<template lang="pug">
  div(:class="wrapperClass")
    component(
      v-if="field.component"
      :is="field.component"
      v-bind="attrs"
      :class="classes"
      v-on="listeners"
      :item="item"
      :value="value"
      :requestParams="requestParams"
    ) {{ value }}
    template(v-else) {{ valueHandler }}
</template>

<script>
import { VSimpleCheckbox, Ripple } from 'vuetify/lib'
import ERadioStatus from '~/components/elements/selections/e-radio-status'
import EChipType from '~/components/elements/chips/e-chip-type'
import ELink from '~/components/elements/links/e-link'
import EModelsColsMap from '~/components/elements/selections/e-models-cols-map'
import ETextEllipsis from '~/components/elements/text/e-text-ellipsis'
import EEmployeeNameWithIcon from '~/components/elements/icons/concrete/e-employee-name-with-icon'
import EOrganizationNameWithIcon from '~/components/elements/icons/concrete/e-organization-name-with-icon'
import EOrmTableColumnWithAction from '~/components/elements/orm/e-orm-table-column-with-action'
import ECopyText from '~/components/elements/text/e-copy-text'
import ETextWithIcon from '~/components/elements/text/e-text-with-icon'
import EOrganizationOwner from '~/components/elements/e-organization-owner'
import EChipsList from '~/components/elements/chips/e-chips-list'
import EComparison from '~/components/elements/informers/e-comparison'
import EModelCashRegisterAddress from '~/components/elements/selections/e-model-cash-register-address'

export default {
  directives: {
    Ripple
  },
  components: {
    EOrganizationOwner,
    EEmployeeNameWithIcon,
    ETextEllipsis,
    EModelsColsMap,
    ERadioStatus,
    EChipType,
    ELink,
    VSimpleCheckbox,
    EOrganizationNameWithIcon,
    EOrmTableColumnWithAction,
    ECopyText,
    ETextWithIcon,
    EChipsList,
    EComparison,
    EModelCashRegisterAddress
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    requestParams: {
      type: Object,
      default: null
    },
    isSelected: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    classes () {
      const classes = this._.get(this.field, 'class', '')
      return this._.isFunction(classes) ? classes(this.item) : classes
    },
    attrs () {
      const attrs = {}
      this._.forIn(this.field.attrs, (value, key) => {
        const force = this.field.forceCall !== false
        if (this._.isFunction(value) && force) {
          attrs[key] = value(this.item)
        } else {
          attrs[key] = value
        }
      })
      return attrs
    },
    value () {
      return this._.get(this.item, this.field.col)
    },
    listeners () {
      const listeners = {}
      if (this._.isFunction(this.field.click)) {
        listeners.input = e => this.field.click(this.item, this.field, this.isSelected)
      }
      return listeners
    },
    valueHandler () {
      return (typeof this.value === 'number')
        ? this.value
        : this.$t(this.value)
    },
    wrapperClass () {
      switch (this.field.col) {
        case 'handledTotalSum':
          return 'align-right'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss">
.align-right {
  text-align: right;
}
</style>
