<template lang="pug">
  div
    div(
      v-if="creatingLoading"
      class="loader"
    )
      e-progress-circular(
        color="#161b25"
        size="xl"
        width="4"
      )
    ValidationObserver(:ref="model.entity" slim v-else)
      v-row()
        v-col( cols="12" class="mt-1 mb-1")
          v-card-title(class="text-center" v-if="!isDialogPart")
            div(style="width:100%") {{$t('Create cash register')}}
          t-orm-fields(v-bind.sync="editedItem"
            :items="fields"
            :tabs="model.ormTabs"
            :context="context")
      v-row(class="mb-4")
        v-col(cols="12")
          t-orm-buttons(:items="buttons" :context="context" :classes="['text-center']")
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import validate from '~/mixins/validation/validate'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import confirmationDialog from '~/mixins/dialogs/confirmationDialog'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'

export default {
  components: {
    TOrmButtons,
    TOrmFields,
    EProgressCircular
  },
  mixins: [validate, confirmationDialog],
  props: {
    context: {
      type: String,
      default: 'create'
    },
    isDialogPart: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    model: CashRegisters,
    editedItem: {},
    createdEntity: {},
    savingLoading: false,
    creatingLoading: false
  }),
  computed: {
    fields () {
      return this._.get(this.model, 'ormFields', [])
    },
    config () {
      return {}
    },
    buttons () {
      return [
        {
          text: 'Add cash register',
          attrs: {
            depressed: true,
            class: 'main-button'
          },
          call: this.save,
          loading: this.savingLoading
        }
      ]
    }
  },
  async created () {
    await this.createCashRegister()
    this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
  },
  methods: {
    close () {
      this.$emit(['dialog', 'close'].join(':'), null)
    },
    clear () {
      this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
    },
    async createCashRegister () {
      try {
        this.creatingLoading = true
        this.createdEntity = this._.get(await this.model.api().create(), 'response.data')
      } catch (e) {
        this.$handlers.error(e, this)
        this.$emit('dialog:close')
      } finally {
        this.creatingLoading = false
      }
    },
    async save () {
      try {
        this.savingLoading = true
        const entity = this.model.query().whereId(this._.get(this.createdEntity, 'id')).first()
        await this.model.api().update(entity, this.editedItem)
        this.$notification.success(this.$t('Cash register is successfully created'))
        this.$emit('dialog:close')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.savingLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
