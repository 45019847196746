<template lang="pug">
  v-card(
    class="org-form"
    :class="{ 'org-form--registration': isRegistration }"
  )
    v-row(justify="center" align="center" no-gutters)
      v-col(cols="12")
        h2(class="org-form__title") {{ title }}
        ValidationObserver(
          ref="form"
          slim
        )
          t-orm-fields(
            v-bind.sync="form"
            :items="fields"
          )
      v-col(cols="12" class="text-center")
        v-btn(
          class="main-button"
          :enableIcon="false"
          @click="btnClick"
          :loading="loading"
        ) {{ btnText }}
</template>

<script>
import Organization from '~/models/directories/Organization'
import loadCurrentUser from '~/middleware/loadCurrentUser'
import TOrmFields from '~/components/templates/orm/t-orm-fields'

export default {
  components: {
    TOrmFields
  },
  props: {
    isRegistration: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    form: {
      name: '',
      economicType: '',
      taxType: '',
      dgd: '',
      ugd: '',
      vatPayer: false,
      vatSeries: '',
      vatNumber: ''
    },
    loading: false
  }),
  computed: {
    fields () {
      return [
        {
          model: 'name',
          component: 'v-text-field',
          provider: {
            name: 'Full name of the head',
            vid: 'name',
            rules: 'required'
          },
          attrs: {
            label: 'Full name of the head',
            outlined: true
          }
        },
        {
          model: 'economicType',
          component: 'v-text-field',
          provider: {
            name: 'Type of economic activity (OKED)',
            vid: 'economicType',
            rules: 'required'
          },
          attrs: {
            label: 'Type of economic activity (OKED)',
            outlined: true
          }
        },
        {
          model: 'taxType',
          component: 'v-text-field',
          provider: {
            name: 'Tax regime',
            vid: 'taxType',
            rules: 'required'
          },
          attrs: {
            label: 'Tax regime',
            outlined: true
          }
        },
        {
          model: 'dgd',
          component: 'v-text-field',
          provider: {
            name: 'DGD at the place of registration',
            vid: 'dgd',
            rules: 'required'
          },
          attrs: {
            label: 'DGD at the place of registration',
            outlined: true
          }
        },
        {
          model: 'ugd',
          component: 'v-text-field',
          provider: {
            name: 'UGD at the place of registration',
            vid: 'ugd',
            rules: 'required'
          },
          attrs: {
            label: 'UGD at the place of registration',
            outlined: true
          }
        },
        {
          model: 'vatPayer',
          component: 'v-switch',
          provider: {
            name: 'VAT payer',
            vid: 'vatPayer',
            rules: 'required'
          },
          attrs: {
            label: 'VAT payer',
            inset: true
          }
        },
        {
          model: 'vatSeries',
          component: 'v-text-field',
          provider: {
            name: 'VAT series',
            vid: 'vatSeries'
          },
          attrs: {
            label: 'VAT series',
            outlined: true
          }
        },
        {
          model: 'vatNumber',
          component: 'v-text-field',
          provider: {
            name: 'VAT number',
            vid: 'vatNumber',
            rules: 'required|integer'
          },
          attrs: {
            label: 'VAT number',
            type: 'number',
            outlined: true
          }
        }
      ]
    },
    title () {
      if (this.isRegistration) {
        return this.$t('Company information')
      } else {
        return this.$t('You need to add an organization')
      }
    },
    btnText () {
      if (this.isRegistration) {
        return this.$t('Continue')
      } else {
        return this.$t('Add organization')
      }
    }
  },
  methods: {
    async btnClick () {
      const valid = await this.$refs.form.validate()

      if (!valid) {
        return
      }

      if (this.isRegistration) {
        this.$attrs.btnCall(this.$attrs.step, this.form)
      } else {
        await this.addOrganization()
      }
    },
    async addOrganization () {
      try {
        this.loading = true
        await Organization.api().create(this.form)
        await loadCurrentUser({}, true)
        this.$notification.success(this.$t('Organization successfully added'))
        this.$nextTick(() => {
          this.$attrs.closeModal(true)
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.org-form {
  width: 100%;
  box-shadow: none;
  padding: 20px 0;

  &--registration {
    border-radius: 24px !important;
    overflow: hidden;
    max-width: 548px;
    margin: 0 auto;
    padding: 32px;
  }

  &__title {
    font-family: $card-title-font-family;
    font-size: 24px;
    line-height: 32px;
    word-break: break-word;
    text-align: center;
    font-weight: 500;
    margin-bottom: 40px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 20px;
    }
  }

  &__text {
    font-weight: 700;
    margin: 15px 0;
  }

  ::v-deep {
    .v-text-field {
      &--outlined {
        .v-label {
          top: 13px;

          &--active {
            background: #fff;
            padding: 0 5px;
            transform: translateY(-21px) scale(0.75);
          }
        }
      }
    }

    .v-input:not(.v-input--radio-group, .v-input--checkbox, .v-input--switch) {
      .v-input__slot {
        background: $input-bg-gray;
        border-radius: $btn-border-radius;
        height: 48px;
        padding-left: 16px !important;
        color: #161b25;
        font-size: 14px;
        position: relative;
        z-index: 2;

        fieldset {
          border: none;
        }

        .v-input__append-inner {
          margin-top: 12px;
        }
      }

      .v-input__control {
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: calc(100% + 4px);
          height: 52px;
          left: -2px;
          top: -2px;
          background: $body-main-gradient;
          border-radius: 14px;
          z-index: 1;
          opacity: 0;
          visibility: hidden;
          transition: .3s;
        }
      }

      &.v-input--is-focused {
        .v-input__slot {
          background: #fff;
        }

        .v-input__control {
          &:after {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
</style>
