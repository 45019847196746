import { Model } from '@vuex-orm/core'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import AssignAble from '~/models/mixins/AssignAble'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import Employees from '~/modules/employees/models/Employees'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import UserManagerCashRegisters from '~/models/directories/pivot/UserManagerCashRegisters'
import Directory from '~/models/abstracts/Directory'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import CashRegistersSearch from '@/modules/cashRegisters/models/CashRegistersSearch'

export default class ManagerUsers extends ChainInheritance(Directory, [
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'managerusers'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortOrder = true
  static persistOptions = {
    insertOrUpdate: ['cashRegisters', 'userManagerCashRegisters']
  }

  static ROLES = {
    owner: 'ROLE_OWNER',
    user: 'ROLE_USER',
    admin: 'ROLE_ADMIN',
    cashier: 'ROLE_CASHIER',
    headCashier: 'ROLE_HEAD_CASHIER'
  }

  static ROLE_NAMES= {
    [ManagerUsers.ROLES.owner]: 'Супер адмін',
    [ManagerUsers.ROLES.admin]: 'Aдміністратор',
    [ManagerUsers.ROLES.cashier]: 'Касир',
    [ManagerUsers.ROLES.headCashier]: 'Старший касир'
  }

  static ROLE_ITEMS = [
    {
      text: ManagerUsers.ROLE_NAMES[ManagerUsers.ROLES.headCashier],
      value: ManagerUsers.ROLES.headCashier
    },
    {
      text: ManagerUsers.ROLE_NAMES[ManagerUsers.ROLES.admin],
      value: ManagerUsers.ROLES.admin
    }
  ]

  static fields () {
    return super.fields({
      // Dynamic relation fields.
      employee_id: this.attr(null),

      id: this.attr(null),
      login: this.attr(null),
      roles: this.attr([], (value) => {
        const removeIndex = value.indexOf(ManagerUsers.ROLES.user)
        if (removeIndex > -1) { // only splice array when item is found
          value.splice(removeIndex, 1) // 2nd parameter means remove one item only
        }
        return value
      }),
      profile: this.attr(null),
      password: this.attr(null), // todo
      relatedEmployee: this.belongsTo(Employees, 'employee_id'),
      relatedCashRegisters: this.belongsToMany(CashRegisters, UserManagerCashRegisters, 'userId', 'cashRegisterId'),
      referralCode: this.attr(null)
    })
  }

  get fullName () {
    return `${this._.get(this, 'profile.firstName', '')} ${this._.get(this, 'profile.lastName', '')}`
  }

  get rolesString () {
    let roles = ''
    this.roles.map((role, key) => {
      if (role !== ManagerUsers.ROLES.user) {
        roles += ManagerUsers.ROLE_NAMES[role] + ' '
      }
    })

    return roles || '-'
  }

  get isOrganizationUser () {
    return this.roles.length === 1 && [ManagerUsers.ROLES.cashier, ManagerUsers.ROLES.admin, ManagerUsers.ROLES.headCashier].includes(this.roles[0])
  }

  get organizationRole () {
    return this.isOrganizationUser ? this.roles[0] : null
  }

  static ormFieldsMap = {
    firstName: 'profile.firstName',
    lastName: 'profile.lastName'
  }

  static ormRelationMap = [
    'relatedCashRegisters'
  ]

  static ormTrans = {
    single: 'user',
    multy: 'users'
  }

  static ormHeaders = [
    { text: 'Full name', value: 'fullName', sortable: false, filterable: false },
    { text: 'Roles', value: 'rolesString', sortable: false, filterable: false },
    { text: 'Login ', value: 'login', sortable: false, filterable: true },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormFields = [
    {
      model: 'firstName',
      component: 'v-text-field',
      provider: {
        vid: 'firstName',
        name: 'First name',
        rules: 'required'
      },
      attrs: {
        label: 'First name',
        outlined: true
      }
    },
    {
      model: 'login',
      component: 'v-text-field',
      context: this.contexts.c,
      provider: {
        vid: 'login',
        name: 'Login ',
        rules: 'required'
      },
      attrs: {
        label: 'Login ',
        type: 'text',
        outlined: true
      }
    },
    {
      model: 'password',
      component: 'e-input-password',
      context: this.contexts.c,
      provider: {
        vid: 'password',
        name: 'Password',
        rules: 'required'
      },
      attrs: {
        label: 'Password',
        type: 'text',
        outlined: true
      }
    },
    {
      model: 'roles',
      component: 'v-select',
      cast: val => [val],
      fieldVal: (ctx) => {
        const val = this._.get(ctx.attrs, ctx.field.model)
        // todo
        return Array.isArray(val) ? val[0] : null
      },
      provider: {
        vid: 'role',
        name: 'Role',
        rules: 'required'
      },
      attrs: {
        label: 'Role',
        outlined: true
      },
      items: () => this.ROLE_ITEMS
    },
    new AutocompleteBuilder({
      model: 'relatedCashRegisters',
      label: 'Select cash register',
      multiple: true,
      itemClass: 'ws-pre',
      cacheItems: true,
      hideDetails: true,
      returnObject: true,
      saveModelInstance: false,
      itemValue: (item) => {
        return this._.get(item, 'id', null)
      },
      chipText: item => this._.get(item, 'fiscalNumber', '-'),
      query: model => model.api()
    }, CashRegistersSearch).build()

  ]

  static ormActions = [
    {
      name: 'edit',
      visible: (item) => {
        return item.isOrganizationUser
      }
    },
    {
      name: 'delete',
      visible: (item) => {
        return item.isOrganizationUser && !item.roles.includes(ManagerUsers.ROLES.cashier)
      }
    }
  ]

  static ormDialogs = {
    default: 'm-orm-crud-dialog',
    edit: 'm-orm-crud-dialog',
    delete: 'm-orm-delete-dialog'
  }

  static ormDialogsConfig = {
    edit: {
      config: {
        context: 'update'
      }
    },
    delete: {
      title: item => ({ type: this.ormTrans.single, name: item.fullName }),
      notification: item => ({ type: this.ormTrans.single, name: item.fullName })
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      return configActions
    }
  }
}
