<template lang="pug">
  v-card(solo flat class="m-block-receipt-preview" :loading="loading")
    v-card-text
      div(class="m-block-receipt-preview__title") {{ $t(title) }}
      v-row()
        v-col(cols="12")
          div(class="text-no-wrap m-block-receipt-preview__delimiter")

        v-col(cols="12" v-if="!items.length && noItemsText" class="text-center")
          span {{ noItemsText }}

        template(v-else)
          v-col(cols="12" v-for="(item, key) in items" :key="key" v-if="!item.hidden" class="px-1")
            v-row(no-gutters align="center" justify="space-between" class="px-2")
              v-col(:cols="item.select ? 6 : 9" sm="5" class="m-block-receipt-preview__item-title")
                | {{ item.name }}
              v-col(
                :cols="item.select ? 6 : 3"
                :sm="5"
                class="m-block-receipt-preview__item-count"
              )
                span(v-if="item.immutable" class="d-block text-center" style="font-size: 0.95rem") {{ item.count }}
                v-select(v-else-if="item.select" :items="_.get(item, 'select.items')" outlined :value="_.get(item, 'select.value')" @input="updateItemCount(key, $event)" hide-details class="m-block-receipt-preview__item-select" :loading="_.get(item, 'select.loading')")
                e-number-counter-input(v-else :value="item.count" @input="updateItemCount(key, $event)" outlined dense hide-details :loading="_.get(item, 'loading')")

        v-col(cols="12")
          div(class="m-block-receipt-preview__delimiter")

        v-col(cols="12" class="py-0" v-if="discount")
          v-row(no-gutters align="center")
            v-col(cols="6" class="m-block-receipt-preview__total m-block-receipt-preview__total--small text-left")
              | {{ $t('Discount') }}
            v-col(cols="6" class="pr-1 pr-sm-2 text-right m-block-receipt-preview__total m-block-receipt-preview__total--small text-right") {{ discount }}

        v-col(cols="12" class="pb-0 pt-1")
          v-row(no-gutters align="center" justify="space-between")
            v-col(cols="12" sm="6" class="m-block-receipt-preview__total")
              | {{ $t('Total sum') }}:
            v-col(cols="12" sm="5" class="pr-sm-2 text-sm-right text-center m-block-receipt-preview__total" )
              | {{ amount | currencyFormat }} <span class="curr-symbol">₸</span>

    div(class="m-block-receipt-preview__corners")
      e-svg-icon() corners
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import currencyFormat from '~/mixins/filters/currencyFormat'
import responsive from '~/mixins/pages/responsive'
import ENumberCounterInput from '~/components/elements/inputs/e-number-counter-input'

export default {
  components: { ESvgIcon, ENumberCounterInput },
  mixins: [currencyFormat, responsive],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Чек'
    },
    loading: {
      type: Boolean,
      default: false
    },
    amount: {
      type: Number,
      default: 0
    },
    discount: {
      type: String,
      default: null
    },
    noItemsText: {
      type: String,
      default: ''
    }
  },
  methods: {
    updateItemCount (key, value) {
      const arr = this.items.map((item, index) => {
        if (index === key) {
          item.count = value
        }
        return item
      })
      this.$emit('input', arr)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
