<template lang="pug">
  div(class="dialog")
    div(class="dialog__title")
      span {{ $t(title) }}
      span(
        v-if="titleAppend"
        class="ml-1"
      ) {{ titleAppend }}
    div(
      v-if="creatingLoading"
      class="dialog__loader"
    )
      e-progress-circular(
        color="#161b25"
        size="xl"
        width="4"
      )
    ValidationObserver(:ref="model.entity" slim v-else)
      v-row()
        v-col(cols="12")
          div {{ $t('Register and register the cash register on the website oofd.kz') }}
          div {{ $t('The serial/factory number of the cash register is indicated below') }}
          div(class="mt-3")
            | {{ $t('The serial/factory number of the cash register') }}:
            span(class="dialog__serial-number") {{ serialNumber }}
          div(class="mt-3") {{ $t('Year of issue') }}: 2023
      v-row()
        v-col( cols="12" class="mt-1 mb-1")
          t-orm-fields(v-bind.sync="editedItem"
            :items="fields"
            :tabs="model.ormTabs"
          )
      v-row(class="mb-4")
        v-col(cols="12")
          t-orm-buttons(:items="buttons" :classes="['text-center']")
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import validate from '~/mixins/validation/validate'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import confirmationDialog from '~/mixins/dialogs/confirmationDialog'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import Shifts from '~/models/directories/Shifts'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import OfdSearch from '~/modules/ofd/models/OfdSearch'

export default {
  components: {
    TOrmButtons,
    TOrmFields,
    EProgressCircular
  },
  mixins: [validate, confirmationDialog],
  props: {
    title: {
      type: String,
      default: 'Add cash register'
    },
    btnText: {
      type: String,
      default: 'Add cash register'
    },
    titleAppend: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {
      }
    },
    isCreating: {
      type: Boolean,
      default: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    model: CashRegisters,
    editedItem: {
      ofd: null,
      ofdToken: null,
      identifier: null,
      registerNumber: null,
      timezone: null
    },
    createdEntity: {},
    savingLoading: false,
    creatingLoading: false,
    isShiftOpened: false
  }),
  computed: {
    fields () {
      return [
        new AutocompleteBuilder({
          model: 'ofd',
          label: 'Select ofd',
          returnObject: true,
          itemClass: 'ws-pre',
          rules: 'required',
          query: model => model.api(),
          visible: () => !this.isEditing
        }, OfdSearch).build(),
        {
          model: 'ofdToken',
          component: 'v-text-field',
          provider: {
            vid: 'ofdToken',
            name: 'Token received on oofd.kz',
            rules: this.isEditing ? '' : 'required'
          },
          attrs: {
            label: 'Token received on oofd.kz',
            outlined: true
          }
        },
        {
          model: 'identifier',
          component: 'v-text-field',
          provider: {
            vid: 'identifier',
            name: 'ID KKM',
            rules: 'required|integer'
          },
          attrs: {
            label: 'ID KKM',
            outlined: true,
            visible: () => !this.isEditing
          },
          cast: val => parseInt(val)
        },
        {
          model: 'registerNumber',
          component: 'v-text-field',
          provider: {
            vid: 'registerNumber',
            name: 'PHM',
            rules: 'required'
          },
          attrs: {
            label: 'PHM',
            outlined: true,
            visible: () => !this.isEditing
          }
        },
        {
          model: 'timezone',
          component: 'v-select',
          provider: {
            vid: 'timezone',
            name: 'Timezone',
            rules: 'required'
          },
          attrs: {
            label: 'Timezone',
            outlined: true,
            items: [
              {
                text: 'Кызылорда (+5 UTC)',
                value: 'Asia/Aqtobe'
              },
              {
                text: 'Астана (+6 UTC)',
                value: 'Asia/Almaty'
              }
            ],
            visible: () => this.isEditing ? !this.isShiftOpened : true
          },
          fieldVal: () => this._.get(this.item, 'timezone')
        }
      ]
    },
    serialNumber () {
      return this._.get(this.item, 'serialNumber') || this._.get(this.createdEntity, 'serialNumber')
    },
    buttons () {
      return [
        {
          text: this.btnText,
          attrs: {
            depressed: true,
            class: 'main-button'
          },
          call: this.save,
          loading: this.savingLoading
        }
      ]
    }
  },
  async created () {
    if (this.isCreating && !this.isEditing) {
      await this.createCashRegister()
    } else if (this.isEditing) {
      await this.checkShift()
    }
  },
  methods: {
    async checkShift () {
      try {
        this.creatingLoading = true
        const res = this._.get(await this.model.api().getShift(this._.get(this.item, 'id')), 'response.data')
        this.isShiftOpened = this._.get(res, 'status') === Shifts.processingStatuses.OPENED
      } catch (e) {
        this.$handlers.error(e, this)
        this.$emit('dialog:close')
      } finally {
        this.creatingLoading = false
      }
    },
    async createCashRegister () {
      try {
        this.creatingLoading = true
        this.createdEntity = this._.get(await this.model.api().create(), 'response.data')
      } catch (e) {
        this.$handlers.error(e, this)
        this.$emit('dialog:close')
      } finally {
        this.creatingLoading = false
      }
    },
    async save () {
      try {
        this.savingLoading = true
        const valid = await this.validate()
        if (!valid) {
          return
        }

        const id = this._.get(this.item, 'id') || this._.get(this.createdEntity, 'id')
        const entity = this.model.query().whereId(id).first()
        let payload = {
          ...this.editedItem,
          ofd: `/api/v1/ofds/${this._.get(this.editedItem, 'ofd.id')}`
        }
        if (this.isEditing) {
          payload = {
            ofd: `/api/v1/ofds/${this._.get(this.item, 'ofd.id')}`,
            timezone: this._.get(this.editedItem, 'timezone')
          }
          const ofdToken = this._.get(this.editedItem, 'ofdToken')
          if (ofdToken) {
            payload.ofdToken = ofdToken
          }
        }
        await this.model.api().update(entity, payload)

        if (this.isEditing) {
          this.$notification.success(this.$t('Cash register is successfully updated'))
        } else if (!this.isCreating) {
          this.$notification.success(this.$t('Cash register is successfully connected'))
        } else {
          this.$notification.success(this.$t('Cash register is successfully created'))
        }

        this.$emit('dialog:close')
        if (this.$attrs.closeModal) {
          this.$attrs.closeModal()
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.savingLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__loader {
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__serial-number {
    color: $color-error;
    margin-left: 8px;
  }
}
</style>
