<template lang="pug">
  div(class="goods-removal")
    e-svg-icon(size="lg" class="d-block mx-auto mb-4") trash
    h3(class="goods-removal__title") {{ $t('Delete group?', { name: _.get(item, 'name', '') }) }}
    t-orm-buttons(:items="ormButtons" :classes="['text-center']")
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import GoodsGroups from '~/modules/goods/models/GoodsGroups'
import TFields from '~/components/templates/t-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons.vue'

export default {
  components: { TFields, ESvgIcon, TOrmButtons },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    model () {
      return GoodsGroups
    },
    ormButtons () {
      return [
        {
          text: 'Cancel',
          attrs: {
            color: 'primary',
            depressed: true,
            text: true
          },
          call: this.closeModal
        },
        {
          text: 'Confirm',
          attrs: {
            color: 'error',
            depressed: true,
            class: ['mr-4']
          },
          loading: this.loading,
          call: this.deleteGroup
        }
      ]
    }
  },
  methods: {
    async deleteGroup () {
      try {
        this.loading = true
        await this.model.api().del(this.item)
        this.closeModal()
        this.$notification.success('Group successfully deleted')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.goods-removal {
  padding: 32px 0 25px;

  &__title {
    color: #161b25;
    font-size: 22px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 32px;
    line-height: 1.5;
  }

  &::v-deep {
    .v-autocomplete {
      .primary--text,
      .v-label {
        color: #161b25 !important;
      }
      fieldset {
        border: 2px solid #161b25;
        border-radius: 10px;
      }
    }
  }
}
</style>
