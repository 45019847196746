<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title text-center") {{ $t('Confirm action') }}
    m-form-block(
      :onSubmit="submit"
      :fields="fields"
      button-text="Confirm"
    )
</template>

<script>
import MFormBlock from '~/components/modules/blocks/m-form-block'
import User from '~/models/administration/User'

export default {
  name: 'BlockCredentialsChecker',
  components: {
    MFormBlock
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    model () {
      return User
    },
    fields () {
      return [
        {
          model: 'login',
          cast: val => val && val.toLowerCase(),
          component: 'v-text-field',
          provider: {
            name: 'E-mail (login)',
            vid: 'login',
            rules: 'required|email',
            mode: 'lazy'
          },
          attrs: {
            label: 'E-mail (login)',
            required: true,
            type: 'email',
            outlined: true
          }
        },
        {
          model: 'password',
          component: 'e-input-password',
          provider: {
            name: 'Password',
            vid: 'password',
            rules: 'required|min:6',
            mode: 'lazy'
          },
          attrs: {
            label: 'Password',
            required: true,
            type: 'password',
            outlined: true,
            appendIcon: false
          }
        }
      ]
    }
  },
  methods: {
    async submit (data) {
      await this.model.api().passwordChecker(data)
      this.closeModal(true)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
