import Dictionary from '~/models/abstracts/Dictionary'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import PersistAble from '~/models/mixins/PersistAble'

export class UktzedDictionary extends ChainInheritance(Dictionary, [
  PersistAble
]) {
  static defaultSort = false
  static entity = 'uktzeddictionary';

  static persistBy = 'insertOrUpdate' ;

  static fields () {
    return {
      id: this.attr(null),
      code: this.attr(null),
      text: this.attr(null),
      // TODO possible slow place
      children: this.hasMany(UktzedDictionary, 'parentId', 'id'),
      parentId: this.attr(null),
      hasChildren: this.attr(null)
    }
  }

  static ormTrans = {
    single: 'Uktzed dictionary',
    multy: 'Uktzed dictionary'
  };

  static ormTreeConfig = {
    parentFieldName: 'parentId',
    childrenFieldName: 'children',
    textFieldName: 'text',
    hasChildrenFlagName: 'hasChildren',
    asyncLoading: {
      // TODO implement function arg support
      requestMethod: 'children'
    }
  }

  static ormHeaders = [
  ];

  static ormFields = [
  ];

  static ormActions = [
    //
  ];
}

export default UktzedDictionary
