<template lang="pug">
  v-snackbar(
    v-model="snackbar"
    :timeout="0"
    color="rgb(30, 38, 44)"
    @click="snackbar = false"
    class="e-new-version-informer"
  )
    e-svg-icon(class="mr-2" size="sm") update-arrow
    | {{ $t('New version available') }}
    v-btn(
      class="e-new-version-informer__btn"
      @click="reload"
      text
    ) {{ $t('Refresh') }}
</template>

<script>
import ELink from '~/components/elements/links/e-link'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  components: {
    ESvgIcon,
    ELink
  },
  data: () => ({
    snackbar: false
  }),
  created () {
    navigator.serviceWorker && navigator.serviceWorker.getRegistrations().then((regs) => {
      const reg = regs[0]
      if (reg) {
        setInterval(() => {
          reg.update()
        }, 6e4)

        reg.addEventListener('updatefound', () => {
          const worker = reg.installing

          worker.addEventListener('statechange', () => {
            if (worker.state === 'activated') {
              this.snackbar = true
            }
          })
        })
      }
    })
  },
  methods: {
    reload () {
      window.location.reload()
    }
  }
}
</script>

<style scoped lang="scss">
.e-new-version-informer {
  &__btn {
    color: $color-warning;
  }
}
</style>
