<template lang="pug">
  div(class="dialog")
    div(
      v-if="paymentPending"
      class="dialog__title text-center"
    ) Оплата через POS-термінал
    div(class="dialog__content")
      v-row(class="mt-2" justify="center" v-if="paymentPending")
        v-col(cols="12")
          p(class="mb-4") Очікується оплата... Слідкуйте за підказками на терміналі
          e-progress-linear(size="md")

      v-row(class="mt-2" justify="center" v-else-if="paymentError")
        v-col(cols="auto")
          e-svg-icon(name="warning-2" size="xbg")
        v-col(cols="12")
          p(class="text-center mb-0") Помилка при оплаті{{ errorText ? ':' : '!' }}
          p(v-if="errorText" class="text-center mt-2 mb-0 color-error") {{ errorText }}

      v-row(class="mt-2" justify="center" v-else-if="paymentSuccess")
        v-col(cols="auto")
          e-svg-icon(name="money" size="xbg")
        v-col(cols="12")
          p(class="text-center") Оплата успішна

      v-row(class="mt-2 mb-4" justify="center" v-else-if="receiptPending")
        v-col(cols="auto")
          e-svg-icon(name="receipt" size="xbg")
        v-col(cols="12")
          p(class="text-center mb-4") Видача фіскального чеку
          e-progress-linear(size="md")

      v-row(class="mt-2" justify="center" v-else-if="receiptError")
        v-col(cols="auto")
          e-svg-icon(name="warning-2" size="xbg")
        v-col(cols="12")
          p(class="text-center mb-0") Помилка при видачі чеку!

      v-row(class="mt-2 mb-5" justify="center" v-else-if="receiptSuccess")
        v-col(cols="auto")
          e-svg-icon(name="done-all" size="xbg")
        v-col(cols="12")
          p(class="text-center mb-0") Фіскальний чек успішно створений
    div(
      v-if="paymentPending || paymentError || receiptError"
      class="dialog__actions pt-5"
    )
      v-btn(
        color="primary"
        @click="close"
        class="main-button d-block mx-auto"
      ) Закрити
</template>

<script>
import EProgressLinear from '~/components/elements/progress/e-progress-linear'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Receipts from '~/modules/receipt/models/Receipts'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import converters from '~/mixins/methods/converters'
import receiptDialogs from '~/modules/receipt/mixins/receiptDialogs'

const workflow = {
  paymentPending: 'paymentPending',
  paymentError: 'paymentError',
  paymentSuccess: 'paymentSuccess',
  receiptPending: 'receiptPending',
  receiptError: 'receiptError',
  receiptSuccess: 'receiptSuccess'
}

export default {
  name: 'BlockPosTerminalPaymentProcess',
  components: {
    EProgressLinear,
    ESvgIcon
  },
  mixins: [converters, receiptDialogs],
  props: {
    receiptPayload: {
      type: Object,
      required: true
    },
    posTerminalSettings: {
      type: Object,
      required: true
    },
    token: {
      type: null,
      required: true
    },
    amount: {
      type: [Number, String],
      required: true
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    state: workflow.paymentPending,
    socket: null,
    errorText: null
  }),
  computed: {
    paymentPending () {
      return this.state === workflow.paymentPending
    },
    paymentError () {
      return this.state === workflow.paymentError
    },
    paymentSuccess () {
      return this.state === workflow.paymentSuccess
    },
    receiptPending () {
      return this.state === workflow.receiptPending
    },
    receiptError () {
      return this.state === workflow.receiptError
    },
    receiptSuccess () {
      return this.state === workflow.receiptSuccess
    }
  },
  created () {
    this.connectToTerminal()
  },
  beforeDestroy () {
    this.closeSocket()
  },
  methods: {
    close () {
      this.closeModal(false)
    },
    closeSocket () {
      if (this.socket) {
        this.socket.close()
      }
    },
    wait (microseconds) {
      return new Promise(resolve => setTimeout(resolve, microseconds))
    },
    async processCreateReceipt (payload) {
      try {
        this.state = workflow.paymentSuccess
        await this.wait(2000)
        this.state = workflow.receiptPending

        const receiptId = this._.get(await Receipts.api().processingCreate(payload, this.token), 'response.data.id')
        const intervalRequest = new IntervalRequest(() => Receipts.api().processingRead(receiptId, this.token))
        const resolveCondition = res => res.response.data.status === Receipts.processingStatuses.DONE.value || res.response.data.status === Receipts.processingStatuses.ERROR.value
        const receipt = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data')

        if (receipt.status === Receipts.processingStatuses.DONE.value) {
          const receiptHtml = this._.get(await Receipts.api().processingReadText(receipt.id), 'response.data')
          const qrCode = this._.get(await Receipts.api().processingReadQrCode(receipt.id), 'response.data', '')
          this.state = workflow.receiptSuccess
          await this.wait(3000)
          await this.openReceiptTextDialog({
            isReceipt: true,
            preview: {
              receipt: receiptHtml,
              qrCode: await this.blobToBase64(qrCode),
              receiptId: receipt.id
            }
          })
        } else {
          this.state = workflow.receiptError
        }
      } catch (e) {
        this.state = workflow.receiptError
        this.$handlers.error(e, this)
      }
    },
    connectToTerminal () {
      const deviceId = this._.get(this.posTerminalSettings, 'terminalName', null)
      const ipAddress = this._.get(this.posTerminalSettings, 'ipAddress', null)
      const port = this._.get(this.posTerminalSettings, 'port', null)
      if (deviceId) {
        this.socket = new WebSocket(`ws://${ipAddress}:${port}/api/pos/${deviceId}/purchase`)
        this.socket.onopen = () => {
          this.socket.send(JSON.stringify({ amount: parseFloat(this.amount) }))
        }
        this.socket.onmessage = async (e) => {
          const res = JSON.parse(this._.get(e, 'data'))
          const success = this._.get(res, 'success')

          if (success) {
            const payload = JSON.parse(JSON.stringify(this.receiptPayload))
            payload.payments[0].card_mask = this._.get(res, 'result.pan', '')
            payload.payments[0].auth_code = this._.get(res, 'result.auth_code', '')
            payload.payments[0].rrn = this._.get(res, 'result.rrn', '')
            payload.payments[0].payment_system = this._.get(res, 'result.issuer_name', '')
            await this.processCreateReceipt(payload)
          } else {
            this.errorText = this._.get(res, 'error')
            this.state = workflow.paymentError
          }
        }
        this.socket.onerror = () => {
          this.state = workflow.paymentError
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
