import Download from 'js-file-download'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import PersistAble from '~/models/mixins/PersistAble'
import Directory from '~/models/abstracts/Directory'
import Tags from '~/models/directories/Tags'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
// import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
// import authUser from "~/modules/auth/authUser";

export default class TurnoverSettings extends ChainInheritance(Directory, [
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static ormLoadWithRelations = true
  static entity = 'turnoversettings'
  static paginated = true
  static defaultSortParam = 'date'
  static persistOptions = {
    insertOrUpdate: ['tag']
  }

  static ormTrans = {
    single: 'Turnover setting',
    multy: 'Turnover settings'
  }

  static ormRelationMap = [
    'tag'
  ]

  static fields () {
    return {
      tag_id: this.attr(null),

      id: this.attr(null),
      tag: this.belongsTo(Tags, 'tag_id', 'id'),
      dayNumber: this.attr(null),
      email: this.attr(null),
      lastSend: this.attr(null)
    }
  }

  get lastSendString () {
    return this.getDateTime(this.lastSend)
  }

  static ormHeaders = [
    { text: 'Tag', value: 'tag.name', sortable: false, filterable: false },
    { text: 'DayNumber', value: 'dayNumber', sortable: false, filterable: false },
    { text: 'Email', value: 'email', sortable: false, filterable: false },
    { text: 'Last send', value: 'lastSendString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFields = [
    {
      model: 'email',
      component: 'v-text-field',
      provider: {
        vid: 'email',
        name: 'email',
        rules: 'required|email'
      },
      attrs: {
        label: 'Email',
        outlined: true
      }
    },
    {
      model: 'dayNumber',
      component: 'v-text-field',
      cast: val => val ? parseInt(val) : 0,
      provider: {
        vid: 'dayNumber',
        name: 'dayNumber',
        rules: 'required|numeric'
      },
      attrs: {
        label: 'Day number',
        outlined: true
      }
    },
    new AutocompleteBuilder({
      model: 'tag',
      label: 'Tag',
      returnObject: true,
      itemClass: 'ws-pre',
      multiple: false,
      context: this.contexts.c,
      itemValue: item => this._.get(item, 'id', null),
      query: model => model.api()
    }, Tags).build()
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = [
    {
      name: 'edit'
    },
    {
      name: 'download',
      text: 'Download',
      icon: {
        type: 'e-svg-icon',
        text: 'xlsx'
      },
      call: async (item) => {
        if (item && item.id) {
          const res = await TurnoverSettings.api().get(TurnoverSettings.$routes[TurnoverSettings.entity].makeExport(item.id), {
            save: false,
            responseType: 'blob'
          })
          Download(this._.get(res, 'response.data'), `turnover_${item.tag.name}.xlsx`)
        }
      }
    }
  ]

  static ormDialogsConfig = {
    create: {
      config: {
        context: 'create'
      }
    },
    edit: {
      config: {
        context: 'update'
      }
    }
  }
}
