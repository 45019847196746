<template lang="pug">
  v-navigation-drawer(
    :value="isOpened"
    class="switching-sidebar"
    :width="width"
    @input="handleOnInput"
    :stateless="stateless"
    right
    fixed
    temporary
  )
    t-orm-page(:support-buttons="false")
      template(v-slot:title)
        v-btn(
          class="switching-sidebar__arrow"
          @click="close"
          :small="xsDevice"
          icon
        )
          e-svg-icon(:size="xsDevice ? 'sm' : 'md'") arrow-next
        span {{ $t(title) }}
      div(class="switching-sidebar__content")
        component(
          :is="component"
          v-bind="componentProps"
          @stateless="changeStateless"
          @closeSidebar="close"
        )
</template>

<script>
import TOrmPage from '~/components/templates/orm/t-orm-page'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Sidebar from '~/modules/sidebar/models/Sidebar'
import responsive from '~/mixins/pages/responsive'
import BlockNotificationsList from '~/modules/notifications/views/components/block-notifications-list'
import BlockEntitiesFilter from '~/modules/dashboard/views/components/block-entities-filter'

export default {
  name: 'SwitchingSidebar',
  components: {
    ESvgIcon,
    TOrmPage,
    BlockNotificationsList,
    BlockEntitiesFilter
  },
  mixins: [responsive],
  props: {
    type: {
      type: String,
      default: 'switching'
    }
  },
  data: () => ({
    width: null,
    stateless: false
  }),
  computed: {
    sidebar () {
      return Sidebar.query().where('type', this.type).first()
    },
    isOpened () {
      return this._.get(this.sidebar, 'isOpen', false)
    },
    params () {
      return this._.get(this.sidebar, 'params', {})
    },
    maxWidth () {
      return this._.get(this.params, 'maxWidth', '')
    },
    component () {
      return this._.get(this.params, 'component', '')
    },
    componentProps () {
      return this._.get(this.params, 'componentProps', {})
    },
    onInput () {
      return this._.get(this.params, 'onInput', null)
    },
    title () {
      return this.checkText(this._.get(this.params, 'title', ''))
    }
  },
  created () {
    if (!this.sidebar) {
      Sidebar.insert({
        data: {
          type: this.type
        }
      })
    }
  },
  methods: {
    changeStateless (val) {
      this.stateless = val
    },
    handleOnInput (val) {
      if (this._.isFunction(this.onInput)) {
        this.onInput(val)
      }
      if (!val) {
        this.close()
      } else {
        this.calculateWidthWithSidebar()
      }
    },
    calculateWidthWithSidebar () {
      const appSidebar = document.querySelector('#app-sidebar')
      if (appSidebar) {
        new ResizeObserver((e) => {
          const sidebarWidth = this._.get(e, '0.target.clientWidth')
          this.calculateWidth(sidebarWidth)
        }).observe(appSidebar)
      } else {
        this.calculateWidth(0)
      }
    },
    calculateWidth (sidebarWidth) {
      const documentWidth = document.body.clientWidth
      let gap = 85
      if (documentWidth < 400) {
        gap = 40
      } else if (documentWidth < 800) {
        gap = 50
      }
      const width = document.body.clientWidth - sidebarWidth - gap
      const maxWidth = parseInt(this.maxWidth)
      this.width = width > maxWidth ? maxWidth : width
    },
    close () {
      if (this._.isFunction(this.onInput)) {
        this.onInput(false)
      }
      this.sidebar.close()
    },
    checkText (text) {
      return this._.isFunction(text) ? text(this) : this.$t(text)
    }
  }
}
</script>

<style scoped lang="scss">
.switching-sidebar {
  height: calc(100% - 54px) !important;

  @media (min-width: map-get($breakpoints, 'sm')) {
    height: 100% !important;
  }

  &__arrow {
    transform: rotate(180deg);
    margin-right: 5px;
  }

  &__content {
    height: calc(100% - 48px);
    overflow-y: auto;

    @media (min-width: map-get($breakpoints, 'md')) {
      height: calc(100% - 68px);
    }
  }
}
</style>
