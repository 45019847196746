<template lang="pug">
  div(class="info-hint info-hint--vertical" v-if="(hint || showHint) && !_.get($User, 'isSuperAdmin')")
    div(v-if="hint" class="d-flex align-center")
      span(class="info-hint__bulb") 💡
      div
        b {{ $t('Hint') }}:
        span(class="ml-1") {{ $t(hint) }}
    div(v-else)
      div(class="d-flex align-center" v-if="showVideoHint")
        m-dialog-youtube(v-model="openYoutubeDialog" :video-url="videoUrl")
        e-svg-icon(size="xlg") youtube
        span(class="ml-2 info-hint__link" @click="openYoutubeDialog = true")
          | {{ $t('Video') }}: {{ $t('How to register') }} {{ $tc('tableFooterHint.' + _.get(model, 'ormTrans.single', ''), 2) }}
      div(class="d-flex align-center" v-if="showRefreshDataHint")
        span(class="info-hint__bulb") 💡
        div
          b {{ $t('Hint') }}:
          span
            span(class="ml-1") {{ $t('after 3-5 minutes update page or click the "Update Data" button to make sure') }}
            span(class="ml-1") {{ $tc('tableFooterHint.' + _.get(model, 'ormTrans.single', ''), 1) }} {{ $t('in sfs') }}.
      div(class="d-flex align-center" v-else-if="showHintForTax")
        span(class="info-hint__bulb") 💡
        div
          b {{ $t('Warning') }}!
          span(class="ml-1") {{ $t('Changes in tax rates will be applied only in the next cash register shift') }}
      div(class="d-flex align-center" v-if="showEmployeeHint")
        span(class="info-hint__bulb") 💡
        b {{ $t('Hint') }}:
        span(class="ml-1") {{ $t('To update the employees key (seal)') }}
      div(class="d-flex align-center" v-if="!this.$Organization")
        span(class="info-hint__bulb") 💡
        b {{ $t('Hint') }}:
        span(class="ml-1") {{ $t('To receive a test data, please add your organization through EDS key') }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import MDialogYoutube from '~/components/modules/dialogs/m-dialog-youtube'

export default {
  components: {
    ESvgIcon,
    MDialogYoutube
  },
  props: {
    model: {
      type: Function,
      required: true,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    videoLinks: {
      cashregisters: 'https://www.youtube.com/embed/DiDsXCKEnNw',
      employee: 'https://www.youtube.com/embed/RZeBTGeFr0I',
      outlet: 'https://www.youtube.com/embed/vFiIGkTwwe0'
    },
    openYoutubeDialog: false,
    entityExists: false,
    showHintForTax: false,
    showEmployeeHint: false
  }),
  computed: {
    showHint () {
      if (!this.$Organization) {
        return true
      }
      return (this.showVideoHint || this.showRefreshDataHint || this.showHintForTax || this.showEmployeeHint) && this.$User.isOwner
    },

    videoUrl () {
      return this._.get(this.videoLinks, this.model.entity, '')
    },
    showVideoHint () {
      return this.isRightEntity && !this.loading && !this.entityExists
    },
    isRightEntity () {
      return this._.get(this.model, 'entity', '') === 'cashregisters' ||
        this._.get(this.model, 'entity', '') === 'employee' ||
        this._.get(this.model, 'entity', '') === 'outlet'
    },
    showRefreshDataHint () {
      const orderEntity = this.model.orderEntity
      if (orderEntity) {
        return Boolean(orderEntity.query().where('status', orderEntity.orderStatuses['200'].value).exists())
      }
      return Boolean(this.model.query().where((entity) => {
        const status = this._.get(entity, 'status', null)
        return status === 'doc_saved_kvt_1' || status === 'awaiting_send_to_tax'
      }).exists())
    }
  },
  async created () {
    if (!this.$Organization) {
      return
    }
    if (this.isRightEntity) {
      try {
        const res = await this.model.api().all({ itemsPerPage: 1, page: 1 }, { save: false })
        this.entityExists = this._.get(res, 'response.data.meta.totalItems', 0)
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
    if (this._.get(this.model, 'entity', '') === 'taxes') {
      this.showHintForTax = true
    }
    if (this._.get(this.model, 'entity', '') === 'employee') {
      this.showEmployeeHint = true
    }
  }
}
</script>

<style scoped lang="scss">
//
</style>
