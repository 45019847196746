import moment from 'moment-timezone'
import Connector from '~/plugins/vuex-orm/plugins/_drivers/abstract/Connector'
import { contexts, formats } from '~/const/global'

export default class APIPlatformConnector extends Connector {
  #context = null
  #dataKey = 'data'
  #persistBy = 'create'
  #sortBy = []
  #filterBy = [[], []]
  #params
  #mapped = {}
  #config = {}
  #persistOptions = {}

  #patterns = {
    sortByField: (field) => {
      let orderField = field

      if (this.isObject(field)) {
        const key = Object.keys(field)[0]
        const val = Object.values(field)[0]

        orderField = this.isOrmModel(val)
          ? `${key}.${val.primaryKey}`
          : field
      }

      return `order[${orderField}]`
    },
    sortByOrder: desc => desc ? 'desc' : 'asc',
    filterByField: (field, value) => {
      if (this.isOrmModelObj(value)) {
        return `${field}.${value.$primaryKey()}`
      }

      return this.isOrmModelCls(value) ? `${field}.${value.primaryKey}` : field
    },
    filterByDate: (field, value) => {
      const filter = {}
      const sortedValue = value.sort((a, b) => new Date(a) - new Date(b))
      if (sortedValue.length) {
        const date = moment(sortedValue[0]).toDate()
        // TODO Временное решение (таймзона)
        // const winterTime = new Date('2022-10-30T03:59:59+02:00')
        // const offset = date.getTime() > winterTime.getTime() ? moment().tz('Europe/Kiev').utcOffset() : 180
        // date.setHours(date.getHours() - offset / 60)
        // const beforeHour = 23 - (offset / 60)
        const beforeHour = 23

        filter[`${field}[after]`] = moment(date).format(formats.dateTimeSec)
        filter[`${field}[before]`] = sortedValue[0] + ` ${beforeHour}:59:59`
        if (sortedValue[1]) {
          filter[`${field}[before]`] = sortedValue[1] + ` ${beforeHour}:59:59`
        }
      }
      return filter
    },
    filterByVal: value => this.isOrmModelObj(value) ? value.primaryVal : value,
    filterByValues: value => `[${value.map(i => i.primaryVal).join(',')}]`
  }

  #map = {
    itemsPerPage: 'limit',
    page: 'offset',
    active: 'active',
    search: 'search',
    root: 'root',
    status: 'status',
    synchronized: 'synchronized',
    mode: 'mode',
    type: 'type',
    exists: 'exists',
    'parent.id': 'parent.id',
    code: 'code',
    cReg: 'cReg',
    cRaj: 'cRaj',
    cSti: 'cSti'
  }

  constructor (model, params, context = null) {
    super()

    if (model && model.persistAble) {
      this.#persistBy = model.getPersistBy()
      this.#persistOptions = model.getPersistOptions()
    }

    this.#context = context
    this.#params = params
    this.mapParams()
    this.genConfig()
  }

  get isPaginated () {
    return Object.keys(this.#mapped).length > 0
  }

  get config () {
    return this.#config
  }

  mapParams () {
    for (const key in this.#params) {
      const val = this.#params[key]

      if (Array.isArray(val)) {
        this.mapArray(key, val)
      } else if (typeof val === 'object') {
        this.mapObject(key, val)
      } else {
        this.mapPrimitive(key, val)
      }
    }

    if (Object.values(this.#sortBy).length === 2) {
      this.morphSortBy()
    }

    if (Object.values(this.#filterBy).length === 2) {
      this.morphFilterBy()
    }
  }

  mapArray (key, val) {
    if (key.includes('sort') && Array.isArray(val) && val.length) {
      this.#sortBy.push(val)
    }
  }

  // TODO: put to Connector.
  mapObject (key, val) {
    if (key.includes('filter') && val) {
      Object.entries(val).map(([field, val]) => {
        if (field && (val || val === false)) {
          this.#filterBy[0].push(field)
          this.#filterBy[1].push(val)
        }
      })
    }
  }

  mapPrimitive (key, val) {
    if ((key in this.#map) && val) {
      this.#mapped[this.#map[key]] = val
    }

    if (key.includes('exists') && val !== undefined) {
      this.#mapped[key] = val
    }
  }

  morphSortBy () {
    const [fields, values] = this.#sortBy

    for (const i in fields) {
      if (this.isOrmModel(values[i]) || this.isPrimitive(values[i])) {
        this.#mapped[this.#patterns.sortByField(fields[i])] = this.#patterns.sortByOrder(values[i])
      }
    }
  }

  morphFilterBy () {
    const [fields, values] = this.#filterBy

    for (const i in fields) {
      const field = fields[i]
      const value = values[i]

      if (this.isOrmModel(value) || this.isPrimitive(value)) {
        this.#mapped[this.#patterns.filterByField(field, value)] = this.#patterns.filterByVal(value)
      } else if (Array.isArray(value) && value.length && value.every(i => this.isOrmModel(i))) {
        this.#mapped[this.#patterns.filterByField(field, value[0])] = this.#patterns.filterByValues(value)
      } else if (Array.isArray(value) && value.length && moment(value[0]).isValid()) {
        Object.assign(this.#mapped, this.#patterns.filterByDate(field, value))
      }
    }
  }

  genConfig () {
    this.#config = {
      persistOptions: this.#persistOptions,
      params: this.#mapped
    }

    if (!contexts.isUpdate(this.#context) && !contexts.isRead(this.#context)) {
      this.#config.dataKey = this.#dataKey
    }

    if (this.isPaginated) {
      this.#config.persistBy = this.#persistBy
    }
  }
}
