const pagination = {
  data: _ => ({
    options: {},
    links: {},
    meta: {},
    requestPromise: null,
    requestPromiseResolve: null
  }),
  computed: {
    totalRows () {
      // Fir when we create new rows we got totalItems from previous request.
      return this._.get(this.meta, 'totalItems', 0) || this.rows.length
    },
    $pagination () {
      if (this.model.paginated) {
        return {
          'server-items-length': this.totalRows
        }
      }
    },
    requestParams () {
      // groupBy, groupDesc, itemsPerPage, multiSort, mustSort, page, sortBy, sortDesc
      const { itemsPerPage, page, sortDesc } = this.options
      const sortBy = this.relatedSortBy()
      const filterBy = this.getServerFilters
      return { itemsPerPage, page, sortBy, sortDesc, filterBy }
    }
  },
  async created () {
    const promises = []
    if (!this.model.paginated) {
      promises.push(this.requestData())
    }
    if (this.prepend && this.$Organization) {
      promises.push(this.prepend.load())
    }

    await Promise.all(promises)
  },
  watch: {
    options: {
      async handler (val, prevVal) {
        if (this.model.paginated) {
          const filtersHasDefault = this._.filter(this._.get(this.model, 'ormFilters'), filter => filter.default)
          const diff = this._.difference(Object.values(val), Object.values(prevVal))

          if (!diff.length || (this.model.isForceFiltered && !this.isAsyncFiltersLoaded) || (filtersHasDefault.length && this._.isEmpty(prevVal))) {
            return
          }
          await this.requestData()
        }
      },
      deep: true
    },
    getServerFilters: {
      async handler (val, prevVal) {
        let hasDiff = false
        for (const key in val) {
          if (val[key] !== prevVal[key] && !(val[key] === null && prevVal[key] === undefined)) {
            hasDiff = true
          }
        }
        const diff = this._.difference(Object.values(val), Object.values(prevVal))
        if (diff && diff[0] !== this._.get(val, 'search') && hasDiff) {
          await this.handleFiltersChange(val)
        }
      },
      deep: true
    },
    async debouncedSearchString (val) {
      await this.handleFiltersChange(val, true)
    }
  },
  methods: {
    async handleFiltersChange (val, isSearch) {
      const filter = Object.values(val).filter(i => !this._.isNull(i))

      if (this.model.paginated && (isSearch || filter.length)) {
        if (this.model.isForceFiltered && !this.isAsyncFiltersLoaded) { return }
        this.options.page = 1

        await this.requestData()
      }
      await this.$emit('filtersChange', val)
    },
    async requestData () {
      if (!this.$Organization && !this.$User.isSuperAdmin) {
        return
      }
      try {
        this.tableLoading = true

        if (this.requestPromise) {
          await this.requestPromise
        }

        this.requestPromise = new Promise((resolve) => {
          this.requestPromiseResolve = resolve
        })

        const route = (typeof this.requestRoute === 'function' && this.requestRoute()) || this.requestRoute
        const dataKey = (typeof this.dataKey === 'function' && this.dataKey()) || this.dataKey
        let response
        if (this._.isFunction(this.customRequest)) {
          response = this._.get(await this.customRequest({
            model: this.model,
            requestParams: this.requestParams
          }), 'response')
        } else {
          response = this._.get(await this.model.api().all(this.requestParams, { route, dataKey }), 'response')
        }
        this.links = this._.get(response, 'data.links', null)
        let meta = this._.get(response, 'data.meta', null)
        if ('limit' in meta && 'offset' in meta) {
          meta = {
            itemsPerPage: meta.limit,
            currentPage: meta.offset,
            totalItems: meta.total
          }
        }
        this.meta = meta
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.tableLoading = false
        this.requestPromiseResolve()
        this.requestPromise = null
      }
    },
    relatedSortBy () {
      const { sortBy } = this.options
      const fields = this.model.getFields()

      return [].concat(sortBy).reduce((list, field) => {
        if (!field) { return list }

        const inter = this._.get(fields, field)
        const sortQuery = this._.get(this._.find(this.model.ormHeaders, { value: field }), 'sortQuery', null)

        if (!inter && !sortQuery) {
          if (process.env.NODE_ENV === 'development') {
            // alert('Some model doesn\'t have some field by which we want sort!')
            // eslint-disable-next-line no-console
            console.error('Field: ', field)
            // eslint-disable-next-line no-console
            console.error('Model: ', this.model.name)
          }

          return list
        }

        if (sortQuery) {
          list.push(sortQuery)

          return list
        }

        let tmp

        if (inter.related || inter.parent) {
          tmp = {
            [field]: inter.related || inter.parent
          }
        } else if (this._.isFunction(inter.mutator)) {
          tmp = {
            [field]: inter.mutator()
          }
        }

        list.push(tmp || field)

        return list
      }, [])
    }
  }
}

export default pagination
