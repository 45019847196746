import Search from '@/models/abstracts/Search'

export class CashRegistersSearch extends Search {
  static entity = 'cashregisterssearch'

  static fields () {
    return {
      id: this.attr(null),
      cashNumber: this.attr(null),
      serialNumber: this.attr(null),
      offlineMode: this.attr(null),
      ofdToken: this.attr(null),
      ofd: this.attr(null),
      address: this.attr(null)
    }
  }

  toString () {
    return `Касса №${this.cashNumber} (${this.serialNumber}) ` + (this.address ? `\n${this.address}` : '')
  }
}

export default CashRegistersSearch
