import { Model } from '@vuex-orm/core'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'

export class Reports extends ChainInheritance(Directory, []) {
  static entity = 'reports'

  static fields () {
    return {
      id: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.processingCreateXReport = function (id) {
        return this.get(Model.$routes.reports.processingCreateXReport(id), { save: false })
      }
      configActions.processingGetReportText = function (id) {
        return this.get(Model.$routes.reports.processingReadAsText(id), { save: false })
      }
      configActions.processingGetZReportsList = function (params) {
        return this.get(Model.$routes.reports.processingGetZReportsList(), { save: false, params })
      }
      configActions.processingGetZReportText = function (id) {
        return this.get(Model.$routes.reports.processingGetZReportText(id), { save: false })
      }

      configActions.processingGetEmployeesReportText = function (id, params) {
        return this.get(Model.$routes.reports.processingGetEmployeesReportText(id), { params, save: false })
      }
      configActions.processingGetEmployeesReportPng = function (id, params) {
        return this.get(Model.$routes.reports.processingGetEmployeesReportPng(id), { params, save: false })
      }

      configActions.processingGetSectionsReportText = function (id, params) {
        return this.get(Model.$routes.reports.processingGetSectionsReportText(id), { params, save: false })
      }
      configActions.processingGetSectionsReportPng = function (id, params) {
        return this.get(Model.$routes.reports.processingGetSectionsReportPng(id), { params, save: false })
      }

      // configActions.processingGetReport = function (id) {
      //   return this.get(Model.$routes.reports.processingRead(id), { save: false })
      // }
      // configActions.getGroups = function () {
      //   return this.get(Model.$routes.reports.groups(), { save: false })
      // }
      // configActions.addGroup = function (payload) {
      //   return this.post(Model.$routes.reports.groups(), payload, { save: false })
      // }
      // configActions.editGroup = function (id, payload) {
      //   return this.put(Model.$routes.reports.concreteGroup(id), payload, { save: false })
      // }
      // configActions.deleteGroup = function (id) {
      //   return this.delete(Model.$routes.reports.concreteGroup(id), { save: false })
      // }
      return configActions
    }
  }
}

export default Reports
