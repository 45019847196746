import Input from '~/components/abstracts/base/Input'

export default class InputBuilder {
  model = ''
  component = ''
  // TODO: create provider class.
  provider = {
    vid: null,
    name: null
  }

  // TODO: create provider class.
  attrs = {
    label: null
  }

  fieldVal

  constructor ({ component = 'v-text-field', model = 'input', label = 'input' } = {}) {
    this.component = component
    this.model = model
    this.provider.vid = model
    this.setLabels(label)
  }

  setLabels (str) {
    return this.setProviderName(str).setLabel(str)
  }

  setRules (str) {
    return this.setProviderRules(str)
  }

  setProviderName (str) {
    if (typeof str !== 'string') {
      throw new TypeError('"name" provider attribute must be a string!')
    }

    this.provider.name = str

    return this
  }

  setProviderRules (str) {
    if (typeof str !== 'string') {
      throw new TypeError('"rules" provider attribute must be a string!')
    }

    this.provider.rules = str

    return this
  }

  setLabel (str) {
    if (typeof str !== 'string') {
      throw new TypeError('"label" attribute must be a string!')
    }

    this.attrs.label = str

    return this
  }

  setReadonly (val) {
    if (typeof val !== 'boolean') {
      throw new TypeError('"Read only" attribute must be a boolean!')
    }

    this.attrs.readonly = val

    if (this.attrs.clearable) {
      this.attrs.clearable = false
    }

    return this
  }

  setDisabled (val) {
    if (typeof val !== 'boolean' && typeof val !== 'function') {
      throw new TypeError('"Disabled" attribute must be a boolean or function!')
    }

    this.attrs.disabled = val

    if (this.attrs.clearable) {
      this.attrs.clearable = false
    }

    return this
  }

  setFieldVal (fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('"fieldVal" attribute must be a function!')
    }

    this.fieldVal = fn

    return this
  }

  setPlaceholder (str) {
    if (typeof str !== 'string') {
      throw new TypeError('"Placeholder" attribute must be a string!')
    }

    this.attrs.placeholder = str

    return this
  }

  setVisible (fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('"visible" attribute must be a function!')
    }

    this.attrs.visible = fn

    return this
  }

  build () {
    return new Input(this)
  }
}
