import { Model } from '@vuex-orm/core'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import AssignAble from '~/models/mixins/AssignAble'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '@/services/Notifications/AppNotifications'

export class Employees extends ChainInheritance(Directory, [
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'employee'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortOrder = false

  static fields () {
    return {
      id: this.attr(null),
      // modes: tax, checkbox, order
      profile: this.attr(null),
      organization: this.attr(null),
      login: this.attr(null),
      roles: this.attr(null)
    }
  }

  static ormTrans = {
    single: 'Employee ',
    multy: 'Employees'
  }

  static ormFieldsMap = {
    //
  }

  static ormHeaders = [
    { text: 'Login ', value: 'login', align: 'left', sortable: false, filterable: false },
    { text: 'Name', value: 'profile.name', align: 'left', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false }
  ]

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['status', 'code'],
      'sort-desc': [true, true]
    })
  }

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = [
    {
      model: 'name',
      component: 'v-text-field',
      provider: {
        vid: 'name',
        name: 'Name',
        rules: 'required'
      },
      attrs: {
        label: 'Name',
        outlined: true
      }
    },
    {
      model: 'login',
      component: 'v-text-field',
      provider: {
        vid: 'login',
        name: 'Login ',
        rules: 'required|min:6|max:25'
      },
      attrs: {
        label: 'Login ',
        outlined: true
      }
    },
    {
      model: 'password',
      component: 'e-input-password',
      provider: {
        vid: 'password',
        name: 'Password',
        rules: 'required|min:6|max:50'
      },
      attrs: {
        label: 'Password',
        type: 'password',
        outlined: true,
        autocomplete: 'new-password'
      }
    }
  ]

  static ormActions = [
    {
      name: 'read'
    },
    {
      name: 'edit',
      visible: true,
      call: (employee, data, ctx) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          component: 'form-employee',
          width: '600px',
          componentProps: {
            title: 'Employee modifying',
            titleAppend: employee?.login,
            btnText: 'Save',
            item: employee,
            isEditing: true
          }
        })
      }
    },
    {
      name: 'changePassword',
      visible: true,
      text: 'Change password',
      icon: {
        type: 'e-svg-icon',
        text: 'padlock'
      },
      call: (employee, data, ctx) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          component: 'form-employee-change-password',
          width: '600px',
          componentProps: {
            title: 'Employee password modifying',
            titleAppend: employee?.login,
            btnText: 'Save',
            item: employee
          }
        })
      }
    },
    {
      name: 'delete',
      visible: true,
      call: (employee, data, ctx) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        confirmationDialog.open({
          title: 'Employee removal',
          text: ctx => ctx.$t('Are you sure to delete employee?', { name: this._.get(employee, 'login') }),
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              await Employees.api().del(employee)
              AppNotifications.success('Employee is successfully deleted')
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      }
    }
  ]

  static ormDialogs = {
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    read: {
      title: item => ({ type: 'Cashier', name: item.login }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'profile.name',
            label: 'Name'
          },
          {
            model: 'login',
            label: 'Login '
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.createEmployee = function (id, payload) {
        return this.post(Model.$routes.employee.list(id), payload)
      }
      configActions.changePassword = function (id, payload) {
        return this.post(Model.$routes.employee.changePassword(id), payload, { save: false })
      }
      return configActions
    }
  }
}

export default Employees
