import { Reports } from '~/modules/reports/models/Reports'

const processingReports = {
  methods: {
    createXReport (id) {
      return Reports.api().processingCreateXReport(id)
    },
    // getReport (id) {
    //   return Reports.api().processingGetReport(id)
    // },
    getReportText (id) {
      return Reports.api().processingGetReportText(id)
    }
  }
}

export default processingReports
