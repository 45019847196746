<template lang="pug">
  m-form-block(:button-text="$t('Run')" :fields="fields" :on-submit="onSubmitForm")
</template>

<script>
import Base64js from 'base64-js'
import MFormBlock from '~/components/modules/blocks/m-form-block'
import Employees from '~/modules/employees/models/Employees'
export default {
  name: 'MFormEmployeeUploadEdsKey',
  components: { MFormBlock },
  props: {
    inModal: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    fields: [
      {
        model: 'ca',
        component: 'v-select',
        attrs: {
          outlined: true,
          placeholder: 'Qualified provider trust services',
          itemText: 'issuerCNs[0]',
          itemValue: 'address'
        },
        // default: () => null,
        request: async (ctx) => {
          const data = await fetch(Employees.$routes.acskProviders)
          return await data.json()
          // const response = await data.json()
          // return [{ address: null, issuerCNs: [ctx.$i18n.t('Autoselect ACSK Provider')] }, ...response]
        }
      },
      {
        model: 'keyFile',
        component: 'e-input-file-content',
        provider: {
          vid: 'keyFile',
          name: 'Select the EDS key',
          rules: 'required'
        },
        attrs: {
          outlined: true,
          placeholder: 'Select the EDS key',
          prependIcon: '',
          appendIcon: '$file',
          accept: '.dat,.pfx,.pk8,.zs2,.jks',
          hideProvider: true,
          expect: 'arrayBuffer'
        },
        hint: 'Possible formats: *.dat, *.pfx, *.pk8, *.zs2, *.jks'
      },
      {
        model: 'keyPassword',
        component: 'e-input-password',
        provider: {
          vid: 'keyPassword',
          name: 'Enter the EDS key password',
          rules: 'required'
        },
        attrs: {
          type: 'password',
          outlined: true,
          placeholder: 'Enter the EDS key password'
        }
      }
    ]
  }),
  computed: {},

  methods: {
    async checkKey (data) {
      const payload = {
        ca: data.ca,
        keyPassword: data.keyPassword,
        keyFile: Base64js.fromByteArray(new Uint8Array(data.keyFile))
      }
      return this._.get(await Employees.api().checkKey(payload), 'response.data', null)
    },
    async onSubmitForm (data) {
      try {
        const keyData = await this.checkKey(data)
        if (this.inModal) {
          this.closeModal({ form: data, keyData }, true)
        }
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>

<style scoped>

</style>
