<template lang="pug">
  div(class="dialog")
    div(class="dialog__title")
      span {{ $t(title) }}
      span(
        v-if="titleAppend"
        class="ml-1"
      ) {{ titleAppend }}
    ValidationObserver(:ref="model.entity" slim)
      v-row()
        v-col( cols="12" class="mt-1 mb-1")
          t-orm-fields(v-bind.sync="editedItem"
            :items="fields"
            :tabs="model.ormTabs"
          )
      v-row(class="mb-4")
        v-col(cols="12")
          t-orm-buttons(:items="buttons" :classes="['text-center']")
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import validate from '~/mixins/validation/validate'
import Employees from '~/modules/employees/models/Employees'

export default {
  components: {
    TOrmButtons,
    TOrmFields
  },
  mixins: [validate],
  props: {
    title: {
      type: String,
      default: 'Add employee'
    },
    btnText: {
      type: String,
      default: 'Add employee'
    },
    titleAppend: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {
      }
    },
    isCreating: {
      type: Boolean,
      default: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    model: Employees,
    editedItem: {},
    savingLoading: false
  }),
  computed: {
    fields () {
      const fields = this._.get(this.model, 'ormFields', [])
      return this.isEditing ? fields.filter(field => field.model === 'name') : fields
    },
    buttons () {
      return [
        {
          text: this.btnText,
          attrs: {
            depressed: true,
            class: 'main-button'
          },
          call: this.save,
          loading: this.savingLoading
        }
      ]
    }
  },
  created () {
    if (this.isEditing) {
      this.$set(this.editedItem, 'name', this._.get(this.item, 'profile.name'))
    } else {
      this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
    }
  },
  methods: {
    async save () {
      try {
        this.savingLoading = true
        const valid = await this.validate()
        if (!valid) {
          return
        }

        if (this.isEditing) {
          const payload = {
            profile: {
              name: this._.get(this.editedItem, 'name')
            }
          }
          await this.model.api().update(this.item, payload)
        } else if (this.isCreating) {
          const payload = {
            profile: {
              name: this._.get(this.editedItem, 'name')
            },
            login: this._.get(this.editedItem, 'login'),
            password: this._.get(this.editedItem, 'password'),
            roles: ['ROLE_CASHIER']
          }
          await this.model.api().createEmployee(this._.get(this.$Organization, 'id'), payload)
        }

        if (this.isEditing) {
          this.$notification.success(this.$t('Employee is successfully updated'))
        } else {
          this.$notification.success(this.$t('Employee is successfully created'))
        }

        this.$emit('dialog:close')
        if (this.$attrs.closeModal) {
          this.$attrs.closeModal()
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.savingLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
