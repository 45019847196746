<template lang="pug">
  span(
    v-if="value"
    class="copy-text"
    @mouseover="showIcon = true"
    @mouseleave="showIcon = false"
    @click="copyToClipboard"
  )
    span(style="cursor:pointer") {{ value }}
    e-svg-icon(
      :style="`opacity: ${showIcon ? 0.5 : 0}`"
      size="xs"
    ) copy
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import clipboard from '~/mixins/global/_clipboard'
export default {
  components: { ESvgIcon },
  mixins: [clipboard],
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data: () => ({
    showIcon: false
  }),
  methods: {
    copyToClipboard () {
      try {
        this.copy(this.value)
        this.$notification.success(this.$t('Copied to clipboard'))
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.copy-text {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
