import { Model } from '@vuex-orm/core'
import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import Dialog from '@/models/system/Dialog'
import AppNotifications from '@/services/Notifications/AppNotifications'

export class Ofd extends PersistAble(Directory) {
  static entity = 'ofd'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'status'
  static defaultSortOrder = false
  static dblClickAction = 'read'

  static fields () {
    return {
      id: this.attr(null),
      title: this.attr(null),
      host: this.attr(null),
      port: this.attr(null),
      site: this.attr(null),
      description: this.attr(null)
    }
  }

  static ormTrans = {
    single: 'Ofd',
    multy: 'Ofd'
  }

  static ormHeaders = [
    { text: 'Name ', value: 'title', align: 'left', filterable: true, sortable: true },
    { text: 'IP address', value: 'host', align: 'left', filterable: false, sortable: false },
    { text: 'Port', value: 'port', align: 'left', filterable: false, sortable: false },
    { text: 'Site', value: 'site', align: 'left', filterable: false, sortable: false },
    { text: 'Description', value: 'description', align: 'left', filterable: false, sortable: false },
    { text: 'Actions', value: 'actions', align: 'center', filterable: false, sortable: false, width: '72' }
  ]

  static ormRowsConfig = {
    disabled: false
  }

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = [
    {
      model: 'title',
      component: 'v-text-field',
      provider: {
        vid: 'title',
        name: 'Name ',
        rules: 'required'
      },
      attrs: {
        label: 'Name ',
        outlined: true
      }
    },
    {
      model: 'host',
      component: 'v-text-field',
      provider: {
        vid: 'host',
        name: 'IP address',
        rules: 'required'
      },
      attrs: {
        label: 'IP address',
        outlined: true
      }
    },
    {
      model: 'port',
      component: 'v-text-field',
      provider: {
        vid: 'port',
        name: 'Port',
        rules: 'required|numeric'
      },
      cast: val => parseInt(val) || 0,
      attrs: {
        label: 'Port',
        outlined: true,
        type: 'number'
      }
    },
    {
      model: 'site',
      component: 'v-text-field',
      provider: {
        vid: 'site',
        name: 'Site'
      },
      attrs: {
        label: 'Site',
        outlined: true
      }
    },
    {
      model: 'description',
      component: 'v-textarea',
      provider: {
        vid: 'description',
        name: 'Description'
      },
      attrs: {
        label: 'Description',
        outlined: true
      }
    }
  ]

  // TODO add approach to crete custom actions
  static ormActions = [
    {
      name: 'read'
    },
    {
      name: 'edit',
      visible: true,
      call: (item, data, ctx) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          component: 'form-ofd',
          width: '600px',
          componentProps: {
            title: 'Ofd modifying',
            titleAppend: item?.title,
            btnText: 'Save',
            item,
            isEditing: true
          }
        })
      }
    },
    {
      name: 'delete',
      visible: true,
      call: (item, data, ctx) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        confirmationDialog.open({
          title: 'Ofd removal',
          text: ctx => ctx.$t('Are you sure to delete ofd?', { name: this._.get(item, 'title') }),
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              await Ofd.api().del(item)
              AppNotifications.success('Ofd is successfully deleted')
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      }
    }
  ]

  static ormDialogs = {
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    read: {
      title: item => ({ type: 'Ofd', name: item.cashNumber }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'title',
            label: 'Name '
          },
          {
            model: 'host',
            label: 'IP address'
          },
          {
            model: 'port',
            label: 'Port'
          },
          {
            model: 'site',
            label: 'Site'
          },
          {
            model: 'description',
            label: 'Description'
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      return configActions
    }
  }
}

export default Ofd
