import Receipts from '~/modules/receipt/models/Receipts'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import _commonData from '~/modules/receipt/mixins/getters/commonData'
import converters from '~/mixins/methods/converters'

const processingReceipt = {
  mixins: [_commonData, converters],
  methods: {
    // top level methods
    async createServiceReceipt (token, amount) {
      const payload = {
        payment: {
          type: 'CASH',
          value: amount,
          label: 'Готівка'
        }
      }
      await this.authenticatedCashier.maybeStartSignAgent()
      return Receipts.api().processingCreateService(payload, token)
    },

    async createReceiptCompletely ({ token, payload, email, receiptViewType = 'text', onError, receiptQueryParams }) {
      // if (receiptViewType !== 'htmlAndId') {
      //   await this.authenticatedCashier.maybeStartSignAgent()
      // }
      const receiptId = this._.get(await this.createReceipt(token, payload), 'response.data.id')
      const intervalRequest = new IntervalRequest(() => this.readReceipt(token, receiptId))
      const resolveCondition = response => response.response.data.status === Receipts.processingStatuses.DONE.value || response.response.data.status === Receipts.processingStatuses.ERROR.value
      // const rejectCondition = response => response.response.data.status === Receipts.processingStatuses.ERROR
      const resultReceipt = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data')
      if (resultReceipt.status === 'ERROR' && resultReceipt.error_message) {
        if (typeof onError === 'function') {
          await onError(resultReceipt)
        }
        throw new Error(this.$t('Error when get receipt:') + ' ' + resultReceipt.error_message)
      }
      if (email && receiptId) {
        await Receipts.api().processingSendEmail(receiptId, { emails: [email] })
      }
      switch (receiptViewType) {
        // case 'html': {
        //   return this._.get(await Receipts.api().processingReadHtml(receiptId), 'response.data')
        // }
        case 'img' : {
          const res = this._.get(await Receipts.api().processingReadPng(receiptId, receiptQueryParams), 'response.data')
          return {
            imgSrc: await this.blobToBase64(res),
            receiptId
          }
        }
        case 'text' : {
          const preview = this._.get(await Receipts.api().processingReadText(receiptId, receiptQueryParams), 'response.data')
          return {
            receipt: preview,
            receiptId
          }
        }
        // case 'htmlAndId': {
        //   const receiptHtml = this._.get(await Receipts.api().processingReadHtml(receiptId), 'response.data')
        //   return { receiptHtml, resultReceipt }
        // }
        default : {
          return resultReceipt
        }
      }
    },

    // low level methods
    createReceipt (token, payload) {
      return Receipts.api().processingCreate(payload, token)
    },
    readReceipt (token, id) {
      return Receipts.api().processingRead(id, token)
    }
  }

}

export default processingReceipt
