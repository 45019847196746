<template lang="pug">
  ValidationObserver(:ref="model.entity" slim)
    v-row(v-if="!madeChoice" class="pb-4" justify="center")
      v-col(cols="9" class="d-flex flex-column align-center")
        e-svg-icon(size="xxlg") key-sign
        p(class="choice-text text-center") {{ $t('Download the cashier\'s key or electronic seal') }}
        v-btn(color="black" dark @click="readFromCert" depressed) {{$t('Read data from key')}}
        div(class="d-flex mt-2 hint")
          e-link(@click="enterManually" underline)
            | {{$t('or set data manually')}}
    template(v-else)
      t-orm-fields(
        v-if="renderFields"
        v-bind.sync="editedItem"
        :items="fields"
        :tabs="model.ormTabs"
        :context="context")
      v-radio-group(v-model="uploadCashierEdsKey" class="radio-group mt-0 pt-0" v-if="keyFile" row)
        template(v-slot:label)
          span(class="hint") {{$t('Launch Checkbox Signature on a secure cloud service')}}?
        v-radio(:ripple="false" color="black" key="yes" :value="true")
          template(v-slot:label)
            span(class="hint") {{$t('yes')}}
        v-radio(:ripple="false" color="black" key="no" :value="false" :label="$t('no')")
          template(v-slot:label)
            span(class="hint") {{$t('no')}}

      div(
        v-if="!keyFile"
        class="mb-4 mt-2 hint"
      )
        | {{ $t('Click') }}
        e-link(
          @click="setDepositSignData"
          class="ml-1 hint"
          underline
        ) {{ $t('here') }}
        |, {{ $t('if you use the ACSK Deposit Sign for the signature') }}

    v-row(class="mb-4 " v-if="madeChoice")
      v-col(cols="12")
        t-orm-buttons(ref="fields" :items="buttons" :context="context" :key="fieldsKey" :classes="['text-center']")
</template>

<script>
import Base64js from 'base64-js'
import { VRadio, VRadioGroup } from 'vuetify/lib'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import validate from '~/mixins/validation/validate'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import EInputFileContent from '~/components/elements/inputs/e-input-file-content'
import filterOrmObject from '~/mixins/orm/filterOrmObject'
import ELink from '~/components/elements/links/e-link'
import taxAuth from '~/mixins/pages/tax-auth'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import contentDialog from '~/mixins/dialogs/contentDialog'
import EmployeeOrder from '~/models/directories/Orders/EmployeeOrder'
import signPluginForOrganization from '~/mixins/dialogs/signPluginForOrganizationDialog'
import { TaxReportService } from '~/services/Tax/Report/TaxReportService'
import signPluginCommonDialog from '~/mixins/dialogs/signPluginCommonDialog'
import EInfoTooltip from '~/components/elements/tooltips/e-info-tooltip'
import TaxAuthService from '~/services/Tax/Auth/TaxAuthService'
import informationDialog from '~/mixins/dialogs/informationDialog'
import EdsKeyData from '~/services/EdsKey/EdsKeyData'

export default {
  components: {
    EInfoTooltip,
    EInputFileContent,
    TOrmButtons,
    TOrmFields,
    ELink,
    ESvgIcon,
    VRadio,
    VRadioGroup
  },
  mixins: [validate, checkPropCtx, filterOrmObject, taxAuth, contentDialog, signPluginCommonDialog, signPluginForOrganization, informationDialog],
  props: {
    context: {
      type: String,
      default: 'create'
    },
    isDialogPart: {
      type: Boolean,
      default: false
    },
    selectedItem: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    fieldsKey: 1,
    madeChoice: false,
    renderFields: true,
    signPluginDialog: false,
    model: EmployeeOrder,
    editedItem: {},
    taxEmployeeLoading: false,
    signDialogLoading: false,
    createdEmployee: null,
    keyFile: null,
    uploadCashierEdsKey: true,
    enterManuallyChoice: false
  }),
  computed: {
    fields () {
      return this._.map(this.model.ormFields, (field) => {
        if (field.model === 'T1RXXXXG3S') {
          field.attrs.visible = this.enterManuallyChoice
        }
        return field
      })
    },
    buttons () {
      return [
        {
          text: 'Add employee',
          contexts: this.$config.contexts.only('c'),
          visible: () => this.madeChoice,
          attrs: {
            class: 'main-button'
          },
          loading: this.taxEmployeeLoading,
          call: this.saveAndSendToTax,
          hint: 'This action must be signed by the EDS of the director'
        }
      ]
    }
  },
  created () {
    this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
    // this.updateLocalStorage()
  },
  methods: {
    close () {
      this.$emit(['dialog', 'close'].join(':'), null)
    },
    rerenderFields () {
      // Note. Workaround to force rerender fields and set new values
      // TODO delete this when orm fields will be refactored
      this.renderFields = false
      return this.$nextTick().then(() => {
        this.renderFields = true
      })
    },
    clearData () {
      this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
      this.rerenderFields()
    },
    async readFromCert () {
      const keyData = await this.contentDialog.open(
        {
          title: 'Read data from eds key',
          component: 'm-form-employee-upload-eds-key',
          width: '650px',
          componentProps: {
            inModal: true
          }
        }
      )
      if (!keyData) { return }
      const subjFullName = this._.get(keyData, 'keyData.certificate_info.certificate.pszSubjFullName', null)
      const subjDRFOCode = this._.get(keyData, 'keyData.certificate_info.certificate.pszSubjDRFOCode', null)
      const publicKeyID = this._.get(keyData, 'keyData.key_id', '')
      const issuerCN = this._.get(keyData, 'keyData.certificate_info.certificate.pszIssuerCN', null)
      const privKeyBeginTime = this._.get(keyData, 'keyData.certificate_start', null)
      const privKeyEndTime = this._.get(keyData, 'keyData.certificate_end', null)
      const certificate = this._.get(keyData, 'keyData.certificate_info.certificate', null)
      this.keyFile = keyData.form
      this.keyFile.ca = this._.get(keyData, 'keyData.ca', null)
      this.loadKey({ subjFullName, subjDRFOCode, publicKeyID, issuerCN, privKeyBeginTime, privKeyEndTime, certificate })
    },
    setFields (data) {
      this.$set(this.$data, 'editedItem', data)
      this.rerenderFields()
    },
    loadKey (keyData) {
      const data = {
        T1RXXXXG1S: keyData.subjFullName,
        T1RXXXXG2S: keyData.subjDRFOCode,
        T1RXXXXG3S: keyData.publicKeyID.replace(/ /g, '').toLowerCase(),
        ca: keyData.issuerCN,
        certificateStart: keyData.privKeyBeginTime,
        certificateEnd: keyData.privKeyEndTime,
        certificateInfo: keyData.certificate
      }
      const newItem = Object.assign({}, this.editedItem, data)
      this.setFields(newItem)
      this.madeChoice = true
    },
    setKeyFields () {
      if (this.keyFile) {
        const data = {
          keyFile: Base64js.fromByteArray(new Uint8Array(this.keyFile.keyFile)),
          keyFileCa: this.keyFile.ca,
          keyPassword: this.keyFile.keyPassword
        }

        const newItem = Object.assign({}, this.editedItem, data)
        this.setFields(newItem)
      }
    },
    enterManually () {
      this.madeChoice = true
      this.enterManuallyChoice = true
    },
    async saveAndSendToTax () {
      if (!await this.validate()) {
        return false
      }
      try {
        this.taxEmployeeLoading = true

        if (this.keyFile && this.uploadCashierEdsKey) {
          this.setKeyFields()
        }
        const payload = this.filterOrmObject(this.editedItem)
        const checkLoginResponse = await this.model.api().post(this.model.$routes[this.model.entity].checkLogin(), {
          login: payload.login
        })
        const login = payload.login
        const freeLogin = this._.get(checkLoginResponse, 'response.data.freeLogin', null)
        if (freeLogin) {
          await this.informationDialog.open({ text: this.$t('dialogs.information.loginOccupiedByAnotherUser', { login, freeLogin }), width: '600px' })
          this.editedItem.login = freeLogin
          await this.rerenderFields()
          return
        }
        await this.signPluginForOrganization.use()
        const keyData = this._.get(this.signPluginForOrganization, 'interface.keyData', null)
        const sign = this._.get(this.signPluginForOrganization, 'interface.sign')
        const edsKeyData = new EdsKeyData(keyData)

        if (!keyData) {
          return this.signPluginForOrganization.close()
        } else if (edsKeyData.isSeal()) {
          this.$handlers.error('Please use the EDS key instead of seal', this)
          await this.signPluginForOrganization.close()
          return this.saveAndSendToTax()
        } else if (edsKeyData.identity !== this._.get(this.$Organization, 'edrpou', null)) {
          this.$handlers.error('The USREOU codes do not match. Please choose another', this)
          await this.signPluginForOrganization.close()
          return this.saveAndSendToTax()
        }

        try {
          const res = await TaxAuthService.sendAuthData(keyData, data => sign(data, { fallbackMethod: this.saveAndSendToTax }))
          if (!res) {
            return null
          }
        } catch (e) {
          this.$handlers.error(e, this, true)
        }

        const reportService = new TaxReportService(this.signPluginForOrganization.interface, this.saveAndSendToTax)

        payload.T1RXXXXG2S = this._.get(this.$Organization, 'edrpou', null) || this._.get(this.$Organization, 'taxNumber', null)
        payload.HPOST = 'Директор'
        payload.HKBOS = reportService.getHKBOSField({
          ctx: this,
          entity: this._.get(this.model, 'entity'),
          type: 'creation'
        })

        const re = /([0-9]{10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6}|[0-9]{9})/

        if (!re.test(payload.HKBOS)) {
          this.$handlers.error(this.$t('It is not possible to find the registration number of the director of the organization. Check the EDS key with which you sign documents'), this)
          return
        }

        if (!payload.HKBOS) {
          this.$handlers.error(this.$t('We could not retrieve data from your EDS key, please check whether it is correct or choose another EDS key'), this)
          return
        }

        const createdEntity = this._.get(await this.model.api().create(payload), `entities.${this.model.entity}[0]`, null)
        const xmlResponse = await this.model.api().xmlDoc(createdEntity)
        const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
        const fname = this._.get(xmlResponse, 'response.data.fname', null)
        const signData = await reportService.getEnvelopedData(xml)

        if (!signData) {
          return null
        }

        const taxId = this._.get(await this.model.api().taxRegister(createdEntity, { signData, fname }), 'response.data.response.taxId', null)
        const encodedTaxId = new TextEncoder().encode(String(taxId))
        const signedTaxId = await reportService.getEnvelopedData(encodedTaxId)

        if (!signedTaxId) {
          return null
        }

        await this.model.api().signTaxId(createdEntity, { tax_id: signedTaxId })

        this.$notification.success(`entityActions.${this.context}.${this.model.entity}`)
        await this.signPluginForOrganization.hide()

        this.close()
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
        await this.signPluginForOrganization.hide()
      } finally {
        this.taxEmployeeLoading = false
      }
    },
    async setDepositSignData () {
      const data = await this.contentDialog.open({
        title: this.$t('Set deposit sign token'),
        width: '500px',
        component: 'm-form-depositsign-token'
      })
      if (!data.token || !data.password || !data.publicKeyId) { return null }

      this.editedItem.T1RXXXXG1S = data.fullName
      this.editedItem.T1RXXXXG3S = data.publicKeyId.toLowerCase()
      this.editedItem.T1RXXXXG2S = data.DRFOCode
      this.editedItem.dsToken = data.token
      this.editedItem.dsPass = data.password
      this.editedItem.certificateEnd = data.certificateEnd
      this.editedItem.certificateStart = data.certificateStart
      this.editedItem.certificateInfo = data.certificateInfo
      this.editedItem.ca = data.ca

      await this.rerenderFields()
      this.editedItem.signatureType = 'DEPOSITSIGN'
    }
  }
}
</script>

<style scoped lang="scss">
.hint {
  font-size: 0.75rem!important;
  line-height: 1.2!important;
}
.choice-text {
  font-size: 0.875rem;
  line-height: 1.2;
  margin: 20px 0 40px;
  flex-grow: 1;
}
.enter-manually-btn {
  margin-bottom: 38px;
}
.radio-group {
  &::v-deep {
    legend {
      width: 100%;
      margin-bottom: 10px;
      height: auto;
    }
  }
}
</style>
