<template lang="pug">
  t-dialog(v-bind="$attrs" type="form" v-on="listeners" :max-width="cardWidth")
    template(#title)
      span {{ $t('Editing of') }} {{ $tc('discount', 2) }}

    template(#content)
      v-row()
        v-col(cols="12")
          ValidationObserver(ref="form" slim)
            t-orm-fields(
              v-bind.sync="editedItem"
              :items="fields"
            )
      v-row(no-gutters)
        v-col(cols="12")
          e-button-main(
            @click="save"
            :enableIcon="false"
            class="d-block mx-auto"
            :loading="loading"
          ) {{ $t('Save') }}
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import fill from '~/mixins/modules/dialogs/fill'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import EButtonMain from '~/components/elements/buttons/e-button-main'
import Billings from '~/models/billing/Billings'

export default {
  components: {
    TOrmFields,
    TDialog,
    EButtonMain
  },
  mixins: [fill],
  data: () => ({
    editedItem: {
      discount: null
    },
    loading: false
  }),
  computed: {
    model () {
      return Billings
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.clear()
            vm.$emit('input', event)
          }
        }
      )
    },
    cardWidth () {
      return [450, 'px'].join('')
    },
    fields () {
      return [
        {
          model: 'discount',
          component: 'v-text-field',
          provider: {
            vid: 'discount',
            name: 'Discount',
            rules: 'required|numeric|max_value:100|min_value:0'
          },
          attrs: {
            label: 'Discount',
            outlined: true,
            type: 'number',
            min: 0,
            max: 100
          }
        }
      ]
    }
  },
  methods: {
    close () {
      this.$emit('input', false)
      this.clear()
    },
    clear () {
      this.editedItem = {}
      this.editedItem.discount = null
    },
    async save () {
      const valid = await this.$refs.form.validate()
      const billingId = this._.get(this.parentContext, 'billing.id', null)

      if (valid && billingId) {
        try {
          this.loading = true
          const model = Billings.query().whereId(billingId).first()
          const payload = {
            discount: parseInt(this._.get(this.editedItem, 'discount', 0))
          }
          await this.model.api().update(model, payload)
          this.$notification.success(this.$t('Discount has been successfully set'))
          this.close()
        } catch (e) {
          this.$handlers.error(e, this.$refs.form)
        } finally {
          this.loading = false
        }
      }
    },
    afterFill (context) {
      this.$set(this.editedItem, 'discount', this._.get(context, 'billing.discount', 0))
    }
  }
}
</script>

<style scoped lang="scss">
//
</style>
