<template lang="pug">
  div()
    p(class="title  font-weight-bold text-center" style="color: black" ) {{$t('Link for payment')}}
    div(class="payment-link pb-6")
      e-link(
        :href="linkText"
      ) {{ linkText }}
      e-svg-icon(
        size="xs"
        class="payment-link__copy-icon"
        @click="copyToClipboard"
      ) copy
    p Надайте це посилання клієнту, після оплати чек буде видано автоматично. Переглянути статус оплати можна в меню "Замовлення".
</template>

<script>
import ELink from '~/components/elements/links/e-link'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import clipboard from '~/mixins/global/_clipboard'
export default {
  components: { ESvgIcon, ELink },
  mixins: [clipboard],
  props: {
    link: {
      type: String,
      required: true
    }
  },
  computed: {
    linkText () {
      return this.link || this.$attrs.value
    }
  },
  methods: {
    copyToClipboard () {
      try {
        this.copy(this.link)
        this.$notification.success(this.$t('Copied to clipboard'))
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }

}
</script>

<style scoped lang="scss">
.payment-link {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;

  &__copy-icon {
    cursor: pointer;
    opacity: 0.5;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }
  }
}

.alert {
  color: #c37f00;
  margin-right: 5px;
}
</style>
