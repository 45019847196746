<template lang="pug">
  v-row( justify="center" class="px-6")
    v-col(cols="12")
      h3(class="title font-weight-bold wb-bw text-center" style="color: #000" v-if="showTitle")
        | {{title}}
      p(v-if="showText" class="title text-center" style="margin-top:5px") {{$t('Close shift by cashier') + shift.cashier.name}} ?
    v-col(cols="12" class="text-center" v-if="showLoading")
      e-progress-circular(size="lg")
    v-col(cols="12")
      ValidationObserver(ref="selectedCashier" v-if="isHeadCashier" slim)
        t-fields(:items="[cashierAutocompleteConfig]" style="margin-top:0!important" :selectedCashier.sync="selectedCashier" :cols="{cols:12}" )
      v-radio-group(class="mt-0" v-model="signType" v-if="(!hasAuthenticatedCashier || type==='head_cashier') && showRadioButtons" hide-details)
        v-radio(:label="$t('Load EDS key in browser')" value="eds_key" color="black" :ripple="false")
        v-radio(:label="$t('I run the Checkbox Signature software locally')" value="agent" color="black" :ripple="false")
      div()

    v-col(cols="auto")
      v-btn(color="primary" class="main-button" @click="closeShiftAction" :loading="loading" depressed :disabled="disabledButton")
        | {{$t('Continue')}}
</template>

<script>
import Shifts from '~/models/directories/Shifts'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import signPluginCommonDialog from '~/mixins/dialogs/signPluginCommonDialog'
import Employees from '~/modules/employees/models/Employees'
import ProcessingSignAgent from '~/services/Processing/ProcessingSignAgent'
import processingReports from '~/modules/receipt/mixins/actions/processingReports'
import _comonData from '~/modules/receipt/mixins/getters/commonData'
import EAutocomplete from '~/components/elements/inputs/e-autocomplete'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import EmployeesSearch from '@/modules/employees/models/EmployeesSearch'
import TFields from '~/components/templates/t-fields'
import confirmationDialog from '~/mixins/dialogs/confirmationDialog'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import processingCheckAgent from '~/modules/receipt/mixins/actions/processingCheckAgent'
import informationDialog from '~/mixins/dialogs/informationDialog'

export default {
  name: 'MBlockShiftClose',
  components: { TFields, EAutocomplete, EProgressCircular },
  mixins: [signPluginCommonDialog, processingReports, _comonData, confirmationDialog, informationDialog, processingCheckAgent],
  props: {
    shift: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'current_cashier'
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      token: null,
      signType: 'agent',
      loading: false,
      zReportPreview: null,
      selectedCashier: null,
      showRadioButtons: false,
      showLoading: true,
      showText: false,
      showTitle: true,
      disabledButton: true,
      selectLoading: false
    }
  },
  computed: {
    isHeadCashier () {
      return this.type === 'head_cashier'
    },
    cashierAutocompleteConfig () {
      return new AutocompleteBuilder({
        model: 'selectedCashier',
        label: 'Select employee',
        rules: 'required',
        returnObject: true,
        itemClass: 'ws-pre',
        hideDetails: true,
        selectLoading: this.selectLoading,
        query: model => model.api().filter({ mode: Employees.itemModes.checkbox.mode, type: Employees.itemTypes.head_cashier.value })
      }, EmployeesSearch).build()
    },
    cashier () {
      return Employees.query().whereId(this.shift.cashier.id).first()
    },
    hasAuthenticatedCashier () {
      return this.authenticatedCashier &&
        this.authenticatedCashier.cashier.id === this.cashier.id &&
        this.authenticatedCashier.cashRegister.id === this.shift.cashRegister
    },
    title () {
      if (this.type === 'current_cashier' && !this.showLoading) {
        return this.hasAuthenticatedCashier ? this.$t('Are you sure to close shift?') : this.$t('Select type of closing shift')
      } else if (this.type === 'current_cashier') {
        return this.$t('Check cashier key status...')
      } else if (this.type === 'head_cashier' && this.showRadioButtons) {
        return this.$t('Select type of closing shift')
      } else if (this.type === 'head_cashier') {
        return this.$t('Select employee')
      } else {
        return ''
      }
    }
  },
  watch: {
    async selectedCashier (val, prevVal) {
      if (val && ((prevVal ? (val.id !== prevVal.id) : true))) {
        this.selectLoading = true
        const singAgentLaunched = await this.checkSignAgent(val, this.shift.cashRegister)
        this.selectLoading = false
        this.disabledButton = false
        if (singAgentLaunched) {
          this.showText = true
        } else {
          this.showRadioButtons = true
        }
      } else {
        this.disabledButton = true
        this.showRadioButtons = false
        this.showText = false
      }
    }
  },
  async created () {
    await Employees.api().read(this.shift.cashier.id)
  },
  async mounted () {
    if (this.type === 'current_cashier') {
      const singAgentLaunched = await this.checkSignAgent(this.shift.cashier, this.shift.cashRegister)
      this.showLoading = false
      this.disabledButton = false
      if (singAgentLaunched) {
        this.showText = true
        this.showTitle = false
      } else {
        this.showRadioButtons = true
      }
    } else {
      this.showLoading = false
      this.showRadioButtons = false
    }
  },
  methods: {
    async closeShiftAction (e, skipClientNameCheck = false) {
      if (this.isHeadCashier && !await this.$refs.selectedCashier.validate()) {
        return
      }
      this.loading = true
      let closedShift = null
      if (this.hasAuthenticatedCashier && !this.isHeadCashier) {
        closedShift = await this.closeByAuthenticatedCashier(skipClientNameCheck)
      } else if (this.signType === 'agent') {
        closedShift = await this.closeByPinCode(skipClientNameCheck)
      } else if (this.signType === 'eds_key') {
        closedShift = await this.closeShiftBySign(skipClientNameCheck)
      }
      if (closedShift) {
        this.$notification.success(this.$t('Shift closed successfully'))
        const zReport = this._.get(await this.getReportText(closedShift.z_report.id), 'response.data')
        this.closeModal(zReport)
      } else {
        this.loading = false
      }
    },
    async closeByPinCode (skipClientNameCheck) {
      try {
        const token = await this.authenticateByPinCode()
        if (!token) { return null }
        return await this.closeShift(token, skipClientNameCheck)
      } catch (e) {
        if (this._.get(e, 'response.status') === 409) {
          // const confirmed = await this.confirmationDialog.open({
          //   title: this.$t('Shift closing confirmation'),
          //   text: this.$t('Shift was opened via CheckBox Kasa'),
          //   width: '650px'
          // })
          // if (confirmed) {
          //   this.closeShiftAction('click', true)
          // }
          await this.informationDialog.open({
            title: 'Помилка',
            text: 'Зміну відкрито за допомогою CheckBox Kasa, закриття зміни через веб-портал може призвести до неочікуваних помилок.',
            width: '650px'
          })
        } else {
          this.$handlers.error(e, this)
          return null
        }
      }
    },
    async closeShiftBySign (skipClientNameCheck) {
      let signAgent = null
      try {
        const token = await this.authenticateBySign()
        if (!token) { return null }
        signAgent = new ProcessingSignAgent(token, this.signPluginCommonDialog.interface)
        signAgent.start()
        const closedShift = await this.closeShift(token, skipClientNameCheck)
        await signAgent.stop()
        this.signPluginCommonDialog.close()
        return closedShift
      } catch (e) {
        if (this._.get(e, 'response.status') === 409) {
          const confirmed = await this.confirmationDialog.open({
            title: this.$t('Shift closing confirmation'),
            text: this.$t('Shift was opened via CheckBox Kasa'),
            width: '650px'
          })
          if (confirmed) {
            this.closeShiftAction('click', true)
          }
        } else {
          this.$handlers.error(e, this)
          this.signPluginCommonDialog.close()
          return null
        }
      } finally {
        if (signAgent instanceof ProcessingSignAgent) {
          await signAgent.stop()
        }
      }
    },
    async closeByAuthenticatedCashier (skipClientNameCheck) {
      try {
        await this.authenticatedCashier.maybeStartSignAgent()
        return await this.closeShift(this.authenticatedCashier.accessToken, skipClientNameCheck)
      } catch (e) {
        if (this._.get(e, 'response.status') === 409) {
          const confirmed = await this.confirmationDialog.open({
            title: this.$t('Shift closing confirmation'),
            text: this.$t('Shift was opened via CheckBox Kasa'),
            width: '650px'
          })
          if (confirmed) {
            this.closeShiftAction('click', true)
          }
        } else {
          this.$handlers.error(e, this)
        }
      }
    },
    async authenticateByPinCode () {
      const cashier = this.isHeadCashier ? this.selectedCashier : this.shift.cashier
      return this._.get(await Employees.api().processingLogin(cashier, this.shift.cashRegister), 'response.data.access_token')
    },
    async authenticateBySign () {
      const cashier = this.isHeadCashier ? this.selectedCashier : this.cashier
      const keyData = await this.signPluginCommonDialog.open()
      if (!keyData) { return }
      await this.signPluginCommonDialog.hide()
      if (keyData[0].infoEx.publicKeyID.replace(/ /g, '').toLowerCase() !== cashier.publicKeyId) {
        this.$notification.error(this.$t('Selected key do not matched with selected cashier. Please select right EDS Key'))
        await this.signPluginCommonDialog.close()
        return await this.authenticateBySign()
      }
      const sign = await this.signPluginCommonDialog.interface.sign(cashier.login)
      return this._.get(await Employees.api().processingLoginSignature(sign), 'response.data.access_token', null)
    },

    async closeShift (token, skipClientNameCheck) {
      const resolveCondition = ({ response }) => (response.data.status === Shifts.processingStatuses.CLOSED)
      let closingShift = null
      if (this.isHeadCashier) {
        const cashRegisterId = this.shift.cashRegister.id
        closingShift = await Shifts.api().processingCloseById(token, cashRegisterId, this.shift.id, skipClientNameCheck)
      } else {
        closingShift = await Shifts.api().processingCloseCurrent(token, skipClientNameCheck)
      }
      const intervalRequest = new IntervalRequest(() => Shifts.api().processingRead(closingShift.response.data.id, token))

      return this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data', null)
    }
  }
}
</script>

<style scoped lang="scss">
.print-preview {
  height: 400px;
  overflow-y: auto;
}
</style>
