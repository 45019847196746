<template lang="pug">
  ValidationObserver(ref="paymentSettings" slim)
    v-row()
      v-col( cols="12" class="mt-1 mb-1")
        t-orm-fields(v-bind.sync="editedItem"
          :items="fields"
          :context="context")
    v-row(class="mb-4")
      v-col(cols="12")
        t-orm-buttons(:items="buttons" :context="context" :classes="['text-center']")
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import Organization from '~/models/directories/Organization'
export default {
  components: { TOrmFields, TOrmButtons },
  props: {
    inModal: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    loading: false,
    editedItem: {},
    context: 'update'
  }),
  computed: {
    organization () {
      return this.item || this.$Organization
    },
    buttons () {
      return [
        {
          text: 'Save',
          attrs: {
            depressed: true,
            class: 'main-button'
          },
          call: () => {
            this.save()
          },
          loading: this.loading
        }
      ]
    },
    fields () {
      return [
        {
          model: 'documentFlowServiceName',
          component: 'v-select',
          provider: {
            vid: 'documentFlowServiceName',
            name: 'Document flow service',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'Document flow service'
          },
          items: () => ([{ value: 'email', text: 'На Email' }, { value: 'vchasno', text: 'В сервіс Вчасно' }])
        },
        {
          model: 'documentFlowServiceUser',
          component: 'v-text-field',
          provider: {
            vid: 'documentFlowServiceUser',
            name: 'User id in doc flow service',
            rules: this.editedItem.documentFlowServiceName === 'vchasno' ? 'required' : ''
          },
          attrs: {
            label: 'User id in doc flow service',
            outlined: true
          },
          hint: 'Email in vchasno service'
        }
      ]
    }
  },
  created () {
    const data = {
      documentFlowServiceName: this.organization.documentFlowServiceName,
      documentFlowServiceUser: this.organization.documentFlowServiceUser
    }
    this.$set(this.$data, 'editedItem', data)
  },
  methods: {
    async save () {
      if (!await this.$refs.paymentSettings.validate()) {
        return
      }
      try {
        this.loading = true
        await Organization.api().update(this.organization, this.editedItem, false)
        this.$notification.success(this.$t('Settings was saved successfully'))
      } catch (e) {
        this.$handlers.error(e, this.$refs.paymentSettings)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
