<template lang="pug">
  v-row(class="mb-5")
    v-col(cols="12" class="text-center")
      v-btn(
        class="secondary-button"
        style="min-width: 200px"
        @click="downloadFile(fileTypes.excel.value)"
        :loading="loading.excel"
        outlined
      )
        e-svg-icon(size="xmd" name="xlsx" class="mr-2")
        | {{$t('Excel file')}}
    v-col(cols="12" class="text-center")
      v-btn(
        class="secondary-button"
        style="min-width: 200px"
        @click="downloadFile(fileTypes.csv.value)"
        :loading="loading.csv"
        outlined
      )
        e-svg-icon(size="xmd" name="csv" class="mr-2")
        | {{$t('Csv file')}}
</template>

<script>
import Download from 'js-file-download'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'

export default {
  components: {
    ESvgIcon
  },
  props: {
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    loading: {
      excel: false,
      csv: false
    }
  }),
  computed: {
    model () {
      return AccountingGoods
    },
    fileTypes () {
      return {
        excel: {
          value: 'excel',
          format: 'xlsx'
        },
        csv: {
          value: 'csv',
          format: 'csv'
        }
      }
    }
  },
  methods: {
    checkStatus (id) {
      const resolveStatus = 'done'
      const request = new IntervalRequest(() => this.model.api().checkExportStatus(id), { interval: 2000, count: 300 })
      return request.startExponential(response => this._.get(response, 'response.data.status', null) === resolveStatus)
    },
    async downloadFile (type) {
      try {
        this.loading[type] = true
        const action = `export${this._.capitalize(type)}`
        const taskId = this._.get(await this.model.api()[action](this.file), 'response.data.task_id', null)
        const resolved = this._.get(await this.checkStatus(taskId), 'response.data', null)

        if (resolved) {
          const blob = this._.get(await this.model.api().exportFile(taskId), 'response.data', null)
          Download(blob, `goods.${this.fileTypes[type].format}`)
        }
        if (this.closeModal) {
          this.closeModal(true)
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading[type] = false
      }
    }
  }

}
</script>

<style scoped lang="scss">
</style>
