<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners")
    template(#title)
      span() {{ title }}
    template(#content)
      m-form-cash-register(:context="context" :selected-item="editedItem" is-dialog-part @dialog:close="close")
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import fill from '~/mixins/modules/dialogs/fill'
import MFormCashRegister from '~/components/modules/forms/concrete/m-form-cash-register'
import EButtonMain from '~/components/elements/buttons/e-button-main'
import apm from '~/mixins/apm/apm'

export default {
  components: {
    MFormCashRegister,
    TDialog,
    EButtonMain
  },
  mixins: [checkPropCtx, fill, apm],
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    itemLoading: false,
    editedItem: {},
    localModel: null
  }),
  computed: {
    dialogCtx () {
      return Object.assign({}, {
        parent: this.selectedItem,
        dialogRefs: this.$refs,
        model: this.localModel.entity
      }, { ...this.ctx })
    },
    dialogConfig () {
      return {
        ...this.localModel.getOrmDialogsConfig(this.type),
        ctx: this.dialogCtx
      }
    },
    context () {
      return this._.get(this.dialogConfig.config, 'context', this.parentContext || this.$config.contexts.create)
    },
    isGridded () {
      return this.dialogConfig && ('grid' in this.dialogConfig)
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.clear()
            vm.$emit('input', event)
          }
        }
      )
    },
    isLoading () {
      return this.loading || this.itemLoading
    },
    title () {
      // TODO: create one logic for pattern generation.
      let title, name

      if (this.dialogConfig.title) {
        title = { ...this.dialogConfig.title.split('|') }
      }

      // TODO: move to class config???
      if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
        name = this.dialogConfig.config.modalName
      } else {
        switch (this.context) {
          case this.$config.contexts.create:
            name = 'Add'
            break
          case this.$config.contexts.read:
            name = 'Card of'
            break
          case this.$config.contexts.update:
            name = 'Editing of'
            break
        }
      }

      const modalType = (() => {
        if (title) {
          return this.$tc(title[0], title[1])
        } else if (this.dialogConfig.config && this.dialogConfig.config.ucFirst) {
          return this._.upperFirst(this.$tc(this.localModel.ormTrans.single, 2))
        } else if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
          return this.$tc(this.localModel.ormTrans.single, 1)
        } else {
          return this._.lowerFirst(this.$tc(this.localModel.ormTrans.single, 1))
        }
      })()

      return [
        this.$t(name),
        modalType,
        (this.dialogConfig.config && this.dialogConfig.config.ucFirst) ? this.selectedItem.name : ''
      ].join(' ').trim(' ')
    }
  },
  created () {
    this.localModel = this.model
  },
  methods: {
    clear () {
      setTimeout(() => {
        this.parentContext = this.$config.contexts.create
      }, 300)
    },

    close () {
      this.$emit('input', false)
      this.clear()
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  &__title {
    font-size: 1rem;
    word-break: normal;
  }
}
</style>
