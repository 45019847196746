import authUser from '~/modules/auth/authUser'
import { menuItems } from '~/const/menuItems'

export default function ({ store, route, redirect }) {
  const user = authUser.get()
  const path = route?.path
  let isRoutePermitted = false
  for (let i = 0; i < menuItems.length; i++) {
    const menuItem = menuItems[i]
    if (menuItem?.href === path && menuItem?.visible(user)) {
      isRoutePermitted = true
      break
    } else if (menuItem?.children?.length) {
      for (let j = 0; j < menuItem.children.length; j++) {
        const menuItemChildren = menuItem.children[j]
        if (menuItemChildren?.href === path && menuItemChildren?.visible(user)) {
          isRoutePermitted = true
          break
        }
      }
    }
  }

  if (!isRoutePermitted) {
    if (authUser.get().isOwner || authUser.get().isOwnerWithoutOrganization) {
      redirect('/dashboard/myorganization')
    } else if (authUser.get().isEmployee) {
      redirect('/dashboard/createreceipts')
    } else if (authUser.get().isSuperAdmin) {
      redirect('/dashboard/organizations')
    }
  }
}
