<template lang="pug">
  div(class="dialog")
    div(class="dialog__title")
      span {{ $t(title) }}
      span(
        v-if="titleAppend"
        class="ml-1"
      ) {{ titleAppend }}
    ValidationObserver(:ref="model.entity" slim)
      v-row()
        v-col( cols="12" class="mt-1 mb-1")
          t-orm-fields(v-bind.sync="editedItem"
            :items="fields"
            :tabs="model.ormTabs"
          )
      v-row(class="mb-4")
        v-col(cols="12")
          t-orm-buttons(:items="buttons" :classes="['text-center']")
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import validate from '~/mixins/validation/validate'
import confirmationDialog from '~/mixins/dialogs/confirmationDialog'
import Ofd from '~/modules/ofd/models/Ofd'

export default {
  components: {
    TOrmButtons,
    TOrmFields
  },
  mixins: [validate, confirmationDialog],
  props: {
    title: {
      type: String,
      default: 'Create Ofd'
    },
    btnText: {
      type: String,
      default: 'Create'
    },
    titleAppend: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    },
    isCreating: {
      type: Boolean,
      default: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    model: Ofd,
    editedItem: {},
    savingLoading: false
  }),
  computed: {
    fields () {
      return this._.get(this.model, 'ormFields', [])
    },
    buttons () {
      return [
        {
          text: this.btnText,
          attrs: {
            depressed: true,
            class: 'main-button'
          },
          call: this.save,
          loading: this.savingLoading
        }
      ]
    }
  },
  created () {
    this.fillData()
  },
  methods: {
    fillData () {
      if (this.item && this.isEditing) {
        this._.map(this.item, (val, key) => {
          if (key !== '$id') {
            this.$set(this.editedItem, key, val)
          }
        })
      } else {
        this.$set(this.$data, 'editedItem', this.model.getFieldsObject())
      }
    },
    async save () {
      try {
        this.savingLoading = true
        const valid = await this.validate()
        if (!valid) {
          return
        }

        if (this.isEditing) {
          await this.model.api().update(this.item, this.editedItem)
        } else if (this.isCreating) {
          await this.model.api().create(this.editedItem)
        }

        if (this.isEditing) {
          this.$notification.success(this.$t('Ofd is successfully updated'))
        } else {
          this.$notification.success(this.$t('Ofd is successfully created'))
        }

        this.$emit('dialog:close')
        if (this.$attrs.closeModal) {
          this.$attrs.closeModal()
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.savingLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
