<template lang="pug">
  v-row(justify="center" class="excise-stamps")
    v-col(cols="12")
      p(class="excise-stamps__hint") {{ $t('To add multiple excise stamps, enter a value in the field') }}
      e-input-scanner(
        :placeholder="$t('Enter excise stamp')"
        v-for="stamp in stampsCount"
        :key="stamp"
        v-model="stamps[stamp]"
        class="mb-1"
        @change="onStampChange"
        translate
        outlined
        hide-details
      )
    v-col(cols="auto")
      v-btn(
        color="primary"
        class="main-button"
        @click="setExciseStamps"
      )
        | {{$t('Continue')}}
</template>

<script>
import EInputScanner from '~/components/elements/inputs/e-input-scanner'

export default {
  components: {
    EInputScanner
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    stamps: {
      0: null
    }
  }),
  computed: {
    stampsCount () {
      return Object.keys(this.stamps)
    }
  },
  watch: {
    stamps: {
      handler (stamps) {
        const lastIndex = this._.last(Object.keys(stamps))
        if (stamps[lastIndex]) {
          this.$set(this.stamps, +lastIndex + 1, null)
        }
      },
      deep: true
    }
  },
  created () {
    this.fillStampsData()
  },
  methods: {
    setExciseStamps () {
      const stamps = this._.filter(Object.values(this.stamps), val => val !== null && val !== '')
      this.closeModal(stamps)
    },
    fillStampsData () {
      this._.map(this._.get(this.item, 'goods.excise_barcodes', []), (stamp, index) => {
        this.$set(this.stamps, index, stamp)
      })
    },
    onStampChange () {
      this._.each(this.stamps, (value, key) => {
        if (value === '') {
          this.$delete(this.stamps, key)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.excise-stamps {
  &__hint {
    font-size: 0.75rem;
    line-height: 1.3;
    margin: -15px 0 20px;
  }
}
</style>
