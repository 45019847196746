import Search from '@/models/abstracts/Search'

export class EmployeesSearch extends Search {
  static entity = 'employeessearch'

  static fields () {
    return {
      id: this.attr(null),
      profile: this.attr(null),
      login: this.attr(null)
    }
  }

  toString () {
    return `${this.profile?.name} (${this.login})`
  }
}

export default EmployeesSearch
