import Search from '~/models/abstracts/Search'

export class OfdSearch extends Search {
  static entity = 'ofdsearch'

  static fields () {
    return {
      id: this.attr(null),
      title: this.attr(null)
    }
  }

  toString () {
    return this.title
  }
}

export default OfdSearch
