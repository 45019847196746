<template lang="pug">
  div(class="switch")
    v-switch(
      :input-value="$attrs.value"
      @change="onChange"
      :label="$t(label)"
      class="mt-0"
      :disabled="_.get($attrs, 'disabled')"
      inset
      hide-details
    )
    e-hint-tooltip(top v-if="tooltip" classes="ml-2")
      span {{ $t(tooltip) }}
</template>

<script>
import EHintTooltip from '~/components/elements/tooltips/e-hint-tooltip'

export default {
  components: {
    EHintTooltip
  },
  props: {
    tooltip: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  methods: {
    onChange (val) {
      this.$emit('input', val || false)
    }
  }
}
</script>

<style scoped lang="scss">
.switch {
  display: flex;
  align-items: center;

  &::v-deep {
    .v-input {
      padding: 0;

      .v-label {
        color: rgba(0, 0, 0, 0.5);
      }

      &__control {
        margin-bottom: 0;
      }
    }
  }
}
</style>
