import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import informationDialog from '~/mixins/dialogs/informationDialog'

export class Taxes extends ChainInheritance(Directory, [PersistAble]) {
  static entity = 'taxes'
  static paginated = true

  static fields () {
    return {
      id: this.attr(null),

      name: this.attr(null),
      type: this.attr(null),
      percent: this.attr(null),
      taxationType: this.attr(null)
    }
  }

  static ormTrans = {
    single: 'Tax ',
    multy: 'Taxes'
  }

  get percentString () {
    return (((this.percent || 0) / 1000).toFixed(2) || 0) + '%'
  }

  static ormHeaders = [
    { text: 'Tax name', value: 'name', align: 'left', sortable: false, filterable: false },
    { text: 'Tax percent', value: 'percentString', align: 'left', filterable: false, sortable: false },
    { text: 'Taxation type', value: 'taxationType', align: 'left', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['code'],
      'sort-desc': [true]
    })
  }

  static ormActions = [
    {
      name: 'edit'
    },
    {
      name: 'delete'
    }
  ]

  static ormDialogs = {
    default: 'm-orm-tax-rates-form-dialog',
    edit: 'm-orm-tax-rates-form-dialog',
    delete: 'm-orm-delete-dialog'
  }

  static ormDialogsConfig = {
    default: {
      config: {
        context: 'create',
        modalName: 'New tax',
        hooks: {
          afterSave: async (data) => {
            if (data.instance.extraRate > 0) {
              await informationDialog.computed.informationDialog().open({ text: 'УВАГА! При використанні акцизного податку, обов\'язково необхідно оформити ліцензію на продаж алкоголю чи тютюну.', width: '600px' })
            }
          }
        }
      },
      title: '|'
    },
    edit: {
      config: {
        context: 'update'
      },
      title: 'Tax|2'
    },
    delete: {
      title: item => ({ type: this.ormTrans.single, name: item.name }),
      notification: item => ({ type: this.ormTrans.single, name: item.name })
    }
  }
}

export default Taxes
