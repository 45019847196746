<template lang="pug">
  div
    v-btn(
      v-if="isAwaiting"
      class="main-button color-error"
      :loading="loading"
      @click="connect"
      small
    ) {{ $t('Connect in OFD') }}
    div(v-else) {{ _.get(item, 'address') }}
</template>

<script>
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import contentDialog from '~/mixins/dialogs/contentDialog'

export default {
  mixins: [contentDialog],
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    model () {
      return CashRegisters
    },
    isAwaiting () {
      return this._.get(this.item, 'status', '') === this.model.itemModes.newCashRegister.value
    }
  },
  methods: {
    async connect () {
      await this.contentDialog.open({
        component: 'form-cash-register',
        width: '600px',
        componentProps: {
          item: this.item,
          title: 'Connect in OFD',
          btnText: 'Connect cash register',
          isCreating: false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
