import contentDialog from '~/mixins/dialogs/contentDialog'

const receiptDialogs = {
  mixins: [contentDialog],
  methods: {
    async openProcessingAuthDialog ({ onInput, ...props }) {
      await this.contentDialog.open({
        component: 'block-processing-employee-login',
        width: '600px',
        onClose: onInput,
        componentProps: {
          onInput,
          ...props
        }
      })
    },
    async openServiceReceiptDialog ({ ...props }) {
      await this.contentDialog.open({
        component: 'block-receipt-service',
        width: '450px',
        componentProps: {
          ...props
        }
      })
    },
    async openReceiptTextDialog ({ width, contentFullHeight = true, ...props }) {
      let dialogWidth = width
      const receiptSettingsWidth = this._.get(this.$Organization, 'receiptSetting.width', 42)
      if (receiptSettingsWidth) {
        dialogWidth = receiptSettingsWidth * 9.5 + 84
      }
      await this.contentDialog.open({
        component: 'block-receipt-text-view',
        width: dialogWidth + 'px',
        contentFullHeight,
        componentProps: {
          ...props
        }
      })
    }
  }
}

export default receiptDialogs
