<template lang="pug">
  v-row()
    v-col(cols="12")
      v-row(class="mt-2" justify="center" v-if="loading")
        v-col(cols="auto")
          e-progress-circular(size="xl", width=4)
        v-col(cols="12")
          p(class="text-center") Зачекайте будьласка...
      v-row(class="mt-2" justify="center" v-if="done")
        v-col(cols="auto")
          e-svg-icon(name="done-all" size="xbg")
        v-col(cols="12")
          p(class="text-center") Чек на замовлення створено!
</template>

<script>
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Orders from '~/modules/acquiring/models/Orders'
import Employees from '~/modules/employees/models/Employees'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import Receipts from '~/modules/receipt/models/Receipts'
import converters from '~/mixins/methods/converters'
import Dialog from '~/models/system/Dialog'
export default {
  components: { ESvgIcon, EProgressCircular },
  mixins: [converters],
  props: {
    order: {
      type: Object,
      required: true
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    loading: false,
    done: false
  }),
  async created () {
    try {
      this.loading = true
      const order = this._.get(await Orders.api().createReceipt(this.order.id), 'response.data', null)
      const receipt = await this.checkReceipt(this.order, order.receiptId)
      const receiptHtml = this._.get(await Receipts.api().processingReadText(receipt.id), 'response.data')
      const qrCode = this._.get(await Receipts.api().processingReadQrCode(receipt.id), 'response.data', '')
      this.loading = false
      this.done = true
      const receiptSettingsWidth = this._.get(this.$Organization, 'receiptSetting.width', 32)
      const dialogWidth = receiptSettingsWidth * 9.5 + 84
      setTimeout(async () => {
        await Dialog.query().where('type', 'content_top').first().open({
          component: 'block-receipt-text-view',
          width: dialogWidth,
          componentProps: {
            isReceipt: true,
            preview: { receipt: receiptHtml, qrCode: await this.blobToBase64(qrCode), receiptId: receipt.id }
          }
        })
      }, 3000)
    } catch (e) {
      this.$handlers.error(e, this)
      this.closeModal()
    }
  },
  methods: {
    async checkReceipt (order, receiptId) {
      const token = this._.get(await Employees.api().processingLogin(order.employee, order.cashRegister), 'response.data.access_token', null)
      const intervalRequest = new IntervalRequest(() => Receipts.api().processingRead(receiptId, token))
      const resolveCondition = response => response.response.data.status === Receipts.processingStatuses.DONE.value || response.response.data.status === Receipts.processingStatuses.ERROR.value
      const resultReceipt = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data')
      if (resultReceipt.transaction.status === 'ERROR' && resultReceipt.transaction.response_error_message) {
        throw new Error(this.$t('Error when get receipt:') + ' ' + resultReceipt.transaction.response_error_message)
      }
      return resultReceipt
    }
  }
}
</script>

<style scoped>

</style>
