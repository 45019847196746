const processingPrefix = '/processing/v1'

const routes = {
  // TODO: create dynamic generation of routes.
  login: _ => '/login',
  auth: {
    refresh_token: _ => '/token/refresh',
    send_reset_password: _ => '/public/users/forgot-password',
    apply_reset_password: token => `public/users/new-password/${token}`,
    organizationDataFromTax: _ => ['/registration', '/organizations', '/get-from-tax'].join('').trim(),
    registration: _ => '/registration'
  },
  users: {
    list: _ => '/users',
    // authorizeInSfs: _ => ['/users', '/', 'tax-refresh-token'].join('').trim(),
    // concrete: id => ['/users', '/', id].join('').trim(),
    changePassword: _ => ['/user', '/change-password'].join('').trim(),
    profile: _ => ['/user', '/profile'].join('').trim(),
    passwordChecker: _ => ['/user', '/password-checker'].join('').trim()
    // rules: _ => ['/users', '/rules'].join('').trim(),
    // addManagedOrganization: id => ['/users', '/', id, '/add-managed-organization'].join('').trim(),
    // deleteManagedOrganization: (id, orgId) => ['/users', '/', id, '/managed-organizations', '/', orgId].join('').trim(),
    // getAuthToken: id => ['/users', '/', id, '/get-auth'].join('').trim(),
    // updatePartnerCode: id => ['/users', '/', id, '/partner-code'].join('').trim()
  },
  currentuser: {
    concrete: id => ['/users', '/', id].join('').trim()
  },
  roles: {
    list: _ => '/roles',
    concrete: id => ['/roles', '/', id].join('').trim()
  },
  employee: {
    list: id => ['/organizations', '/', id, '/managers'].join('').trim(),
    concrete: id => ['/managers', '/', id].join('').trim(),
    changePassword: id => ['/managers', '/', id, '/password'].join('').trim()
  },
  get employeessearch () {
    return this.employee
  },
  get goodssearch () {
    return this.goods
  },
  get accountinggoodssearch () {
    return this.accountinggoods
  },
  get goodsgroupssearch () {
    return this.goodsgroups
  },
  get supplierssearch () {
    return this.suppliers
  },
  get organizationsearch () {
    return this.organization
  },
  suppliers: {
    list: _ => '/accounting/suppliers',
    concrete: id => ['/accounting/suppliers', '/', id].join('').trim()
  },
  supplies: {
    list: _ => '/accounting/supplies',
    addSupplyToProduct: id => ['/accounting/supplies', '/', id].join('').trim(),
    correctSupply: id => ['/accounting/supplies', '/', id, '/correct'].join('').trim()
  },
  goods: {
    list: _ => '/goods',
    concrete: id => ['/goods', '/', id].join('').trim(),
    uploadCsvFile: () => ['/goods', '/import-file'].join(''),
    downloadCsvFile: () => ['/goods', '/export-file'].join(''),
    deleteAll: () => ['/goods', '/delete-all'].join('').trim()
  },
  accountinggoods: {
    list: _ => '/goods-items',
    concrete: id => ['/goods-items', '/', id].join('').trim(),
    exportExcel: _ => '/accounting/export/goods/excel',
    exportCsv: _ => '/accounting/export/goods/csv',
    checkExportStatus: id => ['/accounting/export/goods', '/', id].join('').trim(),
    exportFile: id => ['/accounting/export/goods/file', '/', id].join('').trim(),
    uploadExcel: _ => '/accounting/upload/goods/excel',
    uploadCsv: _ => '/accounting/upload/goods/csv',
    checkUploadStatus: id => ['/accounting/upload/goods', '/', id].join('').trim(),
    getUploadExample: type => ['/accounting/upload/goods/example', '/', type].join('').trim(),
    generateBarcode: _ => '/accounting/barcode'
  },
  accountinggoodssettings: {
    list: _ => '/accounting/settings',
    concrete: id => ['/accounting/settings', '/', id].join('').trim()
  },
  goodsgroups: {
    list: _ => '/goods-groups',
    concrete: id => ['/goods-groups', '/', id].join('').trim(),
    moveGoods: id => ['/goods-groups', '/', id, '/move-goods'].join('').trim()
  },
  permission: {
    list: _ => '/permissions',
    concrete: id => ['/permissions', '/', id].join('').trim()
  },
  organization: {
    list: _ => '/organizations',
    draft: id => ['/organizations', '/', id, '/draft'].join('').trim(),
    concrete: id => ['/organizations', '/', id].join('').trim(),
    within (model, property = null) {
      return [routes[model.constructor.entity].concrete(model[model.constructor.primaryKey]), property].join('').trim()
    },
    synchronize: id => ['/organizations', '/', id, '/synchronization'].join('').trim(),
    cashRegisters: id => ['/organizations', '/', id, '/cash-registers'].join('').trim(),
    shifts: id => ['/organizations', '/', id, '/shifts'].join('').trim(),
    invoices: id => ['/organizations', '/', id, '/invoices'].join('').trim(),
    applyPromo: id => ['/organization', '/', id, '/apply-promo'].join('').trim(),
    sendTaxAuthData: _ => ['/tax', '/update-token'].join(''),
    generateTestData: id => ['/organizations', '/', id, '/generate-test-data'].join('').trim(),
    promoCodeOrganizations: id => ['/organizations', '/', id, '/promo-code-organizations'].join('').trim(),
    generateTelegramBotLink: id => ['/organizations', '/', id, '/generate-link'].join('').trim(),
    activationLogsOrganization: _ => '/call-center-activation-logs',
    adminAuthLogs: _ => '/admin-auth-logs'
  },
  tags: {
    list: _ => '/tags',
    concrete: id => ['/tags', '/', id].join('').trim()
  },
  cashregisters: {
    list: _ => '/cash-registers',
    concrete: id => ['/cash-registers', '/', id].join('').trim(),
    taxRegister: id => ['/cash-registers', '/', id, '/', 'reg-tax'].join('').trim(),
    xmlDoc: id => ['/cash-registers', '/', id, '/', 'xml-doc'].join('').trim(),
    activate: id => ['/cash-registers', '/', id, '/', 'activate'].join('').trim(),
    deactivate: id => ['/cash-registers', '/', id, '/', 'deactivate'].join('').trim(),
    updateSettings: id => ['/cash-registers', '/', id, '/', 'settings'].join('').trim(),
    activatePayable: id => ['/cash-registers', '/', id, '/', 'activate-payable'].join('').trim(),
    lastPrimaryKey: () => ['/cash-registers', '/', 'max-number'].join('').trim(),
    synchronize: _ => ['/cash-registers', '/', 'sync'].join('').trim(),
    error: id => ['/cash-registers', '/', id, '/', 'error'].join('').trim(),
    exportExcel: _ => ['/cash-registers', '/export-excel'].join('').trim(),
    checkOfflineTime: _ => ['/cash-registers', '/processing', '/check-offline-time'].join('').trim(),
    posTerminalSettings: id => ['/cash-registers', '/', id, '/pos-terminal-settings'].join('').trim(),
    activateNotActive: id => ['/cash-registers', '/', id, '/activate-not-active'].join('').trim(),
    flags: id => [processingPrefix, '/cash-registers', '/', id, '/flags'].join('').trim(),
    getShift: id => [processingPrefix, '/cash-registers', '/', id, '/shift'].join('').trim(),
    closeShift: id => [processingPrefix, '/cash-registers', '/', id, '/shift', '/close'].join('').trim()
  },
  get cashregisterssearch () {
    return this.cashregisters
  },
  shifts: {
    list: _ => [processingPrefix, '/shifts'].join('').trim(),
    concrete: id => [processingPrefix, '/shifts', '/', id].join('').trim()
  },
  get shiftssearch () {
    return this.shifts
  },
  taxes: {
    list: _ => '/taxes',
    concrete: id => ['/taxes', '/', id].join('').trim(),
    lastPrimaryKey: () => ['/tax', '/', 'free-code'].join('').trim()
  },
  receipts: {
    list: _ => [processingPrefix, '/tickets'].join('').trim(),
    concrete: id => [processingPrefix, '/tickets', '/', id].join('').trim(),
    processingRead: id => [processingPrefix, '/tickets', '/', id].join(''),
    processingReadDetailed: id => [processingPrefix, '/tickets', '/', id, '/detailed'].join(''),
    processingReadPng: id => [processingPrefix, '/tickets', '/', id, '/png'].join(''),
    processingReadText: id => [processingPrefix, '/tickets', '/', id, '/txt'].join(''),
    processingReadPdf: id => [processingPrefix, '/tickets', '/', id, '/pdf'].join(''),
    processingSendEmail: id => [processingPrefix, '/tickets', '/', id, '/email'].join(''),
    processingReadTestPng: _ => [processingPrefix, '/test/ticket/png'].join(''),
    processingReadTestText: _ => [processingPrefix, '/test/ticket/txt'].join(''),
    processingCreate: _ => [processingPrefix, '/tickets'].join(''),

    chart: _ => ['/receipts', '/get-chart'].join('').trim(),
    totalSum: _ => ['/receipts', '/get-total-sum'].join('').trim(),
    totalSumYear: _ => ['/receipt', '/get-total-sum-year'].join('').trim(),
    totalCountForCurrentYear: _ => ['/receipts', '/get-count'].join(''),
    processingReadHtml: id => ['/receipt', '/processing', '/', id, '/html'].join(''),
    processingReadQrCode: id => ['/receipt', '/processing', '/', id, '/qrcode'].join(''),
    processingCreateService: _ => ['/receipt', '/processing', '/service'].join(''),
    readText: id => ['/receipts', '/', id, '/text'].join(''),
    readHtml: id => ['/receipts', '/', id, '/html'].join(''),
    readPdf: id => ['/receipts', '/', id, '/pdf'].join(''),
    sendToEmail: id => ['/receipt', '/processing', '/', id, '/email'].join(''),
    settings: _ => '/receipt-settings',
    returnReceipt: _ => '/return-receipts',
    returnReceiptValidate: _ => '/return-receipts/validate',
    returnReceiptReset: _ => '/return-receipts/reset',
    sendToSms: id => ['/receipt', '/processing', '/', id, '/sms'].join(''),
    salesChart: _ => ['/sales-chart'].join(''),
    salesChartInfo: _ => ['/sales-chart', '/info'].join('')
  },
  receiptgoods: {
    list: _ => '/receipt-goods'
  },
  onBoardings: {
    list: _ => '/on-boardings',
    concrete: id => ['/on-boardings', '/', id].join('').trim(),
    concreteSteps: id => ['/on-boardings', '/', id, '/steps'].join('').trim(),
    steps: _ => '/on-boarding-steps',
    concreteStep: id => ['/on-boarding-steps', '/', id].join('').trim()
  },
  reports: {
    processingCreateXReport: id => [processingPrefix, '/reports', '/x', '/', id].join('').trim(),
    processingReadAsText: id => [processingPrefix, '/reports', '/x', '/', id, '/txt'].join('').trim(),
    processingGetZReportText: id => [processingPrefix, '/reports', '/z', '/', id, '/txt'].join('').trim(),
    processingGetZReportsList: _ => [processingPrefix, '/reports', '/z'].join('').trim(),
    processingGetEmployeesReportText: id => [processingPrefix, '/reports', '/cashiers', '/', id, '/txt'].join('').trim(),
    processingGetEmployeesReportPng: id => [processingPrefix, '/reports', '/cashiers', '/', id, '/png'].join('').trim(),
    processingGetSectionsReportText: id => [processingPrefix, '/reports', '/sections', '/', id, '/txt'].join('').trim(),
    processingGetSectionsReportPng: id => [processingPrefix, '/reports', '/sections', '/', id, '/png'].join('').trim(),
    extendedZ: _ => [processingPrefix, '/reports', '/extended/z'].join('').trim(),
    extendedReceipts: _ => [processingPrefix, '/reports', '/extended/receipts'].join('').trim(),
    extendedStatus: id => [processingPrefix, '/reports', '/extended/tasks', '/', id].join('').trim(),
    extendedStatusResult: id => [processingPrefix, '/reports', '/extended/tasks', '/', id, '/result'].join('').trim(),

    processingRead: id => ['/report', '/processing', '/', id],
    periodicReport: _ => ['/report', '/processing', '/periodical'].join('').trim(),
    report: _ => ['/report', '/processing', '/report'].join('').trim(),
    checkStatus: _ => ['/report', '/processing', '/check-status'].join('').trim(),
    excel: _ => ['/report', '/processing', '/get-excel'].join('').trim(),
    goods: _ => ['/report', '/processing', '/goods'].join('').trim(),
    shifts: _ => ['/report', '/processing', '/shift'].join('').trim(),
    groups: _ => '/report-groups',
    concreteGroup: id => ['/report-groups', '/', id].join('').trim()
  },
  taxinfo: {
    list: _ => '/report/tax-info'
  },
  financialreport: {
    list: _ => '/report/goods-financial',
    downloadCsvFile: _ => '/report/goods-financial/csv'
  },
  invoices: {
    list: _ => '/invoices',
    concrete: id => ['/invoices', '/', id].join('').trim(),
    cashRegisterPeriods: id => ['/invoices', '/', id, '/cash-register-periods'].join('').trim(),
    readPdf: _ => ['/invoices', '/report', '/pdf'].join('').trim(),
    getWaybillXml: id => ['/invoices', '/', id, '/get-xml'].join('').trim(),
    sendSignedXml: id => ['/invoices', '/', id, '/send-xml '].join('').trim(),
    regenerate: id => ['/invoices', '/', id, '/regenerate '].join('').trim(),
    getWaybillXmlParams: id => ['/invoices', '/', id, '/get-xml-params'].join('').trim(),
    setFree: id => ['/invoices', '/', id, '/set-free'].join('').trim(),
    getFile: id => ['/invoices', '/', id, '/get-file'].join('').trim(),
    paymentLink: _ => ['/ipay', '/link'].join('').trim(),
    googlePayPayment: _ => ['/ipay', '/google-pay-payment'].join('').trim(),
    applePayValidation: _ => ['/payment', '/apple-pay-merchant-validate'].join('').trim(),
    applePayPayment: _ => ['/ipay', '/apple-pay-payment'].join('').trim(),
    paymentInfo: _ => ['/payment', '/info'].join('').trim(),
    billingPeriods: _ => ['/periods'].join('').trim(),
    getExcelList: tagId => ['/invoice', '/get-excel-list', '?tag=', tagId].join('').trim()
  },
  get invoicessearch () {
    return this.invoices
  },
  billings: {
    list: _ => '/billings',
    organizationBilling: id => ['/organizations', '/', id, '/billing'].join('').trim(),
    ccActive: id => ['/billings', '/', id, '/cc-active'].join('').trim(),
    generateInvoice: id => ['/billings', '/', id, '/generate-invoice'].join('').trim(),
    concrete: id => ['/billings', '/', id].join('').trim(),
    unsetAutoPayment: id => ['/billings', '/', id, '/', 'disable-auto-payment'].join('').trim(),
    reconciliationAct: id => ['/billings', '/', id, '/', 'generate-reconciliation'].join('').trim(),
    reconciliationActOnAllOrganizations: _ => ['/billings', '/', 'generate-reconciliation'].join('').trim(),
    changeBillingDate: id => ['/billings', '/', id, '/', 'change-billing-date'].join(''),
    activationCount: id => ['/billings', '/', id, '/', 'activation-count'].join('')
  },
  smsAct: {
    list: _ => ['/sms', '/acts'].join('').trim(),
    getPdf: id => ['/sms', '/acts', '/', id, '/pdf'].join('').trim()
  },
  smsBilling: {
    list: _ => ['/sms', '/billings'].join('').trim(),
    generatePaymentLink: _ => ['/sms', '/payments', '/generate-link'].join('').trim()
  },
  smsInvoice: {
    list: _ => ['/sms', '/invoices'].join('').trim(),
    getPdf: id => ['/sms', '/invoices', '/', id, '/pdf'].join('').trim(),
    generateInvoice: _ => ['/sms', '/invoices'].join('').trim()
  },
  payment: {
    list: _ => '/payments',
    concrete: id => ['/payments', '/', id].join('').trim()
  },
  taxbills: {
    list: _ => '/tax-bills',
    concrete: id => ['/tax-bills', '/', id].join('').trim(),
    taxRegister: id => ['/tax-bills', '/', id, '/', 'reg-tax'].join('').trim(),
    xmlDoc: id => ['/tax-bills', '/', id, '/', 'xml-doc'].join('').trim()
  },
  acts: {
    list: _ => '/acts',
    concrete: id => ['/acts', '/', id].join('').trim(),
    getFile: id => ['/acts', '/', id, '/get-file'].join('').trim()
  },
  get actssearch () {
    return this.acts
  },
  promocodes: {
    list: _ => '/promo-codes'
  },
  receiptpaymentmethod: {
    list: _ => '/receipt-payment-methods',
    concrete: id => ['/receipt-payment-methods', '/', id].join('').trim()
  },
  edsKey: {
    check: _ => '/eds-keys/check'
  },
  receiptconfig: {
    config: _ => '/receipt-config/processing',
    logo: _ => '/receipt-config/processing/receipt-logo',
    logoText: _ => '/receipt-config/processing/receipt-text-logo',
    receiptHtml: _ => '/receipt-config/processing/render-html',
    receiptSafeHtml: _ => '/receipt-config/processing/render-safe-html',
    receiptPng: _ => '/receipt-config/processing/render-png'
  },
  referrals: {
    list: _ => '/organization-bonus'
  },
  notifications: {
    list: _ => '/notifications',
    userList: _ => '/notification',
    concrete: id => ['/notifications', '/', id].join('').trim(),
    createFormFile: _ => '/notification/from-file',
    checkNew: _ => '/notification/check-new',
    readAll: _ => '/notification/mark-as-read'
  },
  transferrequests: {
    list: _ => '/transfer-requests',
    concrete: id => ['/transfer-requests', '/', id].join('').trim(),
    execute: id => ['/transfer-requests', '/', id, '/execute'].join('').trim(),
    decline: id => ['/transfer-requests', '/', id, '/decline'].join('').trim()
  },
  taxreports: {
    list: _ => '/tax/reports',
    reportPdf: id => ['/report', '/', id, '/doc'].join('').trim(),
    reportHtml: id => ['/report', '/', id, '/kvt-html'].join('').trim(),
    report: _ => '/report-tax-orders',
    getReportInfo: _ => '/report-tax-orders/info',
    reportXmlDoc: id => ['/report-tax-orders', '/', id, '/xml-doc'].join('').trim(),
    reportSendToTax: id => ['/report-tax-orders', '/', id, '/send-to-tax'].join('').trim(),
    reportTaxId: id => ['/report-tax-orders', '/', id, '/signed-tax-id'].join('').trim(),
    getReportHtml: id => ['/report-tax-orders', '/', id, '/html'].join('').trim(),
    getReportDraftHtml: _ => ['/report-tax-orders', '/draft-html'].join('').trim(),
    temporaryReport: _ => '/temporary-report-tax-orders',
    getTemporaryReportInfo: _ => '/temporary-report-tax-orders/info',
    temporaryReportXmlDoc: id => ['/temporary-report-tax-orders', '/', id, '/xml-doc'].join('').trim(),
    temporaryReportSendToTax: id => ['/temporary-report-tax-orders', '/', id, '/send-to-tax'].join('').trim(),
    temporaryReportTaxId: id => ['/temporary-report-tax-orders', '/', id, '/signed-tax-id'].join('').trim(),
    getTemporaryReportHtml: id => ['/temporary-report-tax-orders', '/', id, '/html'].join('').trim(),
    getTemporaryReportDraftHtml: _ => ['/temporary-report-tax-orders', '/draft-html'].join('').trim()
  },
  turnoversettings: {
    list: _ => '/turnover-export-settings',
    concrete: id => ['/turnover-export-settings', '/', id].join('').trim(),
    makeExport: id => ['/turnover-export-settings', '/', id, '/make-export-file'].join('').trim(),
    sentToEmail: id => ['/turnover-export-settings', '/', id, '/send'].join('').trim()
  },
  reviews: {
    list: _ => '/feedback',
    concrete: id => ['/feedback', '/', id].join('').trim(),
    process: id => ['/feedback', '/', id, '/process'].join('').trim(),
    close: id => ['/feedback', '/', id, '/close'].join('').trim()
  },
  offlinestatistic: {
    list: _ => '/offline-statistic'
  },
  managerusers: {
    list: _ => '/manager-users',
    concrete: id => ['manager-users', '/', id].join('')
  },
  ofd: {
    list: _ => '/ofds',
    concrete: id => ['ofds', '/', id].join('')
  },
  get ofdsearch () {
    return this.ofd
  }
}

export default routes
