const middleware = {}

middleware['abilities'] = require('../middleware/abilities.js')
middleware['abilities'] = middleware['abilities'].default || middleware['abilities']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['checkRoles'] = require('../middleware/checkRoles.js')
middleware['checkRoles'] = middleware['checkRoles'].default || middleware['checkRoles']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['isPawnshop'] = require('../middleware/isPawnshop.js')
middleware['isPawnshop'] = middleware['isPawnshop'].default || middleware['isPawnshop']

middleware['loadCurrentUser'] = require('../middleware/loadCurrentUser.js')
middleware['loadCurrentUser'] = middleware['loadCurrentUser'].default || middleware['loadCurrentUser']

middleware['loadOrganizations'] = require('../middleware/loadOrganizations.js')
middleware['loadOrganizations'] = middleware['loadOrganizations'].default || middleware['loadOrganizations']

middleware['orgId'] = require('../middleware/orgId.js')
middleware['orgId'] = middleware['orgId'].default || middleware['orgId']

middleware['pageInDevelop'] = require('../middleware/pageInDevelop.js')
middleware['pageInDevelop'] = middleware['pageInDevelop'].default || middleware['pageInDevelop']

middleware['taxTroubles'] = require('../middleware/taxTroubles.js')
middleware['taxTroubles'] = middleware['taxTroubles'].default || middleware['taxTroubles']

export default middleware
