import authUser from '~/modules/auth/authUser'
import Organization from '~/models/directories/Organization'
import currentOrganizationService from '~/modules/auth/services/currentOrganization'

export default async function ({ route }, force = false, silent = true) {
  const excludedRoutes = ['index', 'auth-login', 'payment-success', 'payment-error']
  if (route && excludedRoutes.includes(route.name)) {
    return
  }
  if (authUser.get() && !authUser.get().isSuperAdmin) {
    const reloadOrganizations = true
    if (force || reloadOrganizations) {
      try {
        await Organization.api().all()
        // await Promise.all([
        //   Organization.api().within(authUser.get(), '/organizations'),
        //   authUser.get().isOwner ? Organization.api().within(authUser.get(), '/owned-organization', { dataKey: null }) : null
        // ])
        currentOrganizationService.updateCurrentOrganization()
      } catch (e) {
        if (!silent) { throw e }
      }
    }
  }
}
