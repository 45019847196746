<template lang="pug">
  v-row(justify="center" )
    v-col(cols="12")
      e-input-datetime(v-model="billingDate" outlined )
    v-col(cols="auto")
      v-btn(color="primary"  class="main-button" depressed @click="save" :loading="loading")  Зберегти

</template>

<script>
import EInputDatetime from '~/components/elements/inputs/e-input-datetime'
import Billings from '~/models/billing/Billings'
export default {
  components: { EInputDatetime },
  props: {
    inModal: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    loading: false,
    billingDate: null
  }),
  methods: {
    async save () {
      this.loading = true
      try {
        await Billings.api().changeBillingDate(this.item.id, {
          date_start: this.billingDate
        })
        this.$notification.success('Дата білінгу успішно змінена')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
