import OrmModel from '~/models/abstracts/base/OrmModel'
import InputBuilder from '~/components/abstracts/builders/InputBuilder'

export default class AutocompleteBuilder extends InputBuilder {
  query
  request
  items
  searchModel

  constructor ({
    model = 'autocomplete',
    label = 'autocomplete',
    providerMode = 'lazy',
    ...other
  } = {}, searchModel) {
    super({ component: 'e-autocomplete', model, label })
    this.setProviderMode(providerMode)
    this.setOther(Object.assign({}, other, { searchModel }))
  }

  setAddEntityBtn (addEntityBtn) {
    this.attrs.addEntityBtn = addEntityBtn
  }

  setDeleteEntityBtn (deleteEntityBtn) {
    this.attrs.deleteEntityBtn = deleteEntityBtn
  }

  setDefault (defaultVal) {
    this.defaultVal = defaultVal
  }

  setOther (props) {
    for (const key in props) {
      const value = props[key]
      const name = key.charAt(0).toUpperCase() + key.slice(1)
      const setterExists = (typeof this[`set${name}`] === 'function')

      if (value !== undefined && setterExists) {
        this[`set${name}`](value)
      } else if (!setterExists) {
        throw new TypeError(`Setter with name ${name} doesn't presented in ${this.constructor.name}`)
      }
    }
  }

  setAutoWidth (auto) {
    this.attrs.autoWidth = auto
  }

  setItemText (fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('"item-text" attribute must be a function!')
    }

    this.attrs['item-text'] = fn

    return this
  }

  setItemValue (fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('"item-value" attribute must be a function!')
    }

    this.attrs['item-value'] = fn

    return this
  }

  setReturnObject (returnObject) {
    this.attrs['return-object'] = returnObject
  }

  setCols (cols) {
    this.cols = cols
  }

  setEmmitEmptyObject (emmitEmptyObject) {
    this.attrs.emmitEmptyObject = emmitEmptyObject
  }

  setMultiple (multiple = false) {
    this.attrs.multiple = multiple
  }

  setReadOnly (readonly = false) {
    this.attrs.readonly = readonly
  }

  setClearable (clearable) {
    this.attrs.clearable = clearable
  }

  setQueryConfig (queryConfig) {
    this.attrs.queryConfig = queryConfig
  }

  setChipText (fn) {
    this.attrs.chipText = fn
  }

  setCacheItems (cache) {
    this.attrs.cacheItems = cache
  }

  setWatchProps (watchProps) {
    this.attrs.watchProps = watchProps
  }

  setOnWatchProps (onWatchProps) {
    this.attrs.onWatchProps = onWatchProps
  }

  setEmitSearch (val = true) {
    this.emitSearch = val
  }

  setItemClass (itemClass) {
    this.attrs.itemClass = itemClass
  }

  setRequest (fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('"request" must be a function!')
    }

    this.request = fn

    return this
  }

  setHideDetails (hideDetails) {
    this.attrs.hideDetails = hideDetails
  }

  setContext (context) {
    this.context = context
  }

  setItemsFilter (itemsFilter) {
    this.attrs.itemsFilter = itemsFilter
  }

  setSearchFilter (searchFilter) {
    this.attrs.searchFilter = searchFilter
  }

  setUseDefaultSearchFilter (useDefaultSearchFilter) {
    this.attrs.useDefaultSearchFilter = useDefaultSearchFilter
  }

  setItems (fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('"items" must be a function!')
    }

    this.items = fn

    return this
  }

  setSearchModel (model) {
    if (!(model.prototype instanceof OrmModel)) {
      throw new TypeError(`Search model is not instance of ${OrmModel.prototype.constructor.name}`)
    }

    this.searchModel = model

    return this
  }

  setQuery (fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('"query" must be a function!')
    }

    this.query = fn

    return this
  }

  setAsyncDefault (fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('"query" must be a function!')
    }

    this.asyncDefault = fn

    return this
  }

  setLoadMoreQuery (fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('"query" must be a function!')
    }

    this.loadMoreQuery = fn

    return this
  }

  setHint (hint) {
    this.hint = hint
  }

  setAttrs (attrs) {
    this.attrs = { ...this.attrs, ...attrs }
  }

  setSelectLoading (selectLoading) {
    this.attrs.selectLoading = selectLoading
  }

  setSaveModelInstance (save = true) {
    this.saveModelInstance = save
  }

  setProviderMode (mode) {
    this.provider.mode = mode
  }
}
