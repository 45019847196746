import auth from '~/modules/auth/auth'
import User from '~/models/administration/User'
import Organization from '~/models/directories/Organization'
import Outlets from '~/models/directories/Outlets'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import Dialog from '~/models/system/Dialog'
import AccountingGoodsSettings from '~/modules/goods/models/AccountingGoodsSettings'
import Billings from '~/models/billing/Billings'

const actions = {
  computed: {
    hasDictionary () {
      return this._.isFunction(this._.get(this.model.getOrmDialogsConfig(this.ormDialog), 'dictionary.model'))
    },
    $actions () {
      return [
        {
          name: 'login',
          text: 'Login',
          icon: {
            type: 'e-flat-icon',
            text: 'flaticon-login'
          },
          attrs: {
            color: 'success'
          },
          isPermitted: item => this.$User && this.$User.isSuperAdmin,
          call: this.loginAction
        },
        {
          name: 'edit',
          text: 'edit',
          icon: {
            type: 'e-flat-icon',
            text: 'flaticon-edit'
          },
          attrs: {
            color: 'primary'
          },
          call: this.editAction
        },
        {
          name: 'activateBilling',
          text: 'Resume work',
          icon: {
            type: 'e-svg-icon',
            text: 'continuous'
          },
          call: this.activateBilling
        },
        {
          name: 'defrost',
          text: 'Defrost',
          icon: {
            type: 'e-svg-icon',
            text: 'frost'
          },
          call: this.defrost
        },
        {
          name: 'billingDiscount',
          text: 'Edit discount',
          icon: {
            type: 'e-svg-icon',
            text: 'discount-2'
          },
          call: this.billingDiscount
        },
        {
          name: 'delete',
          text: 'delete',
          icon: {
            type: 'e-svg-icon',
            text: 'trash'
          },
          attrs: {
            color: 'error'
          },
          call: this.deleteAction
        },
        {
          name: 'reset_password',
          text: 'reset password',
          icon: {
            type: 'e-svg-icon',
            text: 'password'
          },
          attrs: {
            color: 'error'
          },
          call: this.resetPassword
        },
        {
          name: 'invoices',
          text: 'Invoices',
          icon: {
            type: 'e-svg-icon',
            text: 'invoice-2'
          },
          call: this.invoicesAction
        },
        {
          name: 'cashRegisters',
          text: 'Cash registers',
          icon: {
            type: 'e-svg-icon',
            text: 'cashbox'
          },
          call: this.cashRegistersAction
        },
        {
          name: 'shifts',
          text: 'Shifts',
          icon: {
            type: 'e-svg-icon',
            text: 'change-2'
          },
          call: this.shiftsAction
        },
        {
          name: 'promoCodes',
          text: 'View promo codes',
          icon: {
            text: 'vouchers',
            type: 'e-svg-icon'
          },
          call: this.promoCodesAction
        },
        {
          name: 'read',
          text: 'View',
          icon: {
            type: 'e-svg-icon',
            text: 'read'
          },
          attrs: {
            color: 'primary'
          },
          call: this.readAction
        },
        {
          name: 'editWithoutSfs',
          text: 'Fix problem',
          icon: {
            type: 'e-svg-icon',
            text: 'wrench'
          },
          call: this.editWithoutSfs
        },
        {
          name: 'sendToSfs',
          text: 'Send to sfs',
          attrs: {
            color: 'primary'
          },
          icon: {
            type: 'e-svg-icon',
            text: 'send'
          },
          visible: (item) => {
            return item.mode === Outlets.itemModes.order.mode
          },
          isPermitted: _ => this.$User && this.$User.isOwner,
          call: this.sendToSfsAction
        },
        {
          name: 'openReceiptView',
          text: 'View receipt',
          icon: {
            text: 'read',
            type: 'e-svg-icon'
          },
          attrs: {
            color: 'primary'
          },
          call: this.openReceiptViewAction
        },
        {
          name: 'addSupplyToProduct',
          text: 'Make supply',
          icon: {
            type: 'e-svg-icon',
            text: 'login'
          },
          visible: item => item.type === AccountingGoods.TYPES_MAP.good,
          call: item => this.addSupplyToProduct('supply', item, 'addSupplyToProduct')
        },
        {
          name: 'addWriteOffToProduct',
          text: 'Write off',
          icon: {
            type: 'e-svg-icon',
            text: 'logout-2'
          },
          visible: item => item.type === AccountingGoods.TYPES_MAP.good && item.count > 0,
          call: item => this.addSupplyToProduct('writeOff', item, 'addWriteOffToProduct')
        },
        {
          name: 'suppliesDetails',
          text: 'Open balance details',
          icon: {
            type: 'e-svg-icon',
            text: 'archive'
          },
          call: this.suppliesDetailsAction
        }
      ]
    }
  },
  methods: {
    getContext (item) {
      return ~this.rows.indexOf(item) ? this.$config.contexts.update : this.$config.contexts.create
    },

    async defaultAction (fields, dictionary = false) {
      if (!await this.$checkOrganization()) {
        return
      }
      this.ormDialog = 'default'
      this.dialogModel = this.model
      if (this.hasDictionary && dictionary) {
        this.dialogModel = this.dialogConfig.dictionary.model
      }
      this.$nextTick(() => {
        this.$refs.modal.fill(this.$config.contexts.create, fields || this.rows, { parent: this.parentItem })
        this.dialog = true
      })
    },

    async loginAction (item) {
      let userId = null
      if (item instanceof User) {
        userId = item.id
      } else if (item instanceof Organization) {
        userId = item.owner.id
      }
      try {
        const res = await auth.loginAsOtherUser(userId)

        if (res === false) {
          this.$notification.error(this.$t('Access denied'))
        } else {
          this.$notification.success(this.$t('You are successfully authorized!'))
          this.$router.push('/dashboard/myorganization')
        }
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },

    editAction (item, preventModelChanges = false) {
      this.ormDialog = 'edit'
      if (!preventModelChanges) {
        this.dialogModel = this.model
      }
      this.$nextTick(() => {
        const ctx = {}
        if (this.parentItem) {
          ctx.parent = this.parentItem
        }
        this.$refs.modal.fill(this.getContext(item), item, ctx)
        this.dialog = true
      })
    },
    editWithoutSfs (item, preventModelChanges = false) {
      this.ormDialog = 'editWithoutSfs'
      if (!preventModelChanges) {
        this.dialogModel = this.model
      }
      this.$nextTick(() => {
        const ctx = {}
        if (this.parentItem) {
          ctx.parent = this.parentItem
        }
        this.$refs.modal.fill(this.getContext(item), item, ctx)
        this.dialog = true
      })
    },
    toggleActive (item) {
      const action = item.active ? 'deactivate' : 'activate'
      this.ormDialog = action
      this.$nextTick(() => {
        const config = this.dialogConfig.title(item)
        const type = { ...config.type.split('|') }
        config.type = this.$tc(type[0], type[1])
        this.$refs.modal.fill(item, this.$t(`m-orm-${action}-dialog.title`, config))
        this.dialog = true
      })
    },
    async activateBilling (item) {
      this.ormDialog = 'activateBilling'
      try {
        const res = this._.get(await Billings.api().all({}, { route: Billings.$routes[Billings.entity].activationCount(item.billing.id) }), 'response.data.count')
        if (res === 1) {
          this.text = this.$t('m-orm-activate-dialog.t-text-1')
        } else if (res >= 2) {
          this.text = this.$t('m-orm-activate-dialog.t-text-2')
        }
      } catch (e) {
        this.$handlers.error(e, this)
      }
      this.$nextTick(() => {
        const config = this.dialogConfig.title(item)
        const type = { ...config.type.split('|') }
        config.type = this.$tc(type[0], type[1])
        this.$refs.modal.fill(item, this.$t('m-orm-activate-dialog.title', config), this.text)
        this.dialog = true
      })
    },
    defrost (item) {
      this.ormDialog = 'defrost'
      this.$nextTick(() => {
        this.$refs.modal.fill(item, this.$t('m-orm-activate-dialog.defrost', this.dialogConfig.title(item)))
        this.dialog = true
      })
    },
    billingDiscount (item) {
      this.ormDialog = 'billingDiscount'
      this.$nextTick(() => {
        this.$refs.modal.fill(item)
        this.dialog = true
      })
    },
    deleteAction (item) {
      this.ormDialog = 'delete'
      this.$nextTick(() => {
        const config = this.dialogConfig.title(item)
        const type = { ...config.type.split('|') }
        config.type = this.$tc(type[0], type[1])

        this.$refs.modal.fill(item, this.$t('m-orm-delete-dialog.title', config))
        this.dialog = true
      })
    },

    cardAction (item) {
      this.ormDialog = 'card'
      this.$nextTick(() => {
        const config = this.dialogConfig.title(item)
        config.type = this.$tc(config.type, 1)

        this.$refs.modal.fill(item, this.$t('m-orm-card-dialog.title', config))
        this.dialog = true
      })
    },

    readAction (item) {
      this.ormDialog = 'read'
      this.$nextTick(() => {
        const config = this.dialogConfig.title(item)
        config.type = this.$tc(config.type, 1)

        this.$refs.modal.fill(item, this.$t('m-orm-view-dialog.title', config))
        this.dialog = true
      })
    },

    dialogAction (item) {
      this.ormDialog = 'dialog'
      this.$nextTick(() => {
        const ctx = {}

        if (this.parentItem) {
          ctx.parent = this.parentItem
        }
        this.$refs.modal.fill(this.getContext(item), item, ctx)
        this.dialog = true
      })
    },

    invoicesAction (item) {
      this.ormDialog = 'invoices'
      this.$nextTick(() => {
        const ctx = {}

        if (this.parentItem) {
          ctx.parent = this.parentItem
        }
        this.$refs.modal.fill(this.getContext(item), item, ctx)
        this.dialog = true
      })
    },

    cashRegistersAction (item) {
      this.ormDialog = 'cashRegisters'
      this.$nextTick(() => {
        const ctx = {}

        if (this.parentItem) {
          ctx.parent = this.parentItem
        }
        this.$refs.modal.fill(this.getContext(item), item, ctx)
        this.dialog = true
      })
    },

    shiftsAction (item) {
      this.ormDialog = 'shifts'
      this.$nextTick(() => {
        const ctx = {}

        if (this.parentItem) {
          ctx.parent = this.parentItem
        }
        this.$refs.modal.fill(this.getContext(item), item, ctx)
        this.dialog = true
      })
    },

    promoCodesAction (item) {
      this.ormDialog = 'promoCodes'
      this.$nextTick(() => {
        const ctx = {}

        if (this.parentItem) {
          ctx.parent = this.parentItem
        }
        this.$refs.modal.fill(this.getContext(item), item, ctx)
        this.dialog = true
      })
    },

    synchronizationAction (item) {
      this.ormDialog = 'synchronization'
      this.$nextTick(() => {
        this.$refs.modal.fill(this.getContext(item), item)
        this.dialog = true
      })
    },

    changeContextAction ({ ctx, item }) {
      this.ormDialog = ctx
      this.$nextTick(() => {
        this.$refs.modal.fill(this.getContext(item), item)
        this.dialog = true
      })
    },

    bindAction (item) {
      // this.ormDialog = ctx
      // this.$nextTick(() => {
      //   this.$refs.modal.fill(this.getContext(item), item)
      //   this.dialog = true
      // })
    },

    assignAction (item) {
      this.ormDialog = 'assign'
      this.$nextTick(() => {
        this.$refs.modal.fill(this.getContext(item), item)
        this.dialog = true
      })
    },

    copyAction (item) {
      this.ormDialog = 'copy'
      this.$nextTick(() => {
        const config = this.dialogConfig.title(item)
        const type = { ...config.type.split('|') }
        config.type = this.$tc(type[0], type[0])
        const successAction = this[this.model.getOrmDialogsConfig('copy').copyConfig.successAction + 'Action']
        this.$refs.modal.fill(item, this.$t('m-orm-copy-dialog.title', config), successAction)
        this.dialog = true
      })
    },
    linkAction (item, attrs) {
      const location = { path: attrs.link }
      if (attrs.query) {
        location.query = this._.isFunction(attrs.query) ? attrs.query(item) : attrs.query
      }
      this.$router.push(location)
    },
    resetPassword (item) {
      this.ormDialog = 'reset_password'
      this.$nextTick(() => {
        const config = this.dialogConfig.title(item)
        const type = { ...config.type.split('|') }
        config.type = this.$tc(type[0], type[0])
        this.$refs.modal.fill(item, this.$t('m-orm-reset-password-dialog.title', config))
        this.dialog = true
      })
    },
    async sendToSfsAction (item) {
      const iitDialog = this._.get(this.$store.state, 'dialogs.dialogs.mDialogIitSignPlugin', null)
      const signData = this._.get(iitDialog, 'actions.signData', null)
      await signData({
        model: this.model,
        createdEntity: item
      })
    },
    openReceiptViewAction (item) {
      this.ormDialog = 'openReceiptView'

      this.$nextTick(() => {
        this.$refs.modal.fill(item)
        this.dialog = true
      })
    },
    async addSupplyToProduct (type, item, ormDialog) {
      const useLeftovers = this._.get(AccountingGoodsSettings.query().first(), 'use_leftovers')
      if (!useLeftovers) {
        const contentDialog = Dialog.query().where('type', 'content').first()
        const enabled = await contentDialog.open({
          width: '500px',
          component: 'm-block-switch-supplies-setting',
          componentProps: {
            useLeftovers: this.useLeftovers
          }
        })
        if (!enabled) {
          return
        }
      }
      this.ormDialog = ormDialog

      this.$nextTick(() => {
        this.$refs.modal.fill({ type, item })
        this.dialog = true
      })
    },
    suppliesDetailsAction (item) {
      this.ormDialog = 'suppliesDetails'
      this.$nextTick(() => {
        const ctx = {}
        if (this.parentItem) {
          ctx.parent = this.parentItem
        }
        this.$refs.modal.fill(this.getContext(item), item, ctx)
        this.dialog = true
      })
    }
  }
}

export default actions
