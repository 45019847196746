<template lang="pug">
  div
    v-data-table(
      v-bind="tableConfig"
      ref="table"
      :options.sync="options"
      :headers="headers"
      :items="rows"
      :item-key="model.entity"
      :loading-text="$t('Loading... Please wait')"
      :locale="$config.locale.iso"
      @toggle-select-all="selectAll"
      @update:options="onOptionsUpdate"
      :class="['m-orm-table']"
    )
      template(v-if="loader" v-slot:body)
        div(class="m-orm-table__loader-wrap")
          e-overlay-block(:loading="loader" opacity="0" progress-size="xl" progress-width="4")

      template(v-if="prepend" v-slot:body.prepend="{headers}")
        t-orm-item(
          v-for="item in prepend.items"
          :key="item.id"
          :model="prepend.model"
          :scopedItem="generatePrependScoped(item, headers)"
          :config="prependTableConfig"
          :dialogModel.sync="prepend.model"
          class="m-orm-table__prepend-tr"
          @update:dialogModel="updateDialogModel"
          :requestParams="requestParams"
          :actions="prepend.itemActions"
        )

      template(v-slot:top)
        slot(name="top"  v-if="!hideTopBar")
          t-orm-toolbar(:color="topBarBackground")
            v-row(:justify="showToolbarLeftSide ? 'space-between' : 'end'")
              v-col(
                v-if="showToolbarLeftSide"
                cols="12"
                sm="auto"
                :class="{ 'pl-0': true, 'm-orm-table__toolbar-left': headersFilter }"
              )
                t-orm-buttons(
                  v-if="showButtons"
                  v-bind="buttonsConfig"
                )
                v-select(
                  v-if="headersFilter"
                  :value="headersFilterValue"
                  :items="tableHeadersItems"
                  :label="$t('Table settings')"
                  @change="handleTableHeadersChange"
                  class="m-orm-table__headers-select pl-3"
                  outlined
                  hide-details
                  multiple
                  return-object
                  small-chips
                  deletable-chips
                  clearable
                )
              v-col(cols="12" sm="auto" class="py-0 table-filtration" :key="key")
                t-orm-filters(v-if="model.ormFilters" v-model="filters" :model="model")

        component(
          v-model="dialog"
          :is="dialogModel.ormDialogs[ormDialog]"
          v-bind="tableDialogConfig"
          @update:ormDialog="changeContextAction"
          @update:selection="defaultAction(dictionaryDataProcessor($event), false)"
        )

        //m-block-table-hint(:model="model" :loading="loader" :hint="hint")

        e-breadcrumbs(
          v-if="breadcrumbs && breadcrumbs.length"
          :items="breadcrumbs"
          class="mb-4 mt-1"
        )

      template(v-slot:item="scopedItemProps")
        t-orm-item(
          :scopedItem="scopedItemProps"
          :dialogModel.sync="model"
          :model="model"
          :config="tableItemConfig"
          @select="selectRow"
          :selected="selected"
          @update:dialogModel="updateDialogModel"
        )

      template(v-if="'footer' in $slots" v-slot:footer)
        slot(name="footer")
</template>

<script>
import pagination from '~/mixins/tables/pagination'
import filtration from '~/mixins/tables/filtration'
import searching from '~/mixins/tables/searching'
import rows from '~/mixins/tables/rows'
import actions from '~/mixins/tables/actions'
import contexts from '~/mixins/props/contexts'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import {
  actions as ormActions,
  ormConfig
} from '~/const/global'
import TOrmItem from '~/components/templates/orm/t-orm-item'
import MOrmCrudDialog from '~/components/modules/dialogs/orm/m-orm-crud-dialog'
import MOrmGridDialog from '~/components/modules/dialogs/orm/m-orm-grid-dialog'
import MOrmSelectDialog from '~/components/modules/dialogs/orm/m-orm-select-dialog'
import MOrmDeleteDialog from '~/components/modules/dialogs/orm/m-orm-delete-dialog'
import MOrmCardDialog from '~/components/modules/dialogs/orm/m-orm-card-dialog'
import MOrmTableDialog from '~/components/modules/dialogs/orm/m-orm-table-dialog'
import MOrmSynchronizationDialog from '~/components/modules/dialogs/orm/m-orm-synchronization-dialog'
import MOrmAssignDialog from '~/components/modules/dialogs/orm/m-orm-assign-dialog'
import TOrmToolbar from '~/components/templates/orm/t-orm-toolbar'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import MOrmCopyDialog from '~/components/modules/dialogs/orm/m-orm-copy-dialog'
import MOrmSelectWithPopularDialog from '~/components/modules/dialogs/orm/m-orm-select-with-popular-dialog'
import MOrmFormsStepperDialog from '~/components/modules/dialogs/orm/m-orm-forms-stepper-dialog'
import MOrmDeactivateDialog from '~/components/modules/dialogs/orm/m-orm-deactivate-dialog'
import MOrmActivateDialog from '~/components/modules/dialogs/orm/m-orm-activate-dialog'
import MStepperDialog from '~/components/modules/dialogs/m-stepper-dialog'
import MOrmResetPasswordDialog from '~/components/modules/dialogs/orm/m-orm-reset-password-dialog'
import MOrmCashRegisterFormDialog from '~/components/modules/dialogs/orm/concrete/m-orm-cash-register-form-dialog'
import MOrmGoodsFormDialog from '~/modules/goods/views/components/m-orm-goods-form-dialog'
import MOrmSuppliesFormDialog from '~/modules/goods/views/components/m-orm-supplies-form-dialog'
import MOrmSendToSfsDialog
  from '~/components/modules/dialogs/orm/concrete/m-orm-send-to-sfs-dialog'
import TempDialog from '~/components/modules/dialogs/temp-dialog'
import MOrmEmployeeFormDialog from '~/components/modules/dialogs/orm/concrete/m-orm-employee-form-dialog'
import MOrmOutletFormDialog from '~/components/modules/dialogs/orm/concrete/m-orm-outlet-form-dialog'
import MOrmBillingDiscountDialog from '~/components/modules/dialogs/orm/concrete/m-orm-billing-discount-dialog'
import MOrmViewDialog from '~/components/modules/dialogs/orm/m-orm-view-dialog'
import MOrmReceiptViewDialog from '~/components/modules/dialogs/concrete/receipt/m-orm-receipt-view-dialog'
import MBlockTableHint from '~/components/modules/blocks/concrete/m-block-table-hint'
import responsive from '~/mixins/pages/responsive'
import EOverlayBlock from '~/components/elements/overlays/e-overlay-block'
import EBreadcrumbs from '~/components/elements/breadcrumbs/e-breadcrumbs'
import MOrmTaxRatesFormDialog from '~/modules/tax-rates/views/components/m-orm-tax-rates-form-dialog'

export default {
  components: {
    EBreadcrumbs,
    EOverlayBlock,
    MOrmReceiptViewDialog,
    MOrmViewDialog,
    MOrmOutletFormDialog,
    MOrmEmployeeFormDialog,
    MOrmSendToSfsDialog,
    MOrmCashRegisterFormDialog,
    MOrmResetPasswordDialog,
    MStepperDialog,
    MOrmActivateDialog,
    MOrmDeactivateDialog,
    MOrmFormsStepperDialog,
    MOrmSelectWithPopularDialog,
    MOrmCopyDialog,
    MOrmTableDialog,
    TOrmItem,
    MOrmCrudDialog,
    MOrmSelectDialog,
    MOrmDeleteDialog,
    MOrmCardDialog,
    MOrmSynchronizationDialog,
    MOrmAssignDialog,
    MOrmGridDialog,
    TOrmButtons,
    TOrmToolbar,
    MBlockTableHint,
    MOrmBillingDiscountDialog,
    TempDialog,
    MOrmGoodsFormDialog,
    MOrmSuppliesFormDialog,
    MOrmTaxRatesFormDialog
  },
  mixins: [pagination, filtration, searching, rows, contexts, checkPropCtx, actions, responsive],
  inheritAttrs: false,
  props: {
    model: {
      type: Function,
      required: true
    },
    hideTopBar: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    prepend: {
      type: Object,
      default: null
    },
    defaultFilter: {
      type: Object,
      default: null
    },
    itemsFilter: {
      type: Object,
      default: null
    },
    parentItem: {
      type: [Object, String],
      default: null
    },
    // TODO: what is it ?
    topBarBackground: {
      type: String,
      default: 'white'
    },
    buttons: {
      type: Array,
      default: _ => ([])
    },
    breadcrumbs: {
      type: Array,
      default: _ => ([])
    },
    customRequest: {
      type: Function,
      default: null
    },
    requestRoute: {
      type: [Function, String, Boolean],
      default: ''
    },
    dataKey: {
      type: [Function, String],
      default: 'data'
    },
    headersFilter: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false,
      tableLoading: false,
      ormDialog: 'default',
      dialogModel: this.model,
      selected: [],
      headersFilterValue: null,
      customTableHeaders: [],
      key: 0
    }
  },
  computed: {
    showToolbarLeftSide () {
      return this.showButtons || this.headersFilter
    },
    headers () {
      return this.headersFilter ? this.customTableHeaders : this.tableHeaders
    },
    loader () {
      return this.tableLoading || this.loading
    },
    modelTableConfig () {
      // NOTE: Config will be cached once.
      return this.model.getTableConfig()
    },
    tableConfig () {
      return Object.assign(this.$config.table, this.modelTableConfig, this.$pagination, this.$searching, this.$attrs)
    },
    dialogConfig () {
      return this.model.getOrmDialogsConfig(this.ormDialog)
    },
    buttonsConfig () {
      const trans = this._.isFunction(this.model.texts)
        ? this.model.texts().buttons.create
        : this.model.ormTrans.single

      const list = this.buttons.map(btn => ({ ...btn, loading: this.loader }))
      const action = this._.get(list, '[0].action')
      const ormToolbarButtons = this._.map(this._.get(this.model, 'ormToolbarButtons', []), (item) => {
        item.parentCtx = this
        return item
      })
      const items = [
        {
          text: `+ ${this.$t('Add')} ${this._.lowerFirst(this.$tc(trans, 1))}`,
          dictionaryFirst: this.hasDictionary,
          attrs: {
            color: 'primary',
            class: ['mr-2 main-button']
          },
          visible: item => this.model.entity !== 'users' && this.$ability.can(ormActions.create, this.model[ormConfig.modelKey]),
          loading: this.loader,
          call: this.defaultAction
        },
        ...ormToolbarButtons
      ]

      switch (action) {
        case 'add':
          items.push(...this._.get(list, '[0].items'))
          break
        case 'replace':
          this._.merge(items, this._.get(list, '[0].items'))
          break
      }

      return {
        context: this.context,
        classes: ['m-orm-table__buttons'],
        items
      }
    },
    showButtons () {
      return this.buttonsConfig.items.some((item) => {
        return this._.isFunction(item.visible) ? item.visible(item) : item.visible
      })
    },
    tableHeaders () {
      return this._.map(this.model.ormHeaders, item => (Object.assign({}, {
        ...item
      }, {
        text: this._.upperFirst((item.text.includes('|'))
          ? this.$tc(item.text.split('|')[0], item.text.split('|')[1])
          : this.$tc(item.text, 1))
      })))
    },
    tableHeadersItemAllValue () {
      return 'allHeaders'
    },
    tableHeadersItemAll () {
      return { text: this.$t('All'), value: this.tableHeadersItemAllValue }
    },
    tableHeadersItems () {
      const items = [...this.tableHeaders]
      if (items.length > 1) {
        items.unshift(this.tableHeadersItemAll)
      }
      return items
    },
    tableDialogConfig () {
      return Object.assign({
        type: this.ormDialog,
        model: this.dialogModel,
        loading: this.loader,
        ref: 'modal'
      }, this.dialogModel.getOrmDialogsConfig(this.ormDialog, {}))
    },
    tableItemConfig () {
      return Object.assign({
        dblclick: this.modelTableConfig.dblClickAction && this[this.modelTableConfig.dblClickAction + 'Action'],
        actions: this.$actions
      }, this.model.ormRowsConfig)
    },
    prependTableConfig () {
      if (!this.prepend) {
        return null
      }
      return Object.assign({
        dblclick: this.prepend.model.getTableConfig().dblClickAction && this[this.prepend.model.getTableConfig().dblClickAction + 'Action'],
        actions: this.$actions
      }, this.prepend.model.ormRowsConfig)
    },
    empty () {
      return this.$t('Table is empty')
    }
  },
  watch: {
    rows () {
      this.$nextTick(() => {
        this.setActionsHeight()
      })
    },
    headersFilterValue (val) {
      const firstItemValue = this._.get(this._.first(val), 'value')
      const storageHeaders = this._.get(this.$localStorageClientData, 'tableHeaders', {})
      this.customTableHeaders = firstItemValue === this.tableHeadersItemAllValue ? this.tableHeaders : val
      this.$setLocalStorageClientData({
        tableHeaders: {
          ...storageHeaders,
          [this.model.entity]: this.customTableHeaders
        }
      })
    }
  },
  created () {
    if (this.$route.hash === '#createDialogOpen') {
      this.defaultAction()
    }
    this.setTableHeaders()
  },
  mounted () {
    this.changeSortByText()
    this.calculateTableMaxHeight()
  },
  methods: {
    calculateTableMaxHeight () {
      const table = document.querySelector('.v-data-table')
      const tableWrapper = table.querySelector('.v-data-table__wrapper')
      const inDialog = table.closest('.v-dialog')
      if (!inDialog) {
        const pageToolbarHeight = this._.get(document.querySelector('.t-orm-page__toolbar-card'), 'clientHeight', 0)
        const tableToolbarHeight = this._.get(table.querySelector('.m-orm-toolbar'), 'clientHeight', 0)
        const tableHintHeight = this._.get(table.querySelector('.info-hint'), 'clientHeight', 0)
        const tableFooterHeight = this._.get(table.querySelector('.v-data-footer'), 'clientHeight', 0)
        tableWrapper.style.maxHeight = `calc(90vh - ${pageToolbarHeight}px - ${tableToolbarHeight}px - ${tableHintHeight}px - ${tableFooterHeight}px)`
      } else {
        tableWrapper.style.maxHeight = '50vh'
      }
    },
    rerenderFields () {
      this.key += 1
    },
    setTableHeaders () {
      if (this.headersFilter && Array.isArray(this.tableHeaders)) {
        const storageHeaders = this._.get(this.$localStorageClientData, `tableHeaders.${this.model.entity}`, [])
        if (storageHeaders.length) {
          const allHeaders = this._.filter(storageHeaders, item => item.value !== this.tableHeadersItemAllValue).length === this.tableHeaders.length
          this.headersFilterValue = allHeaders ? [this.tableHeadersItemAll] : storageHeaders
        } else {
          this.headersFilterValue = this.tableHeaders.length > 1 ? [this.tableHeadersItemAll] : this.tableHeaders
        }
      }
    },
    handleTableHeadersChange (val) {
      let items = val || []
      const hasAllItem = this._.filter(this.tableHeadersItems, item => item.value === this.tableHeadersItemAllValue).length
      const hasAllItemInValue = this._.filter(items, item => item.value === this.tableHeadersItemAllValue).length
      const allItemSelectedLast = this._.get(this._.last(items), 'value') === this.tableHeadersItemAllValue
      if (hasAllItem) {
        if (allItemSelectedLast || !items.length || (items.length === this.tableHeadersItems.length - 1 && !hasAllItemInValue)) {
          items = [this.tableHeadersItemAll]
        } else if (hasAllItemInValue && items.length > 1) {
          items = this._.filter(items, item => item.value !== this.tableHeadersItemAllValue)
        }
      } else if (!items.length) {
        items = [...this.tableHeadersItems]
      }
      this.headersFilterValue = items
    },
    changeSortByText () {
      if (this.xsDevice) {
        const sortLabel = document.querySelectorAll('.v-data-table-header-mobile .v-input .v-label')
        sortLabel.forEach((label) => {
          if (label.textContent === 'Sort by') {
            label.textContent = this.$t(label.textContent)
          }
        })
      }
    },
    updateDialogModel (model) {
      this.dialogModel = model
    },
    onOptionsUpdate (options) {
      const itemsPerPage = this._.get(options, 'itemsPerPage')
      const modelEntity = this._.get(this.model, 'entity')

      if (itemsPerPage && modelEntity) {
        this.$setLocalStorageClientData({
          itemsPerPage: {
            ...this._.get(this.$localStorageClientData, 'itemsPerPage'),
            [modelEntity]: itemsPerPage
          }
        })
      }
    },
    generatePrependScoped (item, headers) {
      return {
        item,
        headers
      }
    },
    dictionaryDataProcessor (data) {
      return this.hasDictionary && this._.isFunction(this.dialogConfig.dictionary.dataProcessor) ? this.dialogConfig.dictionary.dataProcessor(data) : data
    },
    fixedActionsClass () {
      const wrapper = this._.get(this.$refs, 'table.$el', null)
      const table = wrapper && wrapper.querySelector('table')
      const hasActions = this._.get(this.model, 'ormActions', []).length

      if (hasActions && wrapper && table && (table.clientWidth > wrapper.clientWidth) && !this.xsDevice) {
        return 'fixed-actions'
      }
    },
    setActionsHeight () {
      const table = this._.get(this.$refs, 'table', null)

      if (table) {
        const theadRow = table.$el.querySelector('table thead tr')
        const actionsTh = theadRow && theadRow.lastChild

        if (actionsTh) {
          actionsTh.style.height = theadRow.clientHeight + 'px'
        }
      }
    },
    selectAll (data) {
      this.selected = data.value ? this._.map(this._.get(data, 'items', []), (item, index) => ({ index, item })) : []
    },
    selectRow (item, val) {
      if (val) {
        this.selected.push(item)
      } else {
        this.selected = this._.filter(this.selected, selected => selected.index !== item.index)
      }
    }
  }
}
</script>

<style lang="scss">
.m-orm-table {
  /*TODO because tables do not have full width in modal dialogs (ex. goods card page)*/
  width: 100%;

  &__buttons {
    margin-bottom: 10px;
  }

  .v-data-table__wrapper {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    overflow: auto;

    table {
      overflow: auto;

      thead {
        position: sticky;
        background-color: #fff;
        top: 0;
        z-index: 3;
      }
    }
  }

  .v-data-footer {
    border-top: none !important;
  }

  &__loader-wrap {
    position: relative;
    padding: 100px 0;
    display: table-row;
    height: 300px;
  }

  .table-filtration {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px 24px;
  }

  .v-label {
    &--active {
      background: #fff;
      padding-right: 3px;
      margin-right: -3px;
      z-index: 3;
    }
  }

  &__headers-select {
    max-width: 300px;

    .v-select {
      &__selections {
        max-height: 100px;
        padding: 0;

        &::-webkit-scrollbar-track {
          margin: 2px 0;
        }
      }
    }
  }

  &__toolbar-left {
    display: flex;

    .col {
      flex: 0;
    }
  }
}
</style>
