<template lang="pug">
  t-orm-simple-data(:items="fields" class="mb-3")
</template>

<script>
import TOrmSimpleData from '~/components/templates/orm/t-orm-simple-data'
import dateTime from '~/mixins/datetime/dateTime'
import Orders from '~/modules/acquiring/models/Orders'
export default {
  name: 'MBlockAcquiringOrderView',
  components: { TOrmSimpleData },
  mixins: [dateTime],
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    fields () {
      return [
        {
          label: 'Status',
          val: Object.values(Orders.statuses).find(elem => elem.value === this.order.status).text
        },
        {
          label: 'Date',
          val: this.formatTime(this.order.dateCreated)
        },
        {
          label: 'Payed date',
          val: this.formatTime(this.order.payedAt),
          hideEmpty: true
        },
        {
          label: 'Total sum',
          val: ((this.order.totalSum || 0) / 100).toFixed(2)
            .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
        },
        {
          label: 'Cash register ',
          val: this.order.cashRegister.fiscalNumber
        },
        {
          label: 'Employee',
          val: this.order.employee.login
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
