<template lang="pug">
  div(class="dialog")
    div(class="dialog__title") {{ $t('Employee password modifying') }} {{ _.get(item, 'login') }}
    ValidationObserver(:ref="model.entity" slim)
      v-row()
        v-col( cols="12" class="mt-1 mb-1")
          t-orm-fields(
            v-bind.sync="editedItem"
            :items="fields"
          )
      v-row(class="mb-4")
        v-col(cols="12")
          t-orm-buttons(:items="buttons" :classes="['text-center']")
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import validate from '~/mixins/validation/validate'
import Employees from '~/modules/employees/models/Employees'

export default {
  components: {
    TOrmButtons,
    TOrmFields
  },
  mixins: [validate],
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    isCreating: {
      type: Boolean,
      default: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    model: Employees,
    editedItem: {
      password: null,
      passwordConfirmation: null
    },
    savingLoading: false
  }),
  computed: {
    fields () {
      return [
        {
          component: 'e-input-password',
          model: 'password',
          provider: {
            name: this.$t('Password'),
            vid: 'password',
            rules: 'required|min:6',
            mode: 'lazy'
          },
          attrs: {
            label: this.$t('Password'),
            type: 'password',
            outlined: true,
            showPasswordStrength: true
          }
        },
        {
          component: 'e-input-password',
          model: 'passwordConfirmation',
          provider: {
            name: this.$t('Confirm password'),
            vid: 'passwordConfirmation',
            rules: 'required|confirmed:password',
            mode: 'lazy'
          },
          attrs: {
            label: this.$t('Confirm password'),
            type: 'passwordConfirmation',
            outlined: true
          }
        }
      ]
    },
    buttons () {
      return [
        {
          text: 'Save',
          attrs: {
            depressed: true,
            class: 'main-button'
          },
          call: this.save,
          loading: this.savingLoading
        }
      ]
    }
  },
  methods: {
    async save () {
      try {
        this.savingLoading = true
        const valid = await this.validate()
        if (!valid) {
          return
        }
        await this.model.api().changePassword(this._.get(this.item, 'id'), this.editedItem)
        this.$notification.success(this.$t('Cashier password successfully changed'))
        this.$emit('dialog:close')
        if (this.$attrs.closeModal) {
          this.$attrs.closeModal()
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.savingLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__loader {
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__serial-number {
    color: $color-error;
    margin-left: 8px;
  }
}
</style>
