<template lang="pug">
  div(class="dialog")
    div(class="dialog__title text-center mb-4") {{ $t('Bank terminal settings', { fiscalNumber: _.get(item, 'fiscalNumber') }) }}
    div()
      ValidationObserver(ref="form" slim)
        t-orm-fields(
          v-bind.sync="form"
          :items="fields"
        )
    div(class="dialog__actions")
      v-btn(
        class="main-button d-block mx-auto"
        :loading="loading"
        @click="submit"
      ) {{ $t('Save') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import CashRegistersSearch from '@/modules/cashRegisters/models/CashRegistersSearch'
import commonData from '~/modules/receipt/mixins/getters/commonData'

export default {
  name: 'MFormPosTerminalSettings',
  components: {
    TOrmFields
  },
  mixins: [commonData],
  props: {
    item: {
      type: null,
      required: true
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    devicesLoading: false,
    debouncedGetDevices: null,
    form: {
      ipAddress: null,
      port: null,
      terminalName: null,
      usage: true
    }
  }),
  computed: {
    fields () {
      return [
        {
          model: 'ipAddress',
          component: 'v-text-field',
          provider: {
            vid: 'ipAddress',
            name: 'IP address',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'IP address'
          },
          fieldVal: () => this._.get(this.item, 'posTerminalSetting.ipAddress', '127.0.0.1')
        },
        {
          model: 'port',
          component: 'v-text-field',
          provider: {
            vid: 'port',
            name: 'Port',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'Port'
          },
          fieldVal: () => this._.get(this.item, 'posTerminalSetting.port', '9020')
        },
        {
          model: 'terminalName',
          component: 'v-text-field',
          provider: {
            vid: 'terminalName',
            name: 'Terminal ID',
            rules: 'required',
            loading: this.devicesLoading
          },
          attrs: {
            outlined: true,
            label: 'Terminal ID'
          },
          fieldVal: () => this._.get(this.item, 'posTerminalSetting.terminalName', '')
        },
        {
          model: 'usage',
          component: 'v-switch',
          provider: {
            vid: 'usage',
            name: 'Connect'
          },
          attrs: {
            label: 'Connect'
          },
          fieldVal: () => this._.get(this.item, 'posTerminalSetting') ? this._.get(this.item, 'posTerminalSetting.usage') : true
        }
      ]
    }
  },
  methods: {
    async submit () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        await CashRegisters.api().posTerminalSettings(this.item, { posTerminalSetting: this.form })
        if (this._.get(this.item, 'id') === this._.get(this.authenticatedCashier, 'cashRegister.id')) {
          await CashRegistersSearch.api().read(this._.get(this.item, 'id'))
        }
        this.$notification.success(this.$t('Bank terminal settings have been successfully saved'))
        await this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
