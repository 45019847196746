import { Model } from '@vuex-orm/core'
import Employees from '~/modules/employees/models/Employees'
import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import FilterAble from '~/models/mixins/FilterAble'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import Shifts from '~/models/directories/Shifts'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import CashRegistersSearch from '~/modules/cashRegisters/models/CashRegistersSearch'
import { formats } from '~/const/global'

export class Receipts extends ChainInheritance(Directory, [
  FilterAble,
  PersistAble,
  TimestampAble,
  AssignAble]) {
  static entity = 'receipts'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = ''
  static forceFiltered = true
  static persistOptions = {
    insertOrUpdate: [Employees.entity, CashRegisters.entity]
  }

  static statusesMap = { CREATED: true, CLOSED: false }

  static processingStatuses = {
    CREATED: {
      value: 'PENDING',
      text: 'Created  '
    },
    DONE: {
      value: 'DONE',
      text: 'Issued'
    },
    ERROR: {
      value: 'ERROR',
      text: 'Error'
    }
  }

  static TYPES = {
    SELL: 'SELL',
    RETURN: 'SELL_RETURN',
    SERVICE_IN: 'SERVICE_IN',
    SERVICE_OUT: 'SERVICE_OUT'
  }

  static fields () {
    return {
      cash_register_id: this.attr(null),
      cashier_id: this.attr(null),
      shift_id: this.attr(null),

      id: this.attr(null),
      status: this.attr(),
      operation: this.attr(),
      ticket_number: this.attr(),
      created_at: this.attr(),
      updated_at: this.attr(),
      error_message: this.attr(),
      offline_ticket_number: this.attr(),
      organization_id: this.attr(),
      shift: this.belongsTo(Shifts, 'shift_id'),
      // cashRegister: this.belongsTo(CashRegisters, 'cash_register_id'),
      // employee: this.belongsTo(Employees, 'cashier_id'),
      qr_code: this.attr()
    }
  }

  get cashRegister () {
    return this.shift.cashRegister
  }

  get employee () {
    return this.shift.cashier
  }

  static ormRelationMap = [
    // 'group',
    'shifts'
  ]

  static ormTrans = {
    single: 'Receipt',
    multy: 'Receipts',
    notificationSingle: 'Receipt ',
    pageTitle: 'Receipts archive'
  }

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['status', 'updatedAt'],
      'sort-desc': [true, true]
    })
  }

  static ormFilters = [
    {
      model: 'fiscalDate',
      type: 'dateRange',
      component: 'e-input-datetime-range',
      default: () => {
        const date = new Date()
        date.setMonth(date.getMonth() - 1)
        return [this.$moment(date).format(formats.dateISO8601), this.$moment(new Date()).format(formats.dateISO8601)]
      },
      attrs: {
        closeOnClick: false,
        clearable: false,
        emmitOnlyOnSelectClicked: true,
        type: 'date',
        range: true,
        'hide-details': true,
        outlined: true,
        label: 'Fiscal date',
        min: (dates) => {
          if (dates && dates.length === 1) {
            const date = new Date(dates[0])
            date.setMonth(date.getMonth() - 2)
            return this.$moment(date).format(formats.dateISO8601)
          }
        },
        max: (dates) => {
          const today = new Date()
          const formattedToday = this.$moment(today).format(formats.dateISO8601)
          if (dates && dates.length === 1) {
            const date = new Date(dates[0])
            date.setMonth(date.getMonth() + 2)
            if (date.getTime() > today.getTime()) {
              return formattedToday
            }
            return this.$moment(date).format(formats.dateISO8601)
          }
          return formattedToday
        }
      }
    },
    new AutocompleteBuilder({
      model: 'cashRegister',
      label: 'Cash register ',
      emmitEmptyObject: true,
      autoWidth: true,
      emitSearch: false,
      itemClass: 'ws-pre',
      query: model => model.api().filter({ status: CashRegisters.itemModes.active.value })
    }, CashRegistersSearch).build(),
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search by receipt number',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'start'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 4,
                md: 4,
                sm: 6
              },
              fields: [
                'fiscalDate'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 4,
                md: 4,
                sm: 6
              },
              fields: [
                'cashRegister'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 4,
                md: 4,
                sm: 6
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  get fiscalDateString () {
    return this.getDateTimeProcessing(this.created_at)
  }

  get totalSumString () {
    return (this.totalSum / 100).toFixed(2)
      .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  get operationString () {
    if (this.operation === Receipts.TYPES.SELL) {
      return 'Selling'
    } else if (this.operation === Receipts.TYPES.RETURN) {
      return 'Return'
    }
    return '–'
  }

  get ticketNumberString () {
    return `${this.ticket_number || '–'}`
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', sortable: false, filterable: false, sortQuery: 'status' },
    { text: 'Type', value: 'operationString', sortable: false, filterable: false },
    { text: 'Date/time', value: 'fiscalDateString', sortable: false, filterable: false },
    { text: 'Fiscal sign', value: 'ticketNumberString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormColsComponents = {
    status: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) { return {} }
          const {
            DONE,
            CREATED,
            ERROR
          } = this.processingStatuses

          return {
            id: item.id,
            [DONE.value]: {
              text: DONE.text,
              type: DONE.value,
              appearance: {
                color: '#161b25',
                textColor: '#fff'
              }
            },
            [ERROR.value]: {
              text: ERROR.text,
              type: ERROR.value,
              appearance: {
                color: 'rgba(255, 0, 43, 0.1)',
                textColor: '#ff002b'
              },
              tooltip: item.error_message
            },
            [CREATED.value]: {
              text: CREATED.text,
              type: CREATED.value,
              appearance: {
                color: 'rgba(255, 170, 1, 0.2)',
                textColor: '#c37f00'
              }
            }
          }
        }
      }
    },
    ticketNumberString: {
      component: 'e-copy-text'
    }
  }

  static ormRowsConfig = {
    disabled: scopedItem => 'status' in scopedItem.item ? scopedItem.item.status === 'CLOSED' : false
  }

  static ormFields = [
    //
  ]

  static ormActions = [
    {
      name: 'openReceiptView'
    },
    {
      name: 'refundReceipt',
      text: 'Refund receipt',
      icon: {
        type: 'e-svg-icon',
        text: 'selling-2'
      },
      visible: item => item.operation === Receipts.TYPES.SELL,
      call: (item, data, ctx) => {
        ctx.$router.push({
          path: '/dashboard/refundreceipt',
          query: {
            search: item.ticket_number,
            date: item.created_at
          }
        })
      }
    }
  ]

  static ormDialogs = {
    openReceiptView: 'm-orm-receipt-view-dialog'
  }

  static ormDialogsConfig = {
    openReceiptView: {
      config: {
        width: '425px',
        view: 'png',
        download: 'pdf',
        downloadNameField: 'ticket_number',
        downloadPrefix: 'receipt',
        type: 'receipt'
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.processingRead = function (id, token) {
        return this.get(Model.$routes.receipts.processingRead(id),
          {
            save: false
          }
        )
      }
      configActions.processingReadDetailed = function (id) {
        return this.get(Model.$routes.receipts.processingReadDetailed(id), { save: false })
      }
      configActions.processingSendEmail = function (id, payload) {
        return this.post(Model.$routes.receipts.processingSendEmail(id), payload, {
          save: false
        })
      }
      configActions.processingCreate = function (payload, token) {
        return this.post(Model.$routes.receipts.processingCreate(), payload, {
          save: false
        })
      }
      configActions.processingCreateService = function (payload, token) {
        return this.post(Model.$routes.receipts.processingCreateService(), payload, {
          save: false,
          headers: { 'X-Processing-Authorization': token }
        })
      }
      configActions.processingReadText = function (id, params) {
        return this.get(Model.$routes.receipts.processingReadText(id), {
          save: false,
          params
        })
      }
      configActions.processingReadPng = function (id, params) {
        return this.get(Model.$routes.receipts.processingReadPng(id), {
          responseType: 'blob',
          save: false,
          params
        })
      }
      configActions.processingReadTestText = function () {
        return this.get(Model.$routes.receipts.processingReadTestText(), { save: false })
      }
      configActions.processingReadTestPng = function (params) {
        return this.get(Model.$routes.receipts.processingReadTestPng(), {
          responseType: 'blob',
          save: false,
          params
        })
      }
      configActions.processingReadPdf = function (id) {
        return this.get(Model.$routes.receipts.processingReadPdf(id), {
          responseType: 'blob',
          save: false
        })
      }
      return configActions
    }
  }
}

export default Receipts
