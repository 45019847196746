import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'

export class Supplies extends PersistAble(OrmModel) {
  static entity = 'supplies'
  static defaultSortParam = 'name'
  static defaultSortOrder = true
  static paginated = true
  static persistOptions = {
    insertOrUpdate: ['accountinggoods']
  }

  static ormTrans = {
    single: 'Supply ',
    multy: 'Leftovers'
  }

  static fields () {
    return {
      good_id: this.attr(null),

      id: this.attr(null),
      supplier: this.attr(null),
      good: this.belongsTo(AccountingGoods, 'good_id'),
      date: this.attr(null),
      count: this.attr(null),
      purchase_price: this.attr(null)
    }
  }

  get priceString () {
    return (this.purchase_price / 100).toFixed(2)
      .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  get supplierString () {
    return this.supplier && this.supplier.name
  }

  get goodString () {
    const product = AccountingGoods.query().whereId(this.good_id).first()
    return product && product.name
  }

  get dateString () {
    return this.getDateTime(this.date)
  }

  get countString () {
    const product = AccountingGoods.query().whereId(this.good_id).first()
    const isWeight = this._.get(product, 'is_weight', null)
    if (isWeight) {
      return ((this.count || 0) / 1000).toFixed(3)
    } else {
      return ((this.count || 0) / 1000).toFixed(0)
    }
  }

  static ormHeaders = [
    { text: 'good', value: 'goodString', sortable: true, filterable: false },
    { text: 'Supply ', value: 'countString', sortable: true, filterable: false },
    { text: 'Date of delivery', value: 'dateString', sortable: true, filterable: false },
    { text: 'Provider', value: 'supplierString', sortable: true, filterable: false },
    { text: 'Purchase price, ₸', value: 'priceString', sortable: true, align: 'center', width: '160', filterable: false }
  ]

  static ormActions = []

  static ormFields = []

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.addSupplyToMultipleProducts = function (payload) {
        return this.post(Model.$routes.supplies.list(), payload)
      }
      return configActions
    }
  }
}

export default Supplies
