import { Model } from '@vuex-orm/core'
import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import Dialog from '~/models/system/Dialog'
import authUser from '~/modules/auth/authUser'

export class CashRegisters extends PersistAble(Directory) {
  static entity = 'cashregisters'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'status'
  static defaultSortOrder = false
  static dblClickAction = 'read'

  static itemModes = {
    active: {
      text: 'Active',
      value: 'active'
    },
    awaiting: {
      text: 'Waiting for activation',
      value: 'awaiting'
    },
    blocked: {
      text: 'Blocked',
      value: 'blocked'
    },
    error: {
      text: 'Error',
      value: 'error'
    },
    newCashRegister: {
      text: 'Created ',
      value: 'new'
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      cashNumber: this.attr(null),
      status: this.attr(null),
      serialNumber: this.attr(null),
      organization: this.attr(null),
      ofdToken: this.attr(null),
      ofd: this.attr(null),
      offlineMode: this.attr(null),
      reason: this.attr(null),
      timezone: this.attr(null),
      address: this.attr(null)
    }
  }

  static ormTrans = {
    single: 'Cash register',
    multy: 'Cash registers',
    notification: 'Cash register'
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', align: 'left', filterable: true, sortable: true, width: '150' },
    { text: 'Serial number', value: 'serialNumber', align: 'left', filterable: true, sortable: false },
    { text: 'Cash register number', value: 'cashNumber', align: 'left', filterable: true, sortable: true },
    { text: 'Address', value: 'address', align: 'left', filterable: false, sortable: false, width: '150' },
    { text: 'Actions', value: 'actions', align: 'center', filterable: false, sortable: false, width: '72' }
  ]

  static ormRowsConfig = {
    disabled: false
  }

  static ormColsComponents = {
    status: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) { return {} }
          const {
            active,
            blocked,
            error,
            awaiting,
            newCashRegister
          } = this.itemModes

          return {
            id: item.id,
            [active.value]: {
              text: active.text,
              type: active.value
            },
            [blocked.value]: {
              text: blocked.text,
              type: blocked.value,
              appearance: {
                color: 'rgba(255, 0, 43, 0.1)',
                textColor: '#ff002b'
              }
            },
            [error.value]: {
              text: error.text,
              type: error.value
            },
            [awaiting.value]: {
              text: awaiting.text,
              type: awaiting.value,
              appearance: {
                color: 'rgba(255, 170, 1, 0.2)',
                textColor: '#c37f00'
              }
            },
            [newCashRegister.value]: {
              text: newCashRegister.text,
              type: newCashRegister.value
            }
          }
        }
      }
    },
    address: {
      component: 'e-model-cash-register-address'
    }
  }

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  // TODO add approach to crete custom actions
  static ormActions = [
    {
      name: 'read'
    },
    {
      name: 'edit',
      visible: (item) => {
        const isSuperAdmin = authUser.get() && authUser.get().isSuperAdmin
        return !isSuperAdmin && item.status === CashRegisters.itemModes.active.value
      },
      call: (cashRegister, data, ctx) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          component: 'form-cash-register',
          width: '600px',
          componentProps: {
            title: 'Cash register editing',
            titleAppend: String(cashRegister.cashNumber),
            btnText: 'Save',
            item: cashRegister,
            isCreating: false,
            isEditing: true
          }
        })
      }
    }
  ]

  static ormDialogs = {
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    read: {
      title: item => ({ type: 'Cash register ', name: item.cashNumber }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'serialNumber',
            label: 'Serial number'
          },
          {
            model: 'cashNumber',
            label: 'Cash register number',
            hideEmpty: true
          },
          {
            model: 'ofdId',
            label: 'OFD ID'
          },
          {
            model: 'ofdToken',
            label: 'OFD Token'
          },
          {
            model: 'identifier',
            label: 'ID KKM'
          },
          {
            model: 'registerNumber',
            label: 'РНМ'
          },
          {
            model: 'timezone',
            label: 'Timezone'
          },
          {
            model: 'address',
            label: 'Address'
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.getShift = function (id) {
        return this.get(Model.$routes.cashregisters.getShift(id), { save: false })
      }
      configActions.flags = function (id) {
        return this.get(Model.$routes.cashregisters.flags(id), { save: false })
      }
      configActions.closeShift = function (id, payload) {
        return this.post(Model.$routes.cashregisters.closeShift(id), payload, { save: false })
      }
      return configActions
    }
  }
}

export default CashRegisters
