import { Model } from '@vuex-orm/core'
import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import AssignAble from '~/models/mixins/AssignAble'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import User from '~/models/administration/User'
import Stepper from '~/view_configs/modules/stepper/Stepper'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import Tags from '~/models/directories/Tags'

export default class Organization extends ChainInheritance(Administration, [
  SoftDeletAble,
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'organization'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'name'
  static defaultSortOrder = false
  static persistOptions = {
    insertOrUpdate: ['users']
  }

  static fields () {
    return super.fields({
      owner_id: this.attr(null),
      ownerId: this.attr(null),

      id: this.attr(null),
      name: this.attr(null),
      economicType: this.attr(null),
      dgd: this.attr(null),
      ugd: this.attr(null),
      taxType: this.attr(null),
      vatNumber: this.attr(null),
      vatSeries: this.attr(null),
      vatPayer: this.attr(null),
      owner: this.belongsTo(User, 'owner_id')
    })
  }

  static ormRelationMap = [
    'owner'
  ]

  static ormFieldsMap = {}

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormTrans = {
    single: 'Organization',
    multy: 'Organizations',
    dialog: 'organization'
  }

  get ownerName () {
    return this._.get(this.owner, 'profile.name', '')
  }

  get phone () {
    return this._.get(this.owner, 'profile.phone', '-')
  }

  get email () {
    return this._.get(this.owner, 'profile.email', '-')
  }

  static ormHeaders = [
    { text: 'Name', value: 'name', sortable: true, filterable: true },
    { text: 'Owner', value: 'owner', sortable: false, filterable: false },
    { text: 'Phone', value: 'phone', sortable: false, filterable: false },
    { text: 'Email', value: 'email', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['active'],
      'sort-desc': [true]
    })
  }

  static ormActions = [
    {
      name: 'login'
    },
    {
      name: 'read',
      text: 'View',
      icon: {
        text: 'info',
        type: 'e-svg-icon'
      }
    },
    {
      name: 'cashRegisters'
    }
  ]

  static ormFields = [
    {
      model: 'name',
      component: 'v-text-field',
      provider: {
        vid: 'name',
        name: 'Name',
        rules: 'required'
      },
      attrs: {
        label: 'Name',
        outlined: true
      }
    },
    {
      model: 'shortName',
      component: 'v-text-field',
      provider: {
        vid: 'shortName',
        name: 'Short name',
        rules: 'required'
      },
      attrs: {
        label: 'Short name',
        outlined: true
      }
    },
    {
      model: 'edrpou',
      component: 'v-text-field',
      provider: {
        vid: 'edrpou',
        name: 'EDRPOU',
        rules: 'required|inn_passport'
      },
      attrs: {
        label: 'EDRPOU',
        outlined: true
      }
    },
    {
      model: 'taxNumber',
      component: 'v-text-field',
      provider: {
        vid: 'taxNumber',
        name: 'Tax number',
        rules: 'required|numeric|max:12'
      },
      attrs: {
        label: 'Tax number',
        outlined: true
      }
    },
    {
      model: 'personType',
      component: 'v-select',
      default: items => items[0],
      provider: {
        vid: 'personType',
        name: 'Person type',
        rules: 'required'
      },
      attrs: {
        label: 'Person type',
        outlined: true
      },
      items: () => this.personTypes
    },
    {
      model: 'tags',
      component: 'v-select',
      provider: {
        name: 'Tags',
        vid: 'tags'
      },
      default: [],
      attrs: {
        label: 'Tags',
        outlined: true,
        multiple: true,
        'return-object': true,
        'item-text': value => value.name
      },
      async request () {
        Tags.deleteAll()
        await Tags.api().all()
      },
      items: () => Tags.all()
    }
  ]

  static ormColsComponents = {
    owner: {
      component: 'e-organization-owner'
    }
  }

  static ormDialogs = {
    default: 'm-orm-forms-stepper-dialog',
    edit: 'm-orm-crud-dialog',
    read: 'm-orm-view-dialog',
    cashRegisters: 'm-orm-table-dialog'
  }

  static ormDialogsConfig = {
    default: {
      stepperFields: [['name', 'shortName', 'edrpou', 'taxNumber', 'personType', 'logo'], []],
      stepperConfig: new Stepper(
        [null, {
          component: 't-orm-fields-content-wrapper',
          attrs: {
            model: User,
            property: 'owner'
          }
        }],
        1,
        ['Create organization', 'Create owner'])
    },
    edit: {
      config: {
        context: 'update'
      }
    },
    read: {
      title: item => ({ name: item.name }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'name',
            label: 'Name'
          },
          {
            model: 'ownerName',
            label: 'Owner'
          },
          {
            model: 'phone',
            label: 'Phone',
            value: val => val || '-'
          },
          {
            model: 'email',
            label: 'Email',
            value: val => val || '-'
          },
          {
            model: 'economicType',
            label: 'Type of economic activity (OKED)'
          },
          {
            model: 'taxType',
            label: 'Tax regime'
          },
          {
            model: 'dgd',
            label: 'DGD at the place of registration'
          },
          {
            model: 'ugd',
            label: 'UGD at the place of registration'
          },
          {
            model: 'vatPayer',
            label: 'VAT payer',
            value: (val) => {
              return val ? 'Да' : 'Нет'
            }
          },
          {
            model: 'vatSeries',
            label: 'VAT series'
          },
          {
            model: 'vatNumber',
            label: 'VAT number'
          }
        ]
      }
    },
    cashRegisters: {
      title: item => ({ type: this.ormTrans.single, name: item.name }),
      config: {
        model: CashRegisters
      },
      requestRoute: item => Organization.$routes[Organization.entity].cashRegisters(item.id)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      return configActions
    }
  }
}
