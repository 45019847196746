import setAuthenticatedCashier from '~/modules/receipt/mixins/setters/setAuthenticatedCashier'
import informationDialog from '~/mixins/dialogs/informationDialog'
import Shifts from '~/models/directories/Shifts'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import processingAuth from '~/modules/receipt/mixins/actions/processingAuth'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'

const processingShifts = {
  mixins: [setAuthenticatedCashier, informationDialog, processingAuth],
  methods: {
    async checkIfFirstWork () {
      if (this._.get(this.$localStorageClientData, 'visitedMenu[dashboard-Faq]', false)) {
        return false
      }
      try {
        const shiftsCount = this._.get(await Shifts.api().filter({ limit: 1, offset: 1 }).all({ save: false }), 'response.data.meta.totalItems')
        return !parseInt(shiftsCount)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
        return false
      }
    },

    // top level functions
    async processShiftForCashier (authenticatedCashier) {
      if (!await this.checkShiftForCashier(authenticatedCashier)) {
        await this.createShiftForCashier(authenticatedCashier)
      }
    },

    async checkShiftForCashier (authenticatedCashier) {
      // await authenticatedCashier.maybeStartSignAgent()
      const currentShift = this._.get(await this.getExistedShift(authenticatedCashier), 'response.data', null)
      if (currentShift) {
        const shiftCashRegisterId = this._.get(currentShift, 'cash_register_id', null)
        const currentCashRegisterId = this._.get(authenticatedCashier, 'cashRegister.id', false)
        if (shiftCashRegisterId !== currentCashRegisterId) {
          const serialNumber = this._.get(await CashRegisters.api().read(shiftCashRegisterId), 'response.data.serialNumber')
          await this.informationDialog.open({
            text: this.$t('dialogs.information.youHaveShiftInOtherCashRegister', { serialNumber }),
            buttonText: 'Okay',
            textClass: 'text-center',
            width: '600px'
          })
          await this.logOut(authenticatedCashier)
          return false
        }

        if (currentShift.status === Shifts.processingStatuses.OPENED) {
          const currentDate = new Date()
          const shiftOpenedDate = new Date(this._.get(currentShift, 'opened_at', currentDate))

          if (shiftOpenedDate.setUTCHours(0, 0, 0, 0) - currentDate.setUTCHours(0, 0, 0, 0) < 0) {
            await this.informationDialog.open({
              text: this.$t('dialogs.information.youHaveOpenedShiftInPast'),
              buttonText: 'Okay',
              textClass: 'text-center',
              width: '600px'
            })
          }

          // if (await this.checkIfFirstWork()) {
          //   await this.informationDialog.open({
          //     hideCloseButton: true,
          //     width: '530px',
          //     text: 'Before starting work, we recommend that you read the "Rules of work with the cash register"',
          //     buttonText: 'Go',
          //     onConfirm: () => this.$router.push('/dashboard/faq')
          //   })
          // }

          await this.setAuthenticatedCashier({ shift: currentShift }, authenticatedCashier)
          return true
        }

        if (currentShift.status === Shifts.processingStatuses.CREATED) {
          const intervalRequest = new IntervalRequest(() => this.getExistedShift(authenticatedCashier))
          const resolveCondition = ({ response }) => (response.data.status === Shifts.processingStatuses.OPENED)
          const rejectCondition = ({ response }) => (response.data.status === Shifts.processingStatuses.CLOSED)
          try {
            const signedShift = this._.get(await intervalRequest.startExponential(resolveCondition, rejectCondition), 'response.data')
            await this.setAuthenticatedCashier({ shift: signedShift }, authenticatedCashier)
            return true
          } catch (e) {
            throw new Error(this.$t('Unavailable to open shift. Please, contact with support'))
          }
        }
      } else {
        await this.setAuthenticatedCashier({ shift: null }, authenticatedCashier)
        return false
      }
    },

    async createShiftForCashier (authenticatedCashier) {
      await authenticatedCashier.maybeStartSignAgent()
      let openedShift, intervalRequest
      const resolveCondition = ({ response }) => (response.data.status === Shifts.processingStatuses.OPENED || response.data.status === Shifts.processingStatuses.CLOSED)
      try {
        const shiftCreating = this._.get(await this.createShift(authenticatedCashier), 'response.data')
        intervalRequest = new IntervalRequest(() => this.getShift(shiftCreating.id, authenticatedCashier.accessToken))
      } catch (e) {
        throw new Error(this._.get(e, 'response.data.detail', e))
      }
      try {
        openedShift = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data')
        if (openedShift.initial_transaction.status === 'ERROR' && openedShift.initial_transaction.response_error_message) {
          throw new Error(openedShift.initial_transaction.response_error_message)
        }
      } catch (e) {
        throw new Error(this.$t('Error when open shift:') + ' ' + e.message)
      }
      if (openedShift.status === Shifts.processingStatuses.CLOSED) {
        throw new Error(this.$t('Shift already closed by other user. Try again.'))
      }
      await this.setAuthenticatedCashier({ shift: openedShift }, authenticatedCashier)
      return true
    },
    async closeShiftForCashier (authenticatedCashier, skipClientNameCheck) {
      // await authenticatedCashier.maybeStartSignAgent()
      // const resolveCondition = ({ response }) => (response.data.status === Shifts.processingStatuses.CLOSED)
      // const closingShift = await this.closeShift(authenticatedCashier, skipClientNameCheck)
      // const intervalRequest = new IntervalRequest(() => this.getShift(closingShift.response.data.id, authenticatedCashier.accessToken))
      try {
        // const closedShift = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data', null)
        const closedShift = await this.closeShift(authenticatedCashier, skipClientNameCheck)
        // if (closedShift.closing_transaction.status === 'ERROR' && closedShift.closing_transaction.response_error_message) {
        //   throw new Error(closedShift.closing_transaction.response_error_message)
        // }
        await this.setAuthenticatedCashier({ shift: null }, authenticatedCashier)
        return closedShift
      } catch (e) {
        throw new Error(this.$t('Error when close shift:') + ' ' + e.message)
      }
    },

    // low level functions
    getExistedShift (authenticatedCashier) {
      return CashRegisters.api().getShift(this._.get(authenticatedCashier, 'cashRegister.id'))
    },
    getShift (shiftId, token) {
      return Shifts.api().processingRead(shiftId, token)
    },
    createShift (authenticatedCashier) {
      return Shifts.api().processingCreateCurrent(authenticatedCashier)
    },
    closeShift (authenticatedCashier, skipClientNameCheck) {
      return CashRegisters.api().closeShift(this._.get(authenticatedCashier, 'cashRegister.id'), {
        cashier_id: this._.get(authenticatedCashier, 'cashier.id')
      })
    }

  }

}

export default processingShifts
