<template lang="pug">
  v-btn(
    class="e-language-switcher"
    @click="changeLocale"
    text
  ) {{ icon }}
</template>

<script>
export default {
  name: 'ELanguageSwitcher',
  computed: {
    currentLocale () {
      return this._.get(this.$i18n, 'locale', 'ru')
    },
    icon () {
      if (this.currentLocale === 'ru') {
        return 'KZ'
      }
      return 'Ру'
    }
  },
  methods: {
    changeLocale () {
      if (this.currentLocale === 'ru') {
        this.$i18n.setLocale('kz')
      } else {
        this.$i18n.setLocale('ru')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.e-language-switcher {
  cursor: pointer;
  text-align: center;
  line-height: 1;
  font-size: 13px !important;
  width: 32px;
  height: 32px !important;
  min-height: auto;
  border-radius: 6px;
}
</style>
