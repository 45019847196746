import _commonData from '~/modules/receipt/mixins/getters/commonData'

const authData = {
  mixins: [_commonData],
  computed: {
    token () {
      return this._.get(this.authenticatedCashier, 'accessToken', null)
    },
    cashier () {
      return this._.get(this.authenticatedCashier, 'cashier', null)
    },
    cashRegister () {
      return this._.get(this.authenticatedCashier, 'cashRegister', null)
    },
    isAuthenticated () {
      return Boolean(this.cashier && this.cashRegister)
    }
  }
}

export default authData
