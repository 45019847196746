import { Model } from '@vuex-orm/core'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import Directory from '~/models/abstracts/Directory'

export default class SmsAct extends ChainInheritance(Directory, []) {
  static entity = 'smsAct'
  static paginated = false
  static defaultSortParam = 'date'
  static ormLoadWithRelations = true

  static ormTrans = {
    single: 'Act',
    multy: 'Acts'
  }

  static fields () {
    return {
      id: this.attr(null),
      number: this.attr(0),
      amount: this.attr(0),
      billing: this.attr({}), // todo
      createdData: this.attr(null),
      date: this.attr(null)
    }
  }

  get amountString () {
    return typeof this.amount === 'number' && (this.amount / 100).toFixed(2)
  }

  get organizationName () {
    return this._.get(this.billing, 'organizationData.name')
  }

  get dateAsString () {
    return this.getDateTime(this.date)
  }

  static ormHeaders = [
    { text: 'Organization', value: 'organizationName', sortable: true, filterable: false },
    { text: 'Number', value: 'number', sortable: true, filterable: false },
    { text: 'Sum', value: 'amountString', sortable: true, filterable: false },
    { text: 'Date of act', value: 'dateAsString', sortable: true, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormActions = [
    {
      name: 'downloadPdf',
      text: 'Download PDF',
      icon: {
        type: 'e-svg-icon',
        text: 'pdf'
      },
      call: async (item) => {
        if (item && item.id) {
          try {
            const pdf = await SmsAct.api().getSmsActPdf(item.id)
            const blob = new Blob([this._.get(pdf, 'response.data', '')], { type: 'application/pdf' })
            window.open(URL.createObjectURL(blob), '_blank')
          } catch (e) {
            console.log(e)
          }
        }
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.getSmsActPdf = function (id) {
        return this.get(Model.$routes.smsAct.getPdf(id), {
          responseType: 'blob',
          save: false
        })
      }

      return configActions
    }
  }
}
