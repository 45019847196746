<template lang="pug">
  div(class="dialog")
    div(v-if="sentSuccess")
      e-svg-icon(
        size="xbg"
        class="d-block mx-auto mt-4"
      ) done-all
      p(class="dialog__text") {{ $t('Thanks for helping us get better! We will definitely consider your offer') }}
      p() {{ $t('Follow the announcements, perhaps your offer will be added to the updated one') }}
    div(v-else)
      h3(
        class="dialog__title"
      ) {{ $t('Suggestions') }}
      div()
        p {{ $t('If you have comments or suggestions for the work of the portal, you can leave your comment') }}
        ValidationObserver(ref="form")
          t-orm-fields(
            v-bind.sync="form"
            :items="fields"
          )
    div(class="dialog__actions")
      v-btn(
        v-if="sentSuccess"
        @click="closeModal"
        :loading="loading"
        class="main-button d-block mx-auto"
      ) {{ $t('Close') }}
      v-btn(
        @click="send"
        :loading="loading"
        class="main-button d-block mx-auto"
        v-else
      ) {{ $t('Left') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import Reviews from '~/modules/reviews/models/Reviews'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  name: 'BlockReviewForm',
  components: {
    TOrmFields,
    ESvgIcon
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    form: {
      message: ''
    },
    loading: false,
    sentSuccess: false
  }),
  computed: {
    model () {
      return Reviews
    },
    fields () {
      return [
        {
          component: 'v-textarea',
          model: 'message',
          provider: {
            vid: 'message',
            name: 'Comment',
            rules: 'required'
          },
          attrs: {
            label: 'Comment',
            outlined: true,
            'auto-grow': true
          }
        }
      ]
    }
  },
  methods: {
    async send () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        const payload = {
          organization: `organizations/${this._.get(this.$Organization, 'id')}`,
          owner: `users/${this._.get(this.$User, 'id')}`,
          link: location.href,
          message: this._.get(this.form, 'message', '')
        }
        await this.model.api().create(payload)
        this.sentSuccess = true
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__text {
    font-size: 17px;
    margin: 15px 0;
  }
}
</style>
