<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners" :max-width="cardWidth")
    template(#title) {{ title }}
    template(#content)
      m-form-employee(:context="context" :selected-item="editedItem" is-dialog-part @dialog:close="close")
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import fill from '~/mixins/modules/dialogs/fill'
import MFormEmployee from '~/components/modules/forms/concrete/m-form-employee'
import apm from '~/mixins/apm/apm'

// TODO need to create one interface for all concrete dialogs
export default {
  components: {
    MFormEmployee,
    TDialog
  },
  mixins: [checkPropCtx, fill, apm],
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    itemLoading: false,
    editedItem: {}
  }),
  computed: {
    dialogCtx () {
      return Object.assign({}, {
        parent: this.selectedItem,
        dialogRefs: this.$refs,
        model: this.model.entity
      }, { ...this.ctx })
    },
    dialogConfig () {
      return {
        ...this.model.getOrmDialogsConfig(this.type),
        ctx: this.dialogCtx
      }
    },
    context () {
      return this._.get(this.dialogConfig.config, 'context', this.parentContext || this.$config.contexts.create)
    },
    cardWidth () {
      return [600, 'px'].join('')
    },
    isGridded () {
      return this.dialogConfig && ('grid' in this.dialogConfig)
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.clear()
            vm.$emit('input', event)
          }
        }
      )
    },
    isLoading () {
      return this.loading || this.itemLoading
    },
    title () {
      // TODO: create one logic for pattern generation.
      let title, name

      if (this.dialogConfig.title) {
        title = { ...this.dialogConfig.title.split('|') }
      }

      // TODO: move to class config???
      if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
        name = this.dialogConfig.config.modalName
      } else {
        switch (this.context) {
          case this.$config.contexts.create:
            name = 'Add'
            break
          case this.$config.contexts.read:
            name = 'Card of'
            break
          case this.$config.contexts.update:
            name = 'Editing of'
            break
        }
      }

      const modalType = (() => {
        if (title) {
          return this.$tc(title[0], title[1])
        } else if (this.dialogConfig.config && this.dialogConfig.config.ucFirst) {
          return this._.upperFirst(this.$tc(this.model.ormTrans.single, 2))
        } else if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
          return this.$tc(this.model.ormTrans.single, 1)
        } else {
          return this.$tc(this.model.ormTrans.single, 2)
        }
      })()

      return [
        this.$t(name),
        modalType,
        (this.dialogConfig.config && this.dialogConfig.config.ucFirst) ? this.selectedItem.name : ''
      ].join(' ').trim(' ')
    }
  },
  created () {
    this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
  },
  methods: {
    clear () {
      this.apmRum({
        transactionName: 'Creating employee process',
        transactionLabels: {
          message: 'User closed dialog without creating'
        },
        endTransaction: true
      })
      setTimeout(() => {
        this.parentContext = this.$config.contexts.create
        this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
      }, 300)
    },

    close () {
      this.$emit('input', false)
      this.clear()
    },

    filterOrmObject (payload) {
      const obj = Object.assign({}, payload)
      const keys = Object.keys(obj)

      const localFields = this.model.getOrmFields({ only: 'local' })
      const notNullFields = this.model.getOrmFields({ only: 'notNull', nested: true })

      for (const item of keys) {
        if (obj[item] === undefined) {
          obj[item] = null
        }

        // Removes null fields only for create context.
        if (this._.isNull(obj[item]) && this.$isCreateCtx(this.context)) {
          delete obj[item]
        }

        // Removes all local fields.
        if (localFields.length && localFields.includes(item)) {
          delete obj[item]
        }

        // Removes all not not nullable fields.
        if (notNullFields.length && notNullFields.includes(item) && this._.isNull(obj[item])) {
          delete obj[item]
        }
      }

      return obj
    },

    afterFill (context, item) {
      this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ from: item, ctx: this.context }))
    }
  }
}
</script>

<style lang="scss">
//
</style>
