import { Model } from '@vuex-orm/core'
import Employees from '~/modules/employees/models/Employees'
import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import FilterAble from '~/models/mixins/FilterAble'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import Dialog from '~/models/system/Dialog'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import Outlets from '~/models/directories/Outlets'
import OutletsSearch from '~/models/directories/search/OutletsSearch'
import CashRegistersSearch from '~/modules/cashRegisters/models/CashRegistersSearch'

export class Shifts extends ChainInheritance(Directory, [
  FilterAble,
  PersistAble,
  TimestampAble,
  AssignAble]) {
  static entity = 'shifts'
  static paginated = true
  static ormLoadWithRelations = true
  static multiSort = true
  static defaultSortParam = ['status', 'openedAtString']
  static defaultSortOrder = [true, true]
  static persistOptions = {
    insertOrUpdate: [Employees.entity, CashRegisters.entity]
  }

  static processingStatuses = {
    CREATED: 'CREATED',
    OPENING: 'OPENING',
    OPENED: 'OPENED',
    CLOSING: 'CLOSING',
    CLOSED: 'CLOSED'
  }

  static statusesValues = {
    OPENED: true,
    CLOSED: true,
    OPENING: true,
    CLOSING: true
  }

  static statusesColors = {
    OPENED: 'success',
    CLOSED: 'grey',
    OPENING: 'yellow',
    CLOSING: 'grey'
  }

  static statusesTooltips = {
    OPENED: '{value}',
    CLOSED: '{value}',
    OPENING: '{value}...',
    CLOSING: '{value}...'
  }

  get openedAtString () {
    return this.getDateTimeProcessing(this.openedAt)
  }

  get closedAtString () {
    return this.getDateTimeProcessing(this.closedAt)
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.processingCreateCurrent = function (authenticatedCashier) {
        return this.post(Model.$routes.shifts.processingCurrent(), { cashRegister: authenticatedCashier.cashRegister.id }, { headers: { 'X-Processing-Authorization': authenticatedCashier.accessToken }, save: false })
      }
      configActions.processingCloseCurrent = function (token, skipClientNameCheck) {
        if (skipClientNameCheck) {
          return this.put(Model.$routes.shifts.processingCloseCurrent(), { skip_client_name_check: true }, { headers: { 'X-Processing-Authorization': token }, save: false })
        } else {
          return this.put(Model.$routes.shifts.processingCloseCurrent(), {}, { headers: { 'X-Processing-Authorization': token }, save: false })
        }
      }
      configActions.processingCloseById = function (token, cashRegister, id, skipClientNameCheck) {
        if (skipClientNameCheck) {
          return this.post(Model.$routes.shifts.processingCloseById(id), { cashRegister, skip_client_name_check: true }, { headers: { 'X-Processing-Authorization': token }, save: false })
        } else {
          return this.post(Model.$routes.shifts.processingCloseById(id), { cashRegister }, { headers: { 'X-Processing-Authorization': token }, save: false })
        }
      }
      configActions.processingReadCurrent = function (authenticatedCashier) {
        return this.get(Model.$routes.shifts.processingCurrent(), { headers: { 'X-Processing-Authorization': authenticatedCashier.accessToken }, save: false })
      }
      configActions.processingRead = function (shiftId, token) {
        return this.get(Model.$routes.shifts.processingGetShift(shiftId), { headers: { 'X-Processing-Authorization': token }, save: false })
      }
      // workaround for backward capabilities
      configActions.processingReadText = function (id) {
        return this.get(Model.$routes.shifts.readZReport(id), {
          save: false
        })
      }
      configActions.readText = function (id) {
        return this.get(Model.$routes.shifts.readZReport(id), {
          save: false
        })
      }
      return configActions
    }
  }

  static fields () {
    return {
      employee_id: this.attr(null),
      cash_register_id: this.attr(null),

      id: this.attr(null),
      openedAt: this.attr(null),
      closedAt: this.attr(null),
      serial: this.attr(null),
      status: this.attr(null),
      receiptCount: this.attr(null),
      initialTransaction: this.attr(null),
      closingTransaction: this.attr(null),
      cashier: this.belongsTo(Employees, 'employee_id'),
      cashRegister: this.belongsTo(CashRegisters, 'cash_register_id')
      // outlet: this.attr(null)
    }
  }

  static ormRelationMap = [
    // 'group',
    'employee',
    'cashRegister'
  ]

  static ormTrans = {
    single: 'Shift',
    multy: 'Shifts',
    notificationSingle: 'Shift ',
    pageTitle: 'Work shifts'
  }

  get outlet () {
    return this._.get(this.cashRegister, 'outlet', null)
  }

  get outletColumn () {
    if (this.cashRegister && this.cashRegister.isTest) {
      return 'Тестова зміна'
    } else {
      return this._.get(this.cashRegister, 'outlet.name', null)
    }
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', sortable: true, filterable: true },
    { text: 'outlet', value: 'outletColumn', sortable: true, filterable: true, sortQuery: 'cashRegister.outlet.name' },
    { text: 'Address', value: 'outlet.address', sortable: false, filterable: true },
    { text: 'Cashier', value: 'cashier.name', sortable: true, filterable: true, sortQuery: 'cashier.name' },
    { text: 'Shift number', value: 'serial', sortable: true, filterable: true },
    { text: 'Cash register ', value: 'cashRegister.fiscalNumber', sortable: true, filterable: true },
    { text: 'Opening', value: 'openedAtString', sortable: true, sortQuery: 'openedAt' },
    { text: 'Closing', value: 'closedAtString', sortable: true, sortQuery: 'closedAt' },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormColsComponents = {
    status: {
      component: 'e-radio-status',
      attrs: {
        valueMap: this.statusesValues,
        colorMap: this.statusesColors,
        tooltipsMap: this.statusesTooltips
      }
    }
  }

  static ormRowsConfig = {
    disabled: scopedItem => 'status' in scopedItem.item ? scopedItem.item.status === 'CLOSED' : false
  }

  static ormFilters = [
    {
      model: 'openedAt',
      type: 'dateRange',
      component: 'e-input-datetime-range',
      attrs: {
        type: 'date',
        range: true,
        'hide-details': true,
        outlined: true,
        placeholder: 'Opening date'
      }
    },
    new AutocompleteBuilder({
      model: 'cashRegister',
      label: 'Cash register ',
      emmitEmptyObject: true,
      autoWidth: true,
      emitSearch: false,
      itemClass: 'ws-pre',
      query: model => model.api().filter({ 'mode[in]': `${CashRegisters.itemModes.checkbox.mode},${CashRegisters.itemModes.deactivated.mode},${CashRegisters.itemModes.alert.mode},${CashRegisters.itemModes.deactivatedByUser.mode}` })
    }, CashRegistersSearch).build(),
    new AutocompleteBuilder({
      model: 'cashRegister.outlet',
      label: 'Outlet  ',
      emmitEmptyObject: true,
      autoWidth: true,
      emitSearch: false,
      query: model => model.api().filter({ mode: Outlets.itemModes.checkbox.mode })
    }, OutletsSearch).build(),
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'start'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 3,
                md: 3,
                sm: 6
              },
              fields: [
                'openedAt'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 3,
                md: 3,
                sm: 6
              },
              fields: [
                'cashRegister'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 3,
                md: 3,
                sm: 6
              },
              fields: [
                'cashRegister.outlet'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 3,
                md: 3,
                sm: 6
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = [
    //
  ]

  static ormActions = [
    {
      name: 'closeShift',
      text: 'Close shift',
      visible: item => item.status === Shifts.processingStatuses.OPENED,
      loading: true,
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        const zReport = await dialog.open({
          component: 'm-block-shift-close',
          width: '500px',
          componentProps: {
            shift: item,
            type: 'current_cashier'
          }
        })
        if (zReport) {
          await dialog.open({
            title: 'Z-report',
            component: 'block-report-z-view',
            width: '460px',
            componentProps: {
              preview: zReport
            }
          })
        }
      }
    },
    {
      name: 'closeShiftByHead',
      text: 'Close shift by head cashier',
      visible: item => item.status === Shifts.processingStatuses.OPENED,
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        const zReport = await dialog.open({
          component: 'm-block-shift-close',
          width: '600px',
          componentProps: {
            shift: item,
            type: 'head_cashier'
          }
        })
        if (zReport) {
          await dialog.open({
            title: 'Z-report',
            component: 'block-report-z-view',
            width: '460px',
            componentProps: {
              preview: zReport
            }
          })
        }
      }
    },
    {
      name: 'openReceiptView',
      text: 'View z-report',
      isPermitted: (item) => {
        return (item.status === Shifts.processingStatuses.CLOSED)
      }
    }
  ];

  static ormDialogs = {
    openReceiptView: 'm-orm-receipt-view-dialog'
  };

  static ormDialogsConfig = {
    openReceiptView: {
      config: {
        disableQr: true,
        width: '460px',
        view: 'text',
        download: 'text',
        downloadPrefix: 'z-report',
        type: 'z-report'
      }
    }
  }
}

export default Shifts
