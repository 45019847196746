import moment from 'moment-timezone'
import { formats } from '~/const/global'

export default superclass => class extends superclass {
  getDate (date) {
    return this.$moment(date).format(formats.date)
  }

  getTime (time) {
    return this.$moment(time).format(formats.time)
  }

  getDateTime (datetime) {
    if (!datetime) { return null }
    return this.$moment(datetime).format(formats.dateTime)
  }

  getDateTimeProcessing (datetime) {
    if (!datetime) { return null }
    // TODO Временное решение (таймзона)
    const date = moment(datetime).toDate()
    // const winterTime = new Date('2022-10-30T03:59:59+02:00')
    // const offset = date.getTime() > winterTime.getTime() ? moment().tz('Europe/Kiev').utcOffset() : 180
    // date.setHours(date.getHours() + offset / 60)
    return this.$moment(date).format(formats.dateTime)
  }
}
