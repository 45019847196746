import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _448a1202 = () => interopDefault(import('../pages/forbidden.vue' /* webpackChunkName: "pages/forbidden" */))
const _4bb7511c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _43fe2188 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _23d2ff46 = () => interopDefault(import('../pages/auth/registration.vue' /* webpackChunkName: "pages/auth/registration" */))
const _7bf145e8 = () => interopDefault(import('../pages/dashboard/CashRegisters.vue' /* webpackChunkName: "pages/dashboard/CashRegisters" */))
const _39588b88 = () => interopDefault(import('../pages/dashboard/CreateReceipts.vue' /* webpackChunkName: "pages/dashboard/CreateReceipts" */))
const _54b2deb0 = () => interopDefault(import('../pages/dashboard/Employees.vue' /* webpackChunkName: "pages/dashboard/Employees" */))
const _6cdab781 = () => interopDefault(import('../pages/dashboard/Goods.vue' /* webpackChunkName: "pages/dashboard/Goods" */))
const _1eff9638 = () => interopDefault(import('../pages/dashboard/MyOrganization.vue' /* webpackChunkName: "pages/dashboard/MyOrganization" */))
const _77d85913 = () => interopDefault(import('../pages/dashboard/Notifications.vue' /* webpackChunkName: "pages/dashboard/Notifications" */))
const _b8761a50 = () => interopDefault(import('../pages/dashboard/Ofd.vue' /* webpackChunkName: "pages/dashboard/Ofd" */))
const _ba6d3c6a = () => interopDefault(import('../pages/dashboard/Organizations.vue' /* webpackChunkName: "pages/dashboard/Organizations" */))
const _df05c680 = () => interopDefault(import('../pages/dashboard/OrganizationUsers.vue' /* webpackChunkName: "pages/dashboard/OrganizationUsers" */))
const _faf1e158 = () => interopDefault(import('../pages/dashboard/PageInDevelop.vue' /* webpackChunkName: "pages/dashboard/PageInDevelop" */))
const _7b0aa171 = () => interopDefault(import('../pages/dashboard/Payment.vue' /* webpackChunkName: "pages/dashboard/Payment" */))
const _2c15a45c = () => interopDefault(import('../pages/dashboard/Payments.vue' /* webpackChunkName: "pages/dashboard/Payments" */))
const _946d8640 = () => interopDefault(import('../pages/dashboard/Receipts.vue' /* webpackChunkName: "pages/dashboard/Receipts" */))
const _20cafbcb = () => interopDefault(import('../pages/dashboard/RefundReceipt.vue' /* webpackChunkName: "pages/dashboard/RefundReceipt" */))
const _6d9786ac = () => interopDefault(import('../pages/dashboard/Reports.vue' /* webpackChunkName: "pages/dashboard/Reports" */))
const _28f55106 = () => interopDefault(import('../pages/dashboard/Reviews.vue' /* webpackChunkName: "pages/dashboard/Reviews" */))
const _d8173fd4 = () => interopDefault(import('../pages/dashboard/Shifts.vue' /* webpackChunkName: "pages/dashboard/Shifts" */))
const _14fac866 = () => interopDefault(import('../pages/dashboard/TaxRates.vue' /* webpackChunkName: "pages/dashboard/TaxRates" */))
const _2f030533 = () => interopDefault(import('../pages/dashboard/Users.vue' /* webpackChunkName: "pages/dashboard/Users" */))
const _3cc9e476 = () => interopDefault(import('../pages/payment/error.vue' /* webpackChunkName: "pages/payment/error" */))
const _1f9f5100 = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _19d298b4 = () => interopDefault(import('../pages/dashboard/reports/EmployeesReport.vue' /* webpackChunkName: "pages/dashboard/reports/EmployeesReport" */))
const _7df30602 = () => interopDefault(import('../pages/dashboard/reports/ExtendedReport.vue' /* webpackChunkName: "pages/dashboard/reports/ExtendedReport" */))
const _73aed384 = () => interopDefault(import('../pages/dashboard/reports/ReceiptsReport.vue' /* webpackChunkName: "pages/dashboard/reports/ReceiptsReport" */))
const _5f6f08b7 = () => interopDefault(import('../pages/dashboard/reports/SectionsReport.vue' /* webpackChunkName: "pages/dashboard/reports/SectionsReport" */))
const _23f84489 = () => interopDefault(import('../pages/auth/set-password/_token/index.vue' /* webpackChunkName: "pages/auth/set-password/_token/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard"
  }, {
    path: "/forbidden",
    component: _448a1202,
    name: "forbidden"
  }, {
    path: "/profile",
    component: _4bb7511c,
    name: "profile"
  }, {
    path: "/auth/forgot-password",
    component: _43fe2188,
    name: "auth-forgot-password"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login"
  }, {
    path: "/auth/registration",
    component: _23d2ff46,
    name: "auth-registration"
  }, {
    path: "/dashboard/CashRegisters",
    component: _7bf145e8,
    name: "dashboard-CashRegisters"
  }, {
    path: "/dashboard/CreateReceipts",
    component: _39588b88,
    name: "dashboard-CreateReceipts"
  }, {
    path: "/dashboard/Employees",
    component: _54b2deb0,
    name: "dashboard-Employees"
  }, {
    path: "/dashboard/Goods",
    component: _6cdab781,
    name: "dashboard-Goods"
  }, {
    path: "/dashboard/MyOrganization",
    component: _1eff9638,
    name: "dashboard-MyOrganization"
  }, {
    path: "/dashboard/Notifications",
    component: _77d85913,
    name: "dashboard-Notifications"
  }, {
    path: "/dashboard/Ofd",
    component: _b8761a50,
    name: "dashboard-Ofd"
  }, {
    path: "/dashboard/Organizations",
    component: _ba6d3c6a,
    name: "dashboard-Organizations"
  }, {
    path: "/dashboard/OrganizationUsers",
    component: _df05c680,
    name: "dashboard-OrganizationUsers"
  }, {
    path: "/dashboard/PageInDevelop",
    component: _faf1e158,
    name: "dashboard-PageInDevelop"
  }, {
    path: "/dashboard/Payment",
    component: _7b0aa171,
    name: "dashboard-Payment"
  }, {
    path: "/dashboard/Payments",
    component: _2c15a45c,
    name: "dashboard-Payments"
  }, {
    path: "/dashboard/Receipts",
    component: _946d8640,
    name: "dashboard-Receipts"
  }, {
    path: "/dashboard/RefundReceipt",
    component: _20cafbcb,
    name: "dashboard-RefundReceipt"
  }, {
    path: "/dashboard/Reports",
    component: _6d9786ac,
    name: "dashboard-Reports"
  }, {
    path: "/dashboard/Reviews",
    component: _28f55106,
    name: "dashboard-Reviews"
  }, {
    path: "/dashboard/Shifts",
    component: _d8173fd4,
    name: "dashboard-Shifts"
  }, {
    path: "/dashboard/TaxRates",
    component: _14fac866,
    name: "dashboard-TaxRates"
  }, {
    path: "/dashboard/Users",
    component: _2f030533,
    name: "dashboard-Users"
  }, {
    path: "/payment/error",
    component: _3cc9e476,
    name: "payment-error"
  }, {
    path: "/payment/success",
    component: _1f9f5100,
    name: "payment-success"
  }, {
    path: "/dashboard/reports/EmployeesReport",
    component: _19d298b4,
    name: "dashboard-reports-EmployeesReport"
  }, {
    path: "/dashboard/reports/ExtendedReport",
    component: _7df30602,
    name: "dashboard-reports-ExtendedReport"
  }, {
    path: "/dashboard/reports/ReceiptsReport",
    component: _73aed384,
    name: "dashboard-reports-ReceiptsReport"
  }, {
    path: "/dashboard/reports/SectionsReport",
    component: _5f6f08b7,
    name: "dashboard-reports-SectionsReport"
  }, {
    path: "/auth/set-password/:token?",
    component: _23f84489,
    name: "auth-set-password-token"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
