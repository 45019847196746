import { Model } from '@vuex-orm/core'
import Tags from '~/models/directories/Tags'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import Organization from '~/models/directories/Organization'
import authUser from '~/modules/auth/authUser'
import Dialog from '~/models/system/Dialog'

export default class Billings extends ChainInheritance(Directory, []) {
  static entity = 'billings'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'status'

  static ormTrans = {
    single: 'Billing',
    multy: 'Billings'
  }

  static fields () {
    return {
      orgId: this.attr(null),

      id: this.attr(null),
      discount: this.attr(null),
      organization: this.belongsTo(Organization, 'orgId'),
      units: this.attr(null),
      verifCode: this.attr(null),
      oldVerifCode: this.attr(null),
      status: this.attr(null),
      autoPayment: this.attr(null),
      vip: this.attr(null),
      cashRegPrice: this.attr(null),
      overflowLimit: this.attr(null),
      newPrice: this.attr(null),
      considerReceiptsCount: this.attr(null),
      considerNewCashRegisters: this.attr(null),
      cardToken: this.attr({}),
      includeAllCashRegistersInAct: this.attr(null),
      hideBilling: this.attr(false)
    }
  }

  get statusString () {
    return this._.get(Organization, `billingStatuses[${this.status}].text`, '—')
  }

  get autoPaymentString () {
    return this.autoPayment ? '+' : '-'
  }

  get vipString () {
    return this.vip ? '+' : '-'
  }

  get considerReceiptsCountString () {
    return this.considerReceiptsCount ? '+' : '-'
  }

  get considerNewCashRegistersString () {
    return this.considerNewCashRegisters ? '+' : '-'
  }

  get discountString () {
    return this.discount + '%'
  }

  get cashRegPriceString () {
    return typeof this.cashRegPrice === 'number' && (this.cashRegPrice / 100).toFixed(2)
  }

  get newPriceString () {
    return typeof this.newPrice === 'number' && (this.newPrice / 100).toFixed(2)
  }

  get verifCodeString () {
    return this.verifCode ? '#' + this.verifCode : ''
  }

  get oldVerifCodeString () {
    return this.oldVerifCode ? '#' + this.oldVerifCode : ''
  }

  static ormHeaders = [
    { text: 'Organization ', value: 'organization.name', sortable: true, filterable: false },
    { text: 'Status', value: 'statusString', sortable: true, filterable: false },
    { text: 'Cash register price', value: 'cashRegPriceString', sortable: true, filterable: false },
    { text: 'New cash register price', value: 'newPriceString', sortable: true, filterable: false },
    { text: 'Discount', value: 'discountString', sortable: true, filterable: false },
    { text: 'Receipts limit', value: 'overflowLimit', sortable: true, filterable: false },
    { text: 'VIP', value: 'vipString', sortable: true, filterable: false },
    { text: 'Take shifts into invoice', value: 'considerReceiptsCountString', sortable: true, filterable: false },
    { text: 'Add new cash register to invoice', value: 'considerNewCashRegistersString', sortable: true, filterable: false },
    { text: 'Auto payment', value: 'autoPaymentString', sortable: true, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    },
    {
      model: 'organization.tags',
      component: 'v-select',
      provider: {
        name: 'Tags',
        vid: 'tags',
        rules: ''
      },
      attrs: {
        label: 'Tags',
        outlined: true,
        clearable: true,
        'return-object': true,
        'item-text': value => value.name
      },
      async request () {
        Tags.deleteAll()
        await Tags.api().all()
      },
      items: () => Tags.all()
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 6
              },
              fields: [
                'organization.tags'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 6
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = [
    {
      model: 'cashRegPrice',
      component: 'v-text-field',
      provider: {
        vid: 'cashRegPrice',
        name: 'Cash register price',
        rules: 'required'
      },
      attrs: {
        label: 'Cash register price',
        outlined: true,
        type: 'number'
      },
      fieldVal: ctx => this._.get(ctx.attrs, ctx.field.model, 0) / 100,
      cast: val => Math.round(parseFloat(val) * 100)
    },
    {
      model: 'newPrice',
      component: 'v-text-field',
      provider: {
        vid: 'newPrice',
        name: 'New cash register price',
        rules: 'required'
      },
      attrs: {
        label: 'New cash register price',
        outlined: true,
        type: 'number'
      },
      fieldVal: ctx => this._.get(ctx.attrs, ctx.field.model, 0) / 100,
      cast: val => Math.round(parseFloat(val) * 100)
    },
    {
      model: 'discount',
      component: 'v-text-field',
      provider: {
        vid: 'discount',
        name: 'Discount',
        rules: 'required|numeric|max_value:100|min_value:0'
      },
      attrs: {
        label: 'Discount',
        outlined: true,
        type: 'number'
      },
      cast: val => val ? parseInt(val) : 0
    },
    {
      model: 'overflowLimit',
      component: 'v-text-field',
      provider: {
        vid: 'overflowLimit',
        name: 'Receipts limit',
        rules: 'required|numeric'
      },
      attrs: {
        label: 'Receipts limit',
        outlined: true,
        type: 'number'
      },
      cast: val => val ? parseInt(val) : 0
    },
    {
      model: 'vip',
      component: 'v-checkbox',
      provider: {
        vid: 'vip',
        name: 'VIP'
      },
      attrs: {
        label: 'VIP'
      }
    },
    {
      model: 'considerReceiptsCount',
      component: 'v-checkbox',
      provider: {
        vid: 'considerReceiptsCount',
        name: 'Take shifts into invoice'
      },
      attrs: {
        label: 'Take shifts into invoice'
      }
    },
    {
      model: 'considerNewCashRegisters',
      component: 'v-checkbox',
      provider: {
        vid: 'considerNewCashRegisters',
        name: 'Add new cash register to invoice'
      },
      attrs: {
        label: 'Add new cash register to invoice'
      }
    },
    {
      model: 'includeAllCashRegistersInAct',
      component: 'v-checkbox',
      provider: {
        vid: 'includeAllCashRegistersInAct',
        name: 'Include all cash registers in act'
      },
      attrs: {
        label: 'Include all cash registers in act'
      }
    }
  ]

  static ormActions = [
    {
      name: 'read'
    },
    {
      name: 'edit',
      visible: () => {
        return authUser.get() && authUser.get().isBillingManager
      }
    },
    {
      name: 'set_billing_date',
      text: 'Змінити дату білінгу',
      visible: () => {
        return authUser.get() && authUser.get().isBillingManager
      },
      call: async (item) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        await contentDialog.open({
          title: 'Встановити дату білінгу',
          width: '600px',
          component: 'm-form-billing-date-set',
          componentProps: {
            item
          }
        })
      }
    },
    {
      name: 'set_payment_settings',
      text: 'Встановити налаштування оплат',
      visible: () => {
        return authUser.get() && authUser.get().isBillingManager
      },
      call: async (item) => {
        await Organization.api().read(item.organization.id)
        const organization = Organization.query().whereId(item.organization.id).first()
        const contentDialog = Dialog.query().where('type', 'content').first()
        await contentDialog.open({
          title: 'Payment settings',
          width: '600px',
          component: 'm-form-payment-settings',
          componentProps: {
            item: organization
          }
        })
      }
    }
  ]

  static ormDialogs = {
    read: 'm-orm-view-dialog',
    edit: 'm-orm-crud-dialog'
  }

  static ormDialogsConfig = {
    read: {
      title: item => ({ name: item.name }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'organization.name',
            label: 'Organization '
          },
          {
            model: 'statusString',
            label: 'Status'
          },
          {
            model: 'cashRegPriceString',
            label: 'Cash register price'
          },
          {
            model: 'newPriceString',
            label: 'New cash register price'
          },
          {
            model: 'discountString',
            label: 'Discount'
          },
          {
            model: 'overflowLimit',
            label: 'Receipts limit'
          },
          {
            model: 'vipString',
            label: 'VIP'
          },
          {
            model: 'considerReceiptsCountString',
            label: 'Take shifts into invoice'
          },
          {
            model: 'verifCodeString',
            label: 'Payment code'
          },
          {
            model: 'oldVerifCodeString',
            label: 'Old payment code'
          }
        ]
      }
    },
    edit: {
      config: {
        context: 'update'
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.unsetAutoPayment = function (id) {
        return this.put(Model.$routes.billings.unsetAutoPayment(id), { dataKey: null })
      }
      configActions.getReconciliationAct = function (id, params) {
        return this.get(Model.$routes.billings.reconciliationAct(id), {
          responseType: 'blob',
          save: false,
          params
        })
      }
      configActions.getReconciliationActOnAllOrganizations = function (params) {
        return this.get(Model.$routes.billings.reconciliationActOnAllOrganizations(), {
          responseType: 'blob',
          save: false,
          params
        })
      }
      configActions.organizationBilling = function (id) {
        Billings.deleteAll()
        return this.get(Model.$routes.billings.organizationBilling(id))
      }
      configActions.ccActive = function (id) {
        return this.get(Model.$routes.billings.ccActive(id))
      }
      configActions.activationCount = function (id) {
        return this.get(Model.$routes.billings.activationCount(id))
      }
      configActions.generateInvoice = function (id) {
        return this.get(Model.$routes.billings.generateInvoice(id))
      }

      configActions.changeBillingDate = function (id, payload) {
        return this.put(Model.$routes.billings.changeBillingDate(id), payload)
      }
      return configActions
    }
  }
}
