import auth from '~/modules/auth/auth'

export default function ({ store, redirect, error }) {
  if (!auth.isAuthenticated()) {
    const route = store?.$router?.history?.current?.path
    return redirect({
      path: '/auth/login',
      query: {
        from: route
      }
    })
  }
}
