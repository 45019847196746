<template lang="pug">
  ValidationObserver(:ref="model.entity" slim)
    v-row()
      v-col(cols="12" class="mt-1 mb-1")
        t-orm-fields(
          v-bind.sync="productItem"
          :items="fields"
          :tabs="model.ormTabs"
          :context="context"
        )
    v-row(class="mb-4")
      v-col(cols="12")
        t-orm-buttons(:items="buttons" :context="context" :classes="['text-center']")
</template>

<script>
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import validate from '~/mixins/validation/validate'
import filterOrmObject from '~/mixins/orm/filterOrmObject'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import taxAuth from '~/mixins/pages/tax-auth'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import ELink from '~/components/elements/links/e-link'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import GoodsGroupsSearch from '~/modules/goods/models/GoodsGroupsSearch'
import Taxes from '~/modules/tax-rates/models/Taxes'
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'
import actions from '~/modules/goods/mixins/actions'
import scrollToFailedValidation from '~/mixins/methods/scrollToFailedValidation'

export default {
  components: { TOrmButtons, TOrmFields, ELink, TOrmLabel },
  mixins: [validate, filterOrmObject, checkPropCtx, taxAuth, actions, scrollToFailedValidation],
  props: {
    context: {
      type: String,
      default: 'create'
    },
    selectedItem: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      default: null
    },
    isDialogPart: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    model: AccountingGoods,
    productItem: {
      name: null,
      barcode: null,
      group: null,
      taxes: null,
      price: null,
      unit: null,
      sectionCode: null
    },
    itemLoading: false
  }),
  computed: {
    isUpdate () {
      return this.context === 'update'
    },
    fields () {
      return [
        {
          model: 'name',
          component: 'v-text-field',
          provider: {
            name: 'Name',
            vid: 'name',
            rules: 'required'
          },
          attrs: {
            label: 'Name',
            required: true,
            outlined: true
          }
        },
        new AutocompleteBuilder({
          model: 'group',
          label: 'Product group',
          query: model => model.api(),
          addEntityBtn: {
            text: '+ ' + this.$t('Add a new group'),
            call: async () => await this.addGroup()
          }
        }, GoodsGroupsSearch).build(),
        {
          model: 'taxes',
          component: 'v-select',
          provider: {
            name: 'Group taxes',
            vid: 'taxes'
          },
          default: [],
          attrs: {
            label: 'Group taxes',
            outlined: true,
            multiple: true,
            'return-object': true,
            'item-text': (value) => {
              const percent = (((value.percent || 0) / 1000).toFixed(2) || 0) + '%'
              return value.name + ', ' + percent + ', ' + value.taxationType
            }
          },
          async request () {
            Taxes.deleteAll()
            await Taxes.api().all()
          },
          items: () => Taxes.all()
        },
        {
          model: 'barcode',
          component: 'e-input-scanner',
          provider: {
            name: 'Barcode',
            vid: 'barcode'
          },
          attrs: {
            label: 'Barcode',
            outlined: true
          }
        },
        {
          model: 'price',
          cast: val => Math.round((parseFloat(val) || 0) * 100),
          component: 'v-text-field',
          provider: {
            name: 'Price per unit.',
            vid: 'price',
            rules: 'required|money'
          },
          attrs: {
            label: 'Price per unit.',
            outlined: true,
            type: 'number',
            min: 0,
            step: 0.01
          }
        },
        {
          model: 'unit',
          component: 'v-text-field',
          provider: {
            name: 'Unit',
            vid: 'unit',
            rules: 'required'
          },
          attrs: {
            label: 'Unit',
            required: true,
            outlined: true
          }
        },
        {
          model: 'sectionCode',
          component: 'v-text-field',
          provider: {
            name: 'Section code',
            vid: 'sectionCode',
            rules: 'required'
          },
          attrs: {
            label: 'Section code',
            required: true,
            outlined: true
          }
        }
      ]
    },
    buttons () {
      const text = this.$isCreateCtx(this.context) ? 'Add goods' : 'Save'
      return [
        {
          text,
          contexts: this.$config.contexts.only('c.u'),
          loading: this.itemLoading || this._.get(this.iitDialog, 'options.loading', false),
          attrs: {
            depressed: true,
            class: 'main-button'
          },
          call: this.save
        }
      ]
    }
  },
  created () {
    this.fillData()
  },
  methods: {
    fillData () {
      if (this.isUpdate) {
        for (const key in this.item) {
          const keys = Object.keys(this.productItem)
          if (keys.includes(key)) {
            let val = this.item[key]
            if (key === 'price') {
              val /= 100
            }
            if (key === 'taxes') {
              val = Taxes.query().whereId(val.map(i => i?.id)).get()
            }
            this.$set(this.productItem, key, val)
          }
        }
      }
    },
    close () {
      this.$emit(['dialog', 'close'].join(':'), null)
    },
    getIrisArray (arr, entity) {
      const filteredArr = this._.filter(arr, item => item && item.id)
      return this._.map(filteredArr, item => `/api/v1/${entity}/${this._.get(item, 'id')}`, [])
    },
    async save () {
      if (!await this.validate()) {
        this.scrollToFailedValidation(this.$refs[this.model.entity]?.$el)
        return false
      }
      try {
        this.itemLoading = true
        const groupId = this._.get(this.productItem, 'group.id', null)
        const data = {
          ...this.productItem,
          group: groupId ? `/api/v1/goods-groups/${groupId}` : null,
          taxes: this.getIrisArray(this.productItem.taxes, 'taxes')
        }
        const payload = this.filterOrmObject(data)

        this.$isCreateCtx(this.context)
          ? await this.model.api()[this.context](payload, false)
          : await this.model.api()[this.context](this.item, payload, false)

        if (this.$isCreateCtx(this.context)) {
          this.$notification.success(this.$t('Product added successfully'))
        } else {
          this.$notification.success(this.$t('Product edited successfully'))
        }

        this.close()
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
        this.scrollToFailedValidation(this.$refs[this.model.entity]?.$el)
      } finally {
        this.itemLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.hint {
  font-size: 0.75rem;
  line-height: 1.2;
}
.max-width {
  max-width: 27%;
}
</style>
