<template lang="pug">
  div
    template(v-for="(dialog, index) in dialogs")
      component(
        v-if="dialog.component"
        :is="dialog.component"
        v-bind="dialog.options"
        :key="index"
      )
    m-dialog-sign-agent()
    m-dialog-information()
    m-dialog-confirmation()
    m-dialog-sign-plugin-common()
    m-dialog-sign-plugin-common(type="signPluginForOrganization", plugin-id="signPluginOrganization_sign_plugin")
    m-dialog-content()
    m-dialog-content(type="content_top")
</template>

<script>
import { mapGetters } from 'vuex'
import MDialogBase from '~/components/modules/dialogs/m-dialog-base'
import MDialogInfo from '~/components/modules/dialogs/m-dialog-info'
import MDialogConfirmation from '~/components/modules/dialogs/common/m-dialog-confirmation'
import MDialogIitSignPlugin from '~/components/modules/dialogs/m-dialog-iit-sign-plugin'
import MDialogSignAgent from '~/components/modules/dialogs/m-dialog-sign-agent'
import MDialogInformation from '~/components/modules/dialogs/common/m-dialog-information'
import MDialogSignPluginCommon from '~/components/modules/dialogs/common/m-dialog-sign-plugin-common'
import MDialogContent from '~/components/modules/dialogs/common/m-dialog-content'

export default {
  components: {
    MDialogContent,
    MDialogSignPluginCommon,
    MDialogInformation,
    MDialogSignAgent,
    MDialogBase,
    MDialogInfo,
    MDialogConfirmation,
    MDialogIitSignPlugin
  },
  computed: {
    ...mapGetters({
      dialogs: 'dialogs/list'
    })
  }
}
</script>

<style scoped>

</style>
