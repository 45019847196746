<template lang="pug">
  t-dialog(
    v-bind="$attrs"
    :value="isOpened"
    v-on="$listeners"
    :max-width="width"
    :showCloseBtn="!hideCloseButton"
    @input="close"
  )
    template(#title v-if="title" )
      p(class='ma-0 pa-0' :class="titleClass") {{title}}
    template(#subtitle v-if="subtitle" )
      p(class='text-center ma-0 mt-2 pa-0') {{subtitle}}
    template(#content)
      v-row(:class="{ 'h-100': contentFullHeight }")
        v-col(cols="12" v-if="text" :class="{ 'h-100': contentFullHeight }")
          | {{text}}
        v-col(cols="12" v-if="component" :class="{ 'h-100': contentFullHeight }")
          component(:is="component" v-bind="{...componentProps, isOpened}" :closeModal="onCloseEvent" v-on="componentListeners")
    template(#actions v-if="showActions" )
      v-row(no-gutters justify="center" )
        v-col(cols="auto")
          v-btn(@click="confirm" color="primary"  class="main-button" depressed)
            | {{buttonText.approve}}
        v-col(cols="auto")
          v-btn(@click="close" class="ml-4 secondary-button color-error" outlined)
            | {{buttonText.dismiss}}
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TDialog from '~/components/templates/t-dialog'
import Dialog from '~/models/system/Dialog'

// used components
// TODO this must be rethink
import MBlockReceiptSaleDiscount from '~/modules/receipt/views/components/m-block-receipt-sale-discount'
import MBlockReceiptSaleMixedPaymentTypes from '~/modules/receipt/views/components/m-block-receipt-sale-mixed-payment-types'
import MBlockShiftClose from '~/components/modules/blocks/concrete/shift/m-block-shift-close'
import MFormDepositsignToken from '~/components/modules/forms/concrete/m-form-depositsign-token'
import MFormBillingTaxBill from '~/components/modules/forms/concrete/billing/m-form-billing-tax-bill'
import MBlockMultipleTaxBillsSending from '~/components/modules/blocks/concrete/tax-bills/m-block-multiple-tax-bills-sending'
import MFormBlock from '~/components/modules/blocks/m-form-block'
import MBlockGoodsImportResult from '~/components/modules/blocks/concrete/goods/m-block-goods-import-result'
import MOrmTable from '~/components/modules/tables/orm/m-orm-table'
import MFormEmployeeModify from '~/components/modules/forms/concrete/m-form-employee-modify'
import MFormEmployeeUploadEdsKey from '~/components/modules/forms/concrete/emoloyee/m-form-employee-upload-eds-key'
import MFormOutletModify from '~/components/modules/forms/concrete/m-form-outlet-modify'
import MFormPaymentSettings from '~/components/modules/forms/concrete/payment/m-form-payment-settings'
import MChildGoodsList from '~/components/modules/m-child-goods-list'
import MBlockReceiptSaleExciseStamp from '~/components/modules/blocks/concrete/receipt/sale/m-block-receipt-sale-excise-stamp'
import MBlockBarcodeScanner from '~/components/modules/blocks/concrete/m-block-barcode-scanner'
import MBlockGoodsScanner from '~/components/modules/blocks/concrete/goods/m-block-goods-scanner'
import MFormBillingDateSet from '~/components/modules/forms/concrete/billing/m-form-billing-date-set'
import MBlockSetOrganization from '~/components/modules/blocks/concrete/m-block-set-organization'
import MBlockWrongSuppliesCount from '~/modules/goods/views/components/m-block-wrong-supplies-count'
import MBlockSupportMessage from '~/components/modules/blocks/concrete/m-block-support-message'
import MGoodsImport from '~/modules/goods/views/components/m-goods-import'
import MGoodsExport from '~/modules/goods/views/components/m-goods-export'
import MBlockGoodsRemoval from '~/modules/goods/views/components/m-block-goods-removal'
import MBlockOrmReceiptView from '~/modules/receipt/views/components/m-block-orm-receipt-view'
import BlockProcessingEmployeeLogin from '~/modules/receipt/views/components/dialogs/block-processing-employee-login'
import BlockReceiptService from '~/modules/receipt/views/components/dialogs/block-receipt-service'
import BlockReceiptTextView from '~/modules/receipt/views/components/dialogs/block-receipt-text-view'
import BlockOrderError from '~/modules/orders/views/components/errors/block-order-error'
import MBlockAcquiringOrderView from '~/modules/acquiring/views/m-block-acquiring-order-view'
import MBlockAcquiringOrderCreateReceipt from '~/modules/acquiring/views/m-block-acquiring-order-create-receipt'
import MBlockSetAcquiringSettings from '~/components/modules/blocks/concrete/m-block-set-acquiring-settings'
import BlockAcquiringPaymentInfo from '~/modules/receipt/views/components/dialogs/block-acquiring-payment-info'
import BlockAddNotification from '~/modules/notifications/views/components/block-add-notification'
import BlockNotificationsList from '~/modules/notifications/views/components/block-notifications-list'
import BlockSingleNotification from '~/modules/notifications/views/components/block-single-notification'
import TOrmSimpleData from '~/components/templates/orm/t-orm-simple-data'
import BlockMoneyTransfer from '~/modules/payments/views/components/block-money-transfer'
import MBlockSwitchSuppliesSetting from '~/modules/goods/views/components/m-block-switch-supplies-setting'
import MFormOfficePayment from '~/components/modules/blocks/concrete/payment/m-form-office-payment'
import MFormSmsPayment from '~/components/modules/blocks/concrete/payment/m-form-sms-payment'
import MBlockChangeEmployeeEdsKey from '~/components/modules/forms/concrete/emoloyee/m-block-change-employee-eds-key'
import BlockReviewForm from '~/modules/reviews/views/components/block-review-form'
import BlockPosTerminalPaymentProcess from '~/modules/receipt/views/components/dialogs/block-pos-terminal-payment-process'
import MFormPosTerminalSettings from '~/components/modules/forms/concrete/m-form-pos-terminal-settings'
import FormCashRegister from '~/modules/cashRegisters/views/components/form-cash-register'
import FormEmployee from '~/modules/employees/views/components/form-employee'
import FormEmployeeChangePassword from '~/modules/employees/views/components/form-employee-change-password'
import FormOfd from '~/modules/ofd/views/components/form-ofd'
import BlockCredentialsChecker from '~/modules/credentialsChecker/views/components/block-credentials-checker.vue'

export default {
  components: {
    TOrmSimpleData,
    BlockAcquiringPaymentInfo,
    MBlockSetAcquiringSettings,
    MBlockAcquiringOrderCreateReceipt,
    MBlockAcquiringOrderView,
    MGoodsExport,
    MGoodsImport,
    MFormBillingDateSet,
    MFormPaymentSettings,
    MFormEmployeeUploadEdsKey,
    MBlockGoodsImportResult,
    MFormDepositsignToken,
    MFormBillingTaxBill,
    TOrmButtons,
    TDialog,
    MBlockReceiptSaleDiscount,
    MBlockReceiptSaleMixedPaymentTypes,
    QrcodeVue,
    MBlockShiftClose,
    MBlockMultipleTaxBillsSending,
    MFormBlock,
    MOrmTable,
    MFormEmployeeModify,
    MFormOutletModify,
    MChildGoodsList,
    MBlockReceiptSaleExciseStamp,
    MBlockBarcodeScanner,
    MBlockGoodsScanner,
    MBlockSetOrganization,
    MBlockWrongSuppliesCount,
    MBlockSupportMessage,
    MBlockGoodsRemoval,
    MBlockOrmReceiptView,
    BlockProcessingEmployeeLogin,
    BlockReceiptService,
    BlockReceiptTextView,
    BlockOrderError,
    BlockAddNotification,
    BlockNotificationsList,
    BlockSingleNotification,
    BlockMoneyTransfer,
    MBlockSwitchSuppliesSetting,
    MFormOfficePayment,
    MFormSmsPayment,
    MBlockChangeEmployeeEdsKey,
    BlockReviewForm,
    BlockPosTerminalPaymentProcess,
    MFormPosTerminalSettings,
    FormCashRegister,
    FormEmployee,
    FormEmployeeChangePassword,
    FormOfd,
    BlockCredentialsChecker
  },
  props: {
    type: {
      type: String,
      default: 'content'
    }
  },
  computed: {
    dialog () {
      return Dialog.query().where('type', this.type).first()
    },
    isOpened () {
      return this._.get(this.dialog, 'isOpened', false)
    },
    params () {
      return this._.get(this.dialog, 'params', '')
    },
    title () {
      const title = this._.get(this.params, 'title', '')
      return this._.isFunction(title) ? title(this) : this.$t(title)
    },
    subtitle () {
      const subtitle = this._.get(this.params, 'subtitle', '')
      return this._.isFunction(subtitle) ? subtitle(this) : this.$t(subtitle)
    },
    text () {
      const text = this._.get(this.params, 'text', '')
      return this._.isFunction(text) ? text(this) : this.$t(text)
    },
    hideCloseButton () {
      return this._.get(this.params, 'hideCloseButton', false)
    },
    showActions () {
      return this._.get(this.params, 'showActions', false)
    },
    component () {
      return this._.get(this.params, 'component')
    },
    componentProps () {
      return this._.get(this.params, 'componentProps')
    },
    componentListeners () {
      return this._.get(this.params, 'componentListeners')
    },
    contentFullHeight () {
      return this._.get(this.params, 'contentFullHeight', false)
    },
    buttonText () {
      const defaultTexts = {
        approve: this.$t('Approve'),
        dismiss: this.$t('Dismiss')
      }
      return this._.get(this.params, 'buttonText', defaultTexts)
    },
    width () {
      return this._.get(this.params, 'width', '800px')
    },
    titleClass () {
      return this._.get(this.params, 'titleClass', 'text-center')
    },
    onClose () {
      return this._.get(this.params, 'onClose')
    }
  },
  watch: {
    $route () {
      if (this.isOpened) {
        this.close()
      }
    }
  },
  created () {
    if (!this.dialog) {
      Dialog.insert({
        data: {
          type: this.type
        }
      })
    }
  },
  methods: {
    async close () {
      if (this._.isFunction(this.onClose)) {
        await this.onClose()
      }
      await this.dialog.close(false, true)
    },
    confirm () {
      this.dialog.close(true, true)
    },
    onCloseEvent (payload, confirmed = true) {
      this.dialog.close(payload, confirmed)
    }
  }
}
</script>
