<template lang="pug">
  t-dialog(
    v-bind="$attrs"
    :value="isOpened"
    v-on="$listeners"
    max-width="900px"
    :hide-overlay="!isVisible"
    :content-class="!isVisible ? 'iit-sign-dialog-hidden' : ''"
    @input="close"
  )
    template(#title) {{title}}
    template(#content)
      v-row(class="mt-3 mb-3")
        v-col(cols="12")
          v-overlay(absolute :value="loading")
            e-progress-circular
          m-iit-sign-plugin(
            @keyLoaded="onKeyLoaded"
            @iframeLoading="onIframeLoading"
            ref="plugin"
            :plugin-id="pluginId"
          )
</template>

<script>
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TDialog from '~/components/templates/t-dialog'
import MIitSignPlugin from '~/components/modules/IIT/m-iit-sign-plugin'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import Dialog from '~/models/system/Dialog'

export default {
  components: {
    EProgressCircular,
    MIitSignPlugin,
    TOrmButtons,
    TDialog
  },
  props: {
    type: {
      type: String,
      default: 'signPluginCommon'
    },
    pluginId: {
      type: String,
      default: 'signPluginCommon_sign_plugin'
    }
  },
  data: () => ({
    loading: false,
    keyLoading: null,
    keyData: null,
    isSeal: false
  }),
  computed: {
    interface () {
      return {
        keyData: this.keyData,
        isSeal: this.isSeal,
        sign: this.sign,
        envelopData: this.envelopData
      }
    },
    dialog () {
      return Dialog.query().where('type', this.type).first()
    },
    isOpened () {
      return this._.get(this.dialog, 'isOpened', false)
    },
    isVisible () {
      return this._.get(this.dialog, 'isVisible', false)
    },
    params () {
      return this._.get(this.dialog, 'params', '')
    },
    title () {
      return this._.get(this.params, 'title', this.$t('Select eds key'))
    }
  },
  created () {
    if (!this.dialog) {
      Dialog.insert({
        data: {
          type: this.type
        }
      })
    }
  },
  methods: {
    onIframeLoading (loading) {
      this.loading = loading
    },
    async onKeyLoaded (keyData) {
      if (keyData) {
        this.keyData = keyData
        this.isSeal = keyData && !this._.get(keyData[0], 'infoEx.subjDRFOCode', '')
        await this.dialog.saveData({
          interface: this.interface
        })
      }
      this.dialog.resolvePromise(keyData)
    },
    close (data) {
      this.dialog.close(data, true)
    },
    envelopData (certs, data) {
      if (this.$refs.plugin) {
        return this.$refs.plugin.envelopData(certs, data)
      } else {
        throw new Error('Sign plugin interface not loaded')
      }
    },
    async sign (data, options = {}) {
      const { fallbackMethod } = options
      if (this.$refs.plugin) {
        const signedData = await this.$refs.plugin.sign(data)
        if (signedData) {
          return signedData
        } else {
          await this.dialog.close()

          if (typeof fallbackMethod === 'function') {
            await fallbackMethod()
          }
        }
      } else {
        throw new Error('Sign plugin interface not loaded')
      }
    }
  }
}
</script>

<style lang="scss">
</style>
