import Search from '~/models/abstracts/Search'
import DateTimeAble from '~/models/mixins/DateTimeAble'
import ChainInheritance from '~/models/mixins/ChainInheritance'

export class ShiftsSearch extends ChainInheritance(Search, [DateTimeAble]) {
  static entity = 'shiftssearch'

  static fields () {
    return {
      id: this.attr(null),
      status: this.attr(null),
      serial: this.attr(null),
      opened_at: this.attr(null),
      closed_at: this.attr(null)
    }
  }

  toString () {
    return `№${this.serial} (${this.getDateTimeProcessing(this.opened_at)} – ${this.getDateTimeProcessing(this.closed_at)})`
  }
}

export default ShiftsSearch
