<template lang="pug">
  div(class="m-dialog-goods-scanner")
    div(
      v-if="noGoodsFound"
      class="text-center"
    )
      p {{ $t('No product found with this barcode') }}
      v-btn(
        class="main-button mt-3"
        @click="scanAgain"
      ) {{ $t('Scan again') }}
      e-link(
        class="m-dialog-goods-scanner__link"
        @click="addGoods"
      ) {{ $t('Add goods') }}
    m-block-barcode-scanner(
      @scan.once="onBarcodeScan"
      class="mb-6"
      v-else
    )
</template>

<script>
import MBlockBarcodeScanner from '~/components/modules/blocks/concrete/m-block-barcode-scanner'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import ELink from '~/components/elements/links/e-link'
import Taxes from '~/modules/tax-rates/models/Taxes'

export default {
  components: {
    ELink,
    MBlockBarcodeScanner
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    useLeftovers: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    noGoodsFound: false
  }),
  computed: {
    model () {
      return AccountingGoods
    }
  },
  methods: {
    addGoods () {
      this.closeModal()
      this.$router.push({
        path: '/dashboard/goods',
        hash: '#createDialogOpen'
      })
    },
    scanAgain () {
      this.noGoodsFound = false
    },
    async onBarcodeScan (barcode) {
      try {
        this.noGoodsFound = false
        if (!this._.replace(barcode, /\s/g, '')) {
          this.noGoodsFound = true
          return
        }
        const res = this._.get(await this.model.api().filter({
          search: barcode,
          load_childs: true,
          load_count: this.useLeftovers,
          'order[code]': 'desc'
        }).all({ itemsPerPage: 10, page: 1 }, { save: false }), 'response.data.data', [])
        this._.each(res, (product) => {
          product.taxes = Taxes.query().whereIdIn(product.taxes).get()
          return product
        })
        // eslint-disable-next-line new-cap
        const goods = this._.map(res, item => new this.model(item))

        if (goods.length) {
          this.closeModal(goods)
        } else {
          this.noGoodsFound = true
        }
      } catch (e) {
        this.$handlers.error(e, this)
        this.closeModal()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.m-dialog-goods-scanner {
  &__link {
    font-size: .75rem !important;
    margin: 10px auto 30px;
    display: block;
  }
}
</style>
