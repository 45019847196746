<template lang="pug">
  ValidationObserver(:ref="orderModel.entity" slim)
    t-orm-fields(
      :key="`firstPart-${key}`"
      v-bind.sync="editedItem"
      :items="fieldsFirstPart"
    )
    v-row(no-gutters align="center" v-if="showDefaultKoatuuText" class="mb-6")
      t-orm-label(:item="koatuuLabelItem")
      v-col(md="7" lg="7" xl="8" cols="12")
        div(class="mt-2") {{ koatuuText }}
        e-link(
          class="d-block mt-2"
          text-bold
          @click="showKoatuuInput = true"
        ) {{ $t('Change') }}
    t-orm-fields(
      :key="`secondPart-${key}`"
      v-bind.sync="editedItem"
      :items="fieldsSecondPart"
    )
    v-row(class="my-4")
      v-col(cols="12")
        t-orm-buttons(:items="buttons" :classes="['text-center']")
</template>

<script>
import KatottgDictionary from '~/models/directories/dictionaries/KatottgDictionary'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import SignPluginService from '~/services/SignPluginService/SignPluginService'
import Outlets from '~/models/directories/Outlets'
import OutletOrder from '~/models/directories/Orders/OutletOrder'
import TaxCodesDictionary from '~/models/directories/dictionaries/TaxCodesDictionary'
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'
import ELink from '~/components/elements/links/e-link'

export default {
  components: {
    TOrmButtons,
    TOrmFields,
    TOrmLabel,
    ELink
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    onSubmit: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    model: Outlets,
    orderModel: OutletOrder,
    editedItem: {},
    key: 0,
    loading: false,
    lastCreateTaxOrder: null,
    showKoatuuInput: false
  }),
  computed: {
    hasKoatuu () {
      return this._.get(this.lastCreateTaxOrder, 'T1RXXXXG6', false)
    },
    hasKatottg () {
      return this._.get(this.lastCreateTaxOrder, 'T1RXXXXG6S', false)
    },
    showDefaultKoatuuText () {
      return (this.hasKoatuu || this.hasKatottg) && !this.showKoatuuInput
    },
    koatuuText () {
      return `${this.item.address} (${this._.get(this.lastCreateTaxOrder, 'T1RXXXXG6', '-') || this._.get(this.lastCreateTaxOrder, 'T1RXXXXG6S', '-')})`
    },
    koatuuLabelItem () {
      return {
        label: {
          text: this.hasKoatuu ? 'Address (COATUU Code)' : 'Address (KATOTTG Code)'
        }
      }
    },
    fieldsFirstPart () {
      return [
        {
          model: 'T1RXXXXG4S',
          component: 'v-text-field',
          provider: {
            vid: 'T1RXXXXG4S',
            name: 'Outlet name',
            rules: 'required|max:50'
          },
          attrs: {
            label: 'Outlet name',
            outlined: true
          }
        },
        {
          model: 'T1RXXXXG9',
          component: 'v-select',
          provider: {
            vid: 'T1RXXXXG9',
            name: 'State object',
            rules: 'required'
          },
          attrs: {
            returnObject: false,
            label: 'State object',
            outlined: true,
            visible: !this._.get(this.item, 'stateObject')
          },
          fieldVal: () => {
            const stateObject = this._.get(this.item, 'stateObject', '')
            const stateObjectValue = this._.get(this._.filter(this.orderModel.STAN_OBJECT, item => item.text === stateObject), '[0].value', null)
            return stateObjectValue || this.orderModel.STAN_OBJECT[1].value
          },
          items: () => this.orderModel.STAN_OBJECT
        },
        {
          model: 'T1RXXXXG10',
          component: 'v-select',
          provider: {
            vid: 'T1RXXXXG10',
            name: 'Type of point of sale',
            rules: 'required'
          },
          attrs: {
            returnObject: false,
            label: 'Type of point of sale',
            outlined: true
          },
          items: () => this.orderModel.TYPE_OF_RIGHTS
        }
      ]
    },
    fieldsSecondPart () {
      return [
        {
          model: 'T1RXXXXG6S',
          component: 'e-input-tree-select',
          tooltip: 'Katottg',
          label: {
            text: 'Address (KATOTTG Code)'
          },
          cols: {
            cols: 12
          },
          classes: ['mt-3'],
          attrs: {
            visible: !this.showDefaultKoatuuText,
            outlined: true,
            requestItems: 'onlyRootLevel',
            itemText: 'name',
            model: KatottgDictionary,
            providers: [
              {
                vid: 'katottgRegion',
                name: 'Choose region',
                rules: 'required'
              },
              {
                vid: 'katottgDistrict',
                name: 'Choose district',
                rules: 'required'
              },
              {
                vid: 'katottgTerritory',
                name: 'Choose territory',
                rules: 'required'
              },
              {
                vid: 'katottgSettlement',
                name: 'Choose settlement',
                rules: 'required'
              },
              {
                vid: 'katottgSettlementDistrict',
                name: 'Choose settlement district'
              }
            ],
            labelsConfig: {
              labelPattern: '{labelPrefix} {label}',
              labelPrefix: 'Choose',
              labels: ['Region', 'District', 'Local community', 'Settlement', 'Settlement district']
            }
          }
        },
        {
          model: 'T1RXXXXG8S',
          component: 'v-text-field',
          provider: {
            vid: 'T1RXXXXG8S',
            name: 'Street and number',
            rules: 'required|max:100'
          },
          attrs: {
            label: 'Street and number',
            outlined: true
          }
        },
        {
          model: 'T1RXXXXG11',
          component: 'v-checkbox',
          attrs: {
            label: 'Choose setting for outlet creating',
            outlined: true,
            trueValue: '1',
            falseValue: '0'
          }
        },
        {
          model: 'HSTI',
          label: {
            text: 'State Tax Service',
            subText: 'Choose the one that serves the area where the outlet is located'
          },
          component: 'e-dialog-input',
          tooltip: 'Tax code',
          provider: {
            vid: 'HSTI',
            name: 'State Tax Service',
            rules: 'required'
          },
          classes: [this._.get(this.editedItem, 'HSTI', null) ? 'mb-2' : 'mb-8'],
          attrs: {
            valuePattern: '{nameSti}',
            requestItems: 'onlyRootLevel',
            modalAttrs: {
              component: 'm-orm-dialog-tree-select',
              title: 'Select dpi',
              model: TaxCodesDictionary
            }
          }
        },
        {
          model: 'T1RXXXXG2',
          component: 'v-checkbox',
          provider: {
            vid: 'T1RXXXXG2',
            name: this.isActiveEntity ? 'Deactivate' : 'Activate',
            rules: 'required'
          },
          attrs: {
            label: this.isActiveEntity ? 'Deactivate' : 'Activate',
            outlined: true,
            trueValue: this.isActiveEntity ? this.orderModel.orderStatusType.deactivate.value : this.orderModel.orderStatusType.modify.value,
            falseValue: this.isActiveEntity ? this.orderModel.orderStatusType.modify.value : this.orderModel.orderStatusType.deactivate.value
          },
          hint: this.$t('Select if you want to change the entity in the sfs', {
            entity: this.$t('Outlet '),
            action: this.isActiveEntity ? this._.lowerCase(this.$t('Deactivate')) : this._.lowerCase(this.$t('Activate'))
          })
        }
      ]
    },
    isActiveEntity () {
      return this._.get(this.item, 'mode') === this.model.itemModes.checkbox.mode
    },
    buttons () {
      return [
        {
          text: 'Save',
          attrs: {
            class: 'main-button'
          },
          loading: this.loading,
          call: this.modify
        }
      ]
    }
  },
  async created () {
    await this.fillData()
  },
  methods: {
    rerenderFields () {
      this.key += 1
    },
    async fillData () {
      const sortedTaxOrders = this._.sortBy(this._.get(this.item, 'outletTaxOrders', []), order => new Date(order.dateSend).getTime())
      const createTaxOrders = this._.filter(sortedTaxOrders, order => order.type === this.orderModel.orderTypes['100'].value && order.status === this.orderModel.orderStatuses['300'].value)
      this.lastCreateTaxOrder = this._.last(createTaxOrders)

      const stateObject = this._.get(this.item, 'stateObject', '')
      const stateObjectValue = this._.get(this._.filter(this.orderModel.STAN_OBJECT, item => item.text === stateObject), '[0].value', null)
      const typeOfRightsObject = this._.lowerCase(this._.get(this.item, 'typeOfRightsObject', ''))
      const typeOfRightsObjectValue = this._.get(this._.filter(this.orderModel.TYPE_OF_RIGHTS, item => item.sfsValue === typeOfRightsObject), '[0].value', null)

      let HSTI = null
      if (this.lastCreateTaxOrder) {
        HSTI = this._.get(await TaxCodesDictionary.api().filter({ cSti: this._.get(this.lastCreateTaxOrder, 'HSTI') }).all(), 'response.data.data[0]')
      }

      const active = this.isActiveEntity ? this.orderModel.orderStatusType.modify.value : this.orderModel.orderStatusType.deactivate.value

      this.setFields({
        T1RXXXXG4S: this._.get(this.item, 'name', null),
        T1RXXXXG9: stateObjectValue,
        T1RXXXXG10: typeOfRightsObjectValue,
        T1RXXXXG6: this._.get(this.lastCreateTaxOrder, 'T1RXXXXG6', null),
        T1RXXXXG6S: this._.get(this.lastCreateTaxOrder, 'T1RXXXXG6S', null),
        T1RXXXXG8S: this._.get(this.lastCreateTaxOrder, 'T1RXXXXG8S', null),
        HSTI,
        T1RXXXXG2: active,
        T1RXXXXG11: this._.get(this.lastCreateTaxOrder, 'T1RXXXXG11', null)
      })
    },
    setFields (data) {
      this.$set(this.$data, 'editedItem', data)
      this.rerenderFields()
    },
    async modify () {
      const valid = await this.$refs[this.orderModel.entity].validate()
      if (!valid) {
        return
      }
      try {
        this.loading = true

        let katottg = this._.get(this.editedItem, 'T1RXXXXG6S')

        if (katottg && (typeof katottg === 'object')) {
          katottg = KatottgDictionary.getCode(katottg)
        }

        const data = {
          ...this.editedItem,
          HSTI: `${this._.get(this.editedItem, 'HSTI.cSti', null)}`,
          T1RXXXXG6S: katottg,
          outlet: `/outlets/${this._.get(this.item, 'id')}`
        }

        await SignPluginService.sendEntityTaxOrder({
          type: 'modify',
          entity: this.item,
          orderModel: this.orderModel,
          model: this.model,
          organization: this.$Organization,
          data,
          ctx: this
        })

        if (this._.isFunction(this.onSubmit)) {
          await this.onSubmit(this.editedItem)
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
//
</style>
