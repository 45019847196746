import Employees from '~/modules/employees/models/Employees'

const processingCheckAgent = {
  methods: {
    async checkSignAgent (employee, cashRegister) {
      const token = this._.get(await Employees.api().processingLogin(employee, cashRegister), 'response.data.access_token', null)
      return this._.get(await Employees.api().processingCheckAgent(token), 'response.data.online', false)
    }
  }

}

export default processingCheckAgent
