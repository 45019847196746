<template lang="pug">
  v-row
    v-col(cols="12")
      div(class="mt-4" style="max-width: 550px")
        m-block-payment-receipt-preview(
          title=""
          @input="onChange"
          :items="receiptItems"
          :amount="totalSumWithExtraDiscount"
          :loading="invoiceLoading"
          :discount="receiptDiscount"
        )
        div(v-if="!isAutoPayment || !cardTokenData.active" class="mt-6 mb-0 d-flex align-center")
          v-switch(v-model="enableAutomaticPayment" class="m-0" hide-details)
            template(v-slot:label)
              span(class="payment-card__auto-payment-label") {{ $t('Enable auto payment') }}
          e-hint-tooltip(classes="ml-2 mt-1" top)
            span {{ $t('Funds will be automatically deducted each month') }}
        div(v-if="!isAutoPayment || !cardTokenData.active" class="payment-buttons-wrap")
          v-btn(
            class="payment__button payment-buttons-wrap__ipay"
            color="black"
            @click="payment"
            :loading="paymentLoading || paymentInfoLoading"
            :disabled="!cashRegistersCount.normal"
            block
            dark
          )
            | {{ $t('Pay by card') }}
            e-svg-icon(v-for="icon in paymentIcons" :key="icon.name" :size="icon.size" :class="icon.class")
              | {{ icon.name }}
          google-pay-button(
            class="payment__button payment-buttons-wrap__google-pay"
            :price="Math.round(totalSumWithExtraDiscount * 100)"
            :paymentGateway="$env.IPAY_GOOGLE_PAY_GATEWAY"
            :merchantId="$env.GOOGLE_PAY_MERCHANT_ID"
            :gatewayMerchantId="$env.IPAY_GOOGLE_PAY_MERCHANT_ID"
            :merchantName="$env.IPAY_MERCHANT_NAME"
            :environment="$env.GOOGLE_PAY_ENVIRONMENT"
            :loading="paymentInfoLoading || googlePayPaymentLoading"
            :disabled="!cashRegistersCount.normal"
            @click="processGooglePayPayment"
            color="black"
            block
            dark
          )
          apple-pay-button(
            v-if="cashRegistersCount.normal"
            class="payment__button payment-buttons-wrap__apple-pay"
            :amount="totalSumWithExtraDiscount"
            :merchantId="$env.APPLE_PAY_MERCHANT_ID"
            :label="paymentDescription || defaultPaymentDescription"
            :merchantValidation="applePayMerchantValidation"
            :payment="applePayPayment"
          )
        v-btn(
          v-if="isAutoPayment && cardTokenData.active"
          color="black"
          class="payment__button mt-4"
          @click="unsetAutoPayment"
          :loading="unsetAutoPaymentLoading"
          block
          dark
        )
          | {{ $t('Disable automatic payment by card') }}

        v-btn(
          color="black"
          class="payment__button mt-4"
          @click="getInvoicePdf"
          :loading="invoicePdfLoading || paymentInfoLoading"
          :disabled="!cashRegistersCount.normal"
          block
          dark
        )
          | {{ $t('Create an invoice') }}
          e-svg-icon(size="lg" class="ml-5") invoice
</template>

<script>
import informationDialog from '~/mixins/dialogs/informationDialog'

import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import EHintTooltip from '~/components/elements/tooltips/e-hint-tooltip'
import ApplePayButton from '~/modules/applePay/components/ApplePayButton'
import GooglePayButton from '~/modules/googlePay/components/GooglePayButton'
import MBlockPaymentReceiptPreview from '~/components/modules/blocks/concrete/payment/m-block-payment-receipt-preview'

import Billings from '~/models/billing/Billings'
import Invoices from '~/models/billing/Invoices'

export default {
  components: {
    ESvgIcon,
    TOrmFields,
    EHintTooltip,
    ApplePayButton,
    GooglePayButton,
    MBlockPaymentReceiptPreview
  },
  mixins: [informationDialog],
  props: {
    context: {
      type: String,
      default: 'create',
      validator: (value) => {
        return ['create', 'update'].includes(value)
      }
    }
  },
  data: () => ({
    loading: false,
    totalSum: 0,
    periodsCount: 3,
    extraDiscount: 0,
    invoiceLoading: false,
    paymentLoading: false,
    invoicePdfLoading: false,
    showPeriodsCounter: false,
    paymentInfoLoading: false,
    cashRegistersCount: {
      new: 0,
      normal: 0,
      overflow: 0
    },
    enableAutomaticPayment: false,
    unsetAutoPaymentLoading: false,
    googlePayPaymentLoading: false,
    defaultPaymentDescription: 'Оплата за користування сервісом Checkbox',
    paymentDescription: '',
    actualDiscount: null,
    billingPeriods: []
  }),
  computed: {
    model () {
      return Invoices
    },
    invoice () {
      return this.model.query().where('status', 'invoiced').orderBy('date', 'desc').first() || {}
    },
    billing () {
      return Billings.query().with('organization', (query) => {
        query.where('id', this.orgId)
      }).first()
    },
    invoiceId () {
      return this._.get(this.invoice, 'id', '')
    },
    browserInfo () {
      return {
        browser_language: navigator.language,
        browser_screen_height: window.screen.height,
        browser_screen_width: window.screen.width,
        browser_accept_header: 'application/json, text/plain, */*',
        browser_user_agent: navigator.userAgent
      }
    },
    paymentIcons () {
      return [
        { size: this.xsDevice ? 'lg' : 'xlg', name: 'mastercard', class: this.xsDevice ? 'ml-1' : 'ml-3' },
        { size: this.xsDevice ? 'xlg' : 'xxlg', name: 'visa-white', class: 'ml-3' }
      ]
    },
    receiptPreviewTitle () {
      return this.invoiceId ? 'Cash registers invoice' : 'Payment for cash registers'
    },
    totalSumWithExtraDiscount () {
      return this.totalSum - Math.round(this.extraDiscount / 100)
    },
    receiptDiscount () {
      let discount = ''
      let actualDiscount = 0
      let actualSymbol = ''
      if (this.actualDiscount && this.actualDiscount.value) {
        actualSymbol = this._.get(this.actualDiscount, 'type') === 'money' ? '₸' : '%'
        actualDiscount = this.actualDiscount.value
      }
      if (this.extraDiscount) {
        if (this._.get(this.actualDiscount, 'type') === 'money' && actualDiscount) {
          discount = actualDiscount + Math.round(this.extraDiscount / 100) + actualSymbol
        } else if (actualDiscount) {
          discount = actualDiscount + actualSymbol + ' + ' + (Math.round(this.extraDiscount / 100)) + '₸'
        } else {
          discount = Math.round(this.extraDiscount / 100) + '₸'
        }
      } else if (actualDiscount) {
        discount = actualDiscount + actualSymbol
      }
      return discount
    },
    isAutoPayment () {
      return this._.get(this.billing, 'autoPayment', false)
    },
    cardTokenData () {
      return this.billing && this.billing.cardToken ? this._.get(this.billing, 'cardToken', {}) : {}
    },
    receiptItems () {
      const periodsSelect = !this.showPeriodsCounter ? {
        items: [
          {
            text: this.$tc('Months count', 0, { count: 1 }),
            value: 1
          },
          {
            text: this.$tc('Months count', 1, { count: 3 }),
            value: 3
          },
          {
            text: this.$tc('Months count', 2, { count: 6 }),
            value: 6
          },
          {
            text: this.$tc('Months count', 2, { count: 12 }),
            value: 12
          },
          {
            text: this.$t('Other'),
            value: 0
          }
        ],
        value: this.periodsCount,
        loading: this.paymentInfoLoading
      } : false

      return [
        {
          name: this.$t('Number of cash registers'),
          count: this.cashRegistersCount.normal,
          immutable: true
        },
        {
          name: this.$t('Number of checks limit exceeded'),
          count: this.cashRegistersCount.overflow,
          hidden: !this.cashRegistersCount.overflow,
          immutable: true
        },
        {
          name: this.$t('Number of days at the new box office'),
          count: this.cashRegistersCount.new,
          hidden: !this.cashRegistersCount.new,
          immutable: true
        },
        {
          _name: 'period',
          name: this.$t('Period'),
          select: periodsSelect,
          count: this.periodsCount
        }
      ]
    }
  },
  async created () {
    await this.getInvoiceData()
    await this.getBillingPeriods()

    if (this.cardTokenData.active === false && this.isAutoPayment) {
      await this.informationDialog.open({
        text: 'Some error when trying withdraw money from your card',
        buttonText: 'Okay',
        textClass: 'text-center',
        width: '450px',
        hideCloseButton: true
      })
    }
  },
  methods: {
    async payment () {
      try {
        this.paymentLoading = true
        const res = await this.model.api().get(
          this.model.$routes[this.model.entity].paymentLink(),
          {
            save: false,
            params: {
              months: this.periodsCount,
              automaticPayment: this.enableAutomaticPayment
            }
          })
        const shortLink = this._.get(res, 'response.data.url')

        if (shortLink) {
          window.open(shortLink, '_blank')
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.paymentLoading = false
      }
    },
    async onChange (items) {
      const periods = this._.filter(items, { _name: 'period' }, [])
      if (periods && periods.length) {
        const periodsCount = periods[0].count
        this.periodsCount = periodsCount

        if (!this.showPeriodsCounter && !periodsCount) {
          this.showPeriodsCounter = true
          this.periodsCount = 1
        }

        if (this.cashRegistersCount.normal && this.periodsCount) {
          await this.getPaymentInfo(periodsCount)
        }
      }
    },
    async getPaymentInfo (months = 1) {
      try {
        this.paymentInfoLoading = true
        const paymentInfo = this._.get(await this.model.api().get(this.model.$routes[this.model.entity].paymentInfo(), {
          save: false,
          params: {
            months
          }
        }), 'response.data')
        this.cashRegistersCount.normal = this._.get(paymentInfo, 'normal.length', 0) || 1
        this.cashRegistersCount.overflow = this._.get(paymentInfo, 'overflow.length', 0)
        this.cashRegistersCount.new = this._.get(paymentInfo, 'new.length', 0)
        this.actualDiscount = this._.get(paymentInfo, 'discount', {})
        this.extraDiscount = this._.get(paymentInfo, 'extraDiscount', 0)
        this.totalSum = this._.get(paymentInfo, 'totalWithDiscount', 0)
        this.paymentDescription = this._.get(paymentInfo, 'description', '')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.paymentInfoLoading = false
      }
    },
    async applePayPayment (token) {
      try {
        await this.model.api().applePayPayment({ token: btoa(JSON.stringify(token)) }, { months: this.periodsCount })
        await this.$router.push('/payment/success')
      } catch (e) {
        await this.$router.push('/payment/error')
      }
    },
    async getBillingPeriods () {
      try {
        const billingPeriods = await this.model.api().get(this.model.$routes[this.model.entity].billingPeriods(this.invoiceId), { save: false })
        this.billingPeriods = this._.get(billingPeriods, 'response.data.data', null)
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async processGooglePayPayment (token, err) {
      if (err) {
        // eslint-disable-next-line no-console
        console.log(err)
        return null
      }

      try {
        this.googlePayPaymentLoading = true
        const res = await this.model.api().post(
          this.model.$routes[this.model.entity].googlePayPayment(),
          {
            token: btoa(token),
            browserInfo: this.browserInfo
          },
          {
            save: false,
            params: {
              months: this.periodsCount
            }
          }
        )
        window.location.href = this._.get(res, 'response.data.redirect_url', '')
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e.response)
        this.$handlers.error(e, this)
      } finally {
        this.googlePayPaymentLoading = false
      }
    },
    async applePayMerchantValidation (validationUrl) {
      try {
        const res = await this.model.api().applePayValidation({ validationUrl })
        return this._.get(res, 'response.data', null)
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async unsetAutoPayment () {
      try {
        this.unsetAutoPaymentLoading = true
        await Billings.api().unsetAutoPayment(this.billing.id)
        this.$notification.success('Auto payment by card was disabled')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.unsetAutoPaymentLoading = false
      }
    },
    async getInvoiceData () {
      try {
        this.invoiceLoading = true
        await this.model.api().filter({ 'order[date]': 'desc' }).all({ status: 'invoiced' })
        if (this.invoiceId) {
          this.periodsCount = 1
        }
        await this.getPaymentInfo(this.periodsCount)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.invoiceLoading = false
      }
    },
    async getInvoicePdf () {
      try {
        this.invoicePdfLoading = true
        const res = await this.model.api().get(this.model.$routes[this.model.entity].readPdf(), {
          save: false,
          responseType: 'blob',
          params: {
            months: this.periodsCount
          }
        })
        const blob = new Blob([this._.get(res, 'response.data', '')], { type: 'application/pdf' })
        window.open(URL.createObjectURL(blob), '_blank')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.invoicePdfLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.payment-buttons-wrap {
  display: flex;
  align-items: center;
  margin-top: 16px;
  flex-wrap: wrap;

  @media (min-width: map-get($breakpoints, 'md')) {
    flex-wrap: nowrap;
  }

  &__ipay {
    height: 48px !important;
    min-width: auto !important;
    width: 100%;

    @media (min-width: map-get($breakpoints, 'md')) {
      width: auto;
    }
  }
  &__apple-pay {
    max-width: none;
    width: 100%;
    margin-top: 16px;

    @media (min-width: map-get($breakpoints, 'md')) {
      margin-left: 12px;
      margin-top: 0;
      max-width: 100px;
    }
  }
  &__google-pay {
    border-radius: 12px;
    overflow: hidden;
    min-width: auto !important;
    max-width: none;
    width: 100%;
    margin-top: 16px;
    height: 48px;

    button {
      background-color: $btn-secondary-color !important;
    }

    @media (min-width: map-get($breakpoints, 'md')) {
      margin-left: 12px;
      margin-top: 0;
      max-width: 100px;
    }
  }
}
.payment__button {
  height: 48px !important;
}
.payment-card {
  &__text {
    font-size: 0.8rem;
    display: block;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    &--big {
      font-size: 1rem;
    }

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 0.875rem;
    }
  }
  &__auto-payment-label {
    font-weight: 700;
    color: $btn-secondary-color;
  }
}
</style>
