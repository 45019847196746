<template lang="pug">
  div(class="dialog")
    div(class="dialog__title mb-4") {{ $t(`serviceReceipt.${receiptType}.title`) }}
    div()
      ValidationObserver(ref="serviceReceiptForm")
        ValidationProvider(
          rules="required|money"
          :name="' '"
          vid="serviceReceipt"
          v-slot="{ errors }"
        )
          v-text-field(
            :label="$t(`serviceReceipt.${receiptType}.label`)"
            v-model="amount"
            :error-messages="errors"
            outlined
          )
    div(class="dialog__actions")
      v-row(justify="center")
        v-col(
          cols="auto"
          class="pa-0"
        )
          v-btn(
            class="main-button"
            @click="sendServiceReceipt"
            :loading="loading"
          ) {{ $t(`serviceReceipt.${receiptType}.btnText`) }}
</template>

<script>
import TDialog from '~/components/templates/t-dialog'

import processingReceipt from '~/modules/receipt/mixins/actions/processingReceipt'
import processingShifts from '~/modules/receipt/mixins/actions/processingShifts'
import authData from '~/modules/receipt/mixins/getters/authData'
import _commonData from '~/modules/receipt/mixins/getters/commonData'
import shiftData from '~/modules/receipt/mixins/getters/shiftData'

import Receipts from '~/modules/receipt/models/Receipts'

import { IntervalRequest } from '~/services/_utils/IntervalRequest'

export default {
  name: 'BlockReceiptService',
  components: {
    TDialog
  },
  mixins: [
    processingReceipt,
    processingShifts,
    authData,
    _commonData,
    shiftData
  ],
  props: {
    receiptType: {
      type: String,
      default: 'cashIn',
      validate: value => ['cashIn', 'cashOut'].includes(value)
    }
  },
  data: () => ({
    amount: null,
    loading: null
  }),
  computed: {
    shiftSum () {
      return this._.get(this.shift, 'balance.balance', 0)
    }
  },
  created () {
    if (this.receiptType === 'cashOut') {
      this.amount = (this.shiftSum / 100).toFixed(2)
    }
  },
  methods: {
    close () {
      this.amount = null
      const closeModal = this._.get(this.$attrs, 'closeModal')
      if (this._.isFunction(closeModal)) {
        closeModal()
      }
    },
    async sendServiceReceipt () {
      if (!await this.$refs.serviceReceiptForm.validate()) {
        return false
      }
      let amount = Math.round(parseFloat(this.amount) * 100)
      if (this.receiptType === 'cashOut') {
        // if it is removal - amount must contain "-"
        amount = -amount
      }
      try {
        this.loading = true
        const { response } = await this.createServiceReceipt(this.token, amount)
        try {
          const intervalRequest = new IntervalRequest(() => this.readReceipt(this.token, response.data.id))
          await intervalRequest.start(({ response }) => response.data.status === Receipts.processingStatuses.DONE.value,
            ({ response }) => response.data.status === Receipts.processingStatuses.ERROR.value)
          this.checkShiftForCashier(this.authenticatedCashier)
          this.$notification.success(this.$t(`serviceReceipt.${this.receiptType}.approvedNotification`, { amount: this.amount }))
          this.close()
        } catch (e) {
          this.$notification.error(this.$t(`serviceReceipt.${this.receiptType}.rejectedNotification`, { amount: this.amount }))
        } finally {
          this.amount = null
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
