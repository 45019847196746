import Base64js from 'base64-js'
import Employees from '~/modules/employees/models/Employees'
import setAuthenticatedCashier from '~/modules/receipt/mixins/setters/setAuthenticatedCashier'
import signAgentDialog from '~/mixins/dialogs/signAgentDialog'
import ProcessingWebSocket from '~/services/_utils/ProcessingWebSoket/ProcessingWebSocket'
import { ProcessingWebSocketMessageHandler } from '~/services/_utils/ProcessingWebSoket/ProcessingWebSocketMessageHandler'
import _comonData from '~/modules/receipt/mixins/getters/commonData'
import contentDialog from '~/mixins/dialogs/contentDialog'

const processingAuth = {
  mixins: [setAuthenticatedCashier, _comonData, signAgentDialog, contentDialog],
  methods: {
    // top level functions
    async authenticate (authenticatedCashier) {
      const { response } = await Employees.api().processingLogin(authenticatedCashier.cashier, authenticatedCashier.cashRegister)
      await this.setAuthenticatedCashier({
        accessToken: response.data.access_token,
        shift: null
      }, authenticatedCashier)
      return true
    },

    async authenticateWithSign (authenticatedCashier) {
      if (this.signAgentDialog.isOpened) {
        await this.signAgentDialog.close()
      }
      const dialog = await this.signAgentDialog.open()
      if (!dialog) { return }
      if (this.signAgentDialog.interface.keyData[0].infoEx.publicKeyID.replace(/ /g, '').toLowerCase() !== authenticatedCashier.cashier.publicKeyId) {
        this.$notification.error(this.$t('Selected key do not matched with selected cashier. Please select right EDS Key'))
        await this.authenticateWithSign(authenticatedCashier)
        return
      }
      const sign = await this.signAgentDialog.interface.sign(authenticatedCashier.cashier.login)
      const token = this._.get(await Employees.api().processingLoginSignature(sign), 'response.data.access_token', null)
      const socket = new ProcessingWebSocket(this.$env.WSS_PROCESSING_BASE_URL, token, this)
      const messageHandler = new ProcessingWebSocketMessageHandler(this.signAgentDialog.interface)
      const onStateChange = async (target, event) => {
        if (event.type === 'close' || event.type === 'open') {
          await this.setAuthenticatedCashier({
            signAgent: target
          }, this.authenticatedCashier)
        }
      }
      await socket.open(messageHandler, onStateChange)

      await this.setAuthenticatedCashier({
        accessToken: token,
        shift: null,
        signAgent: socket
      }, authenticatedCashier)
      return true
    },

    async authenticateWithCloudSign (authenticatedCashier) {
      const keyData = await this.contentDialog.open(
        {
          title: 'Launch Checkbox Signature on a secure cloud service',
          component: 'm-form-employee-upload-eds-key',
          width: '650px',
          componentProps: {
            inModal: true
          }
        }
      )
      if (!keyData) {
        return null
      }
      const issuerCN = this._.get(keyData, 'keyData.certificate_info.certificate.pszIssuerCN', null)
      const certificateStart = this._.get(keyData, 'keyData.certificate_start', null)
      const certificateEnd = this._.get(keyData, 'keyData.certificate_end', null)
      const certificate = this._.get(keyData, 'keyData.certificate_info', null)
      const keyIdFromKey = this._.get(keyData, 'keyData.key_id', '').replace(/ /g, '').toLowerCase()
      if (keyIdFromKey !== this._.get(authenticatedCashier, 'cashier.publicKeyId')) {
        this.$notification.error('Selected key does not match of cashier key', true)
        return null
      }
      const data = {
        ca: this._.get(keyData, 'keyData.ca', null),
        keyPassword: keyData.form.keyPassword,
        keyFile: Base64js.fromByteArray(new Uint8Array(keyData.form.keyFile)),
        caIssuerCN: issuerCN,
        certificateStart,
        certificateEnd,
        certificateInfo: certificate
      }
      try {
        await Employees.api().uploadEdsKey(this._.get(authenticatedCashier, 'cashier.id'), data)
        this.$notification.success('Key successfully upload to checkbox server')
        return await this.authenticate(authenticatedCashier)
      } catch (e) {
        this.$notification.error(e)
        return null
      }
    },

    async logOut (authenticatedCashier) {
      if (authenticatedCashier.signAgent && authenticatedCashier.signAgent.close) {
        await authenticatedCashier.signAgent.close()
      }
      await this.setAuthenticatedCashier({
        accessToken: null,
        cashier: null,
        cashRegister: null,
        acquiringSetting: null,
        shift: null,
        signAgent: null
      }, authenticatedCashier)
    }
  }

}

export default processingAuth
