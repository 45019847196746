<template lang="pug">
  v-text-field(
    @keypress="floatKeyPress"
    @blur="setDefault"
    class="e-number-counter-input"
    v-bind="$attrs"
    v-on="$listeners"
    ref="e-number-counter-input"
    :key="key"
  )
    template(#append)
        span(@click="increment") +
    template(#prepend-inner)
        span(@click="decrement" :class="!isActiveMinus ? 'inactive': ''") -
</template>

<script>
import currencyFormat from '~/mixins/filters/currencyFormat'

export default {
  mixins: [currencyFormat],
  props: {
    step: {
      type: Number,
      default: 1
    }
  },
  data: () => ({
    key: 0
  }),
  computed: {
    isActiveMinus () {
      return parseFloat(this.$attrs.value) > this.step
    }
  },
  watch: {
    '$attrs.value' (val) {
      this.setInputWidth(val)
    }
  },
  methods: {
    setInputWidth (val) {
      const size = val ? val.toString().length : 1
      const ref = this.$refs['e-number-counter-input']
      const input = ref && ref.$el && ref.$el.querySelector('input')

      if (input) {
        input.style.width = size * 9 + 'px'
      }
    },
    increment () {
      const val = (parseFloat(this.$attrs.value) || 0) + this.step
      this.$emit('input', Number.isInteger(this.step) ? val : val.toFixed(3))
    },
    decrement () {
      if (this.isActiveMinus) {
        const val = (parseFloat(this.$attrs.value) || 0) - this.step
        this.$emit('input', Number.isInteger(this.step) ? val : val.toFixed(3))
      }
    },
    setDefault () {
      const val = parseFloat(this.$attrs.value)
      const emitVal = Math.abs(val || this.step)
      this.$emit('input', Number.isInteger(this.step) ? emitVal.toFixed(0) : emitVal.toFixed(3))
      this.key += 1
    }
  }
}
</script>

<style scoped lang="scss">
.e-number-counter-input{
  font-family: $body-font-family;
  max-width: none;

  ::v-deep.v-input__slot{
    border: 1px solid #E0E0E0;
    border-radius: 6px;
    min-height: 28px!important;
    background-color: #fff;
    width: auto;
    input{
      text-align: center;
      min-width: 70px;
      max-width: 70px;
      margin: 0 auto;
    }
    .v-input__prepend-inner, .v-input__append-inner{
      font-size: 16px;
      line-height: 16px;
      margin-top: 7px;
      font-weight: 500;
      font-feature-settings: 'tnum' on, 'lnum' on;
      cursor: pointer;
      span{
        color: #0CB1D6;
        &.inactive{
          color: #161B25;
          opacity: .25;
        }
      }
    }
  }
}

</style>
