<template lang="pug">
  ValidationObserver(ref="validator"  slim)
    v-row(no-gutters)
      v-col(cols="12")
        ValidationProvider(rules="required" mode="passive" :name="$t('Monobank token')" vid="token" v-slot="{ errors }")
          v-text-field(v-model="token" outlined=true placeholder="Monobank token" :error-messages="errors")
      v-col(cols="12" v-if="showQrCodes")
        p(class="mb-1") {{$t('Found QR cash registers')}}:
        v-radio-group(class="mt-0 payment-type__radio-wrap" v-model="defaultQr")
          v-radio(
            :key="qr.qrId"
            v-for="qr in cashRegister.acquiringSetting.qrCodes"
            :label="qr.shortQrId + ' (' + qr.qrId + ')'"
            :value="qr.qrId"
            color="black"
            :ripple="false"
            )
      v-row(justify="center")
        v-col(cols="auto")
          v-btn(class="main-button mb-2" @click="saveToken" :loading="loading")
            | Зберегти
</template>

<script>
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'

export default {
  props: {
    cashRegisterId: {
      type: String,
      required: true
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    loading: false,
    token: null,
    showQrCodes: false,
    qrCodeSelected: false,
    defaultQr: null
  }),
  computed: {
    cashRegister () {
      return CashRegisters.query().whereId(this.cashRegisterId).first()
    }
  },
  created () {
    this.token = this._.get(this.cashRegister, 'acquiringSetting.token', null)
    const qrCodes = this._.get(this.cashRegister, 'acquiringSetting.qrCodes', [])
    if (qrCodes && qrCodes.length >= 1) {
      this.showQrCodes = false
      const selected = qrCodes.find(item => item.default)
      if (selected) {
        this.defaultQr = selected.qrId
      }
    }
  },
  methods: {
    async saveToken () {
      const valid = await this.$refs.validator.validate()
      if (!valid) { return null }

      const payload = {
        acquiring: {
          token: this.token,
          qrCodes: this.generateQrPayload()
        }
      }
      try {
        this.loading = true
        const oldQrCodes = this._.get(this.cashRegister, 'acquiringSetting.qrCodes', [])
        const oldToken = this._.get(this.cashRegister, 'acquiringSetting.token', null)
        const settings = this._.get(await CashRegisters.api().updateSettings(this.cashRegister, payload), 'response.data.acquiringSetting', [])
        const newQrCodesFound = (settings?.qrCodes?.length !== oldQrCodes.length) && (settings.token === oldToken)
        if (settings.qrCodes && settings.qrCodes.length && !this.qrCodeSelected) {
          if (newQrCodesFound) {
            this.$notification.info(this.$t('Found new qr codes for this token. Select one if need'))
          } else {
            this.$notification.info(this.$t('Found qr codes for this token. Select one if need'))
          }
          this.showQrCodes = true
          this.qrCodeSelected = true
        } else {
          this.$notification.success(this.$t('Acquiring settings saved'))
          this.closeModal(true)
        }
      } catch (e) {
        this.$handlers.error(e, this.$refs.validator)
      } finally {
        this.loading = false
      }
    },
    generateQrPayload () {
      const qrCodes = this._.get(this.cashRegister, 'acquiringSetting.qrCodes', [])
      if (qrCodes && qrCodes.length) {
        return qrCodes.map((item) => {
          return Object.assign({}, item, { default: item.qrId === this.defaultQr })
        })
      }
      return null
    }
  }

}
</script>

<style scoped>

</style>
